<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-card-text class="px-0 ">
                    <v-row class="px-0 py-1">
                        <v-col cols="12" class="px-0" align="center" justify="center">
                            <h1>{{ $t('upload_files.manage_files') }} ({{id}})</h1>
                            <v-col cols="8" class="px-0">
                                <v-progress-linear
                                    v-model="progress"
                                    height="35"
                                    color="amarillo"
                                    class="pl-2 pr-0" 
                                >
                                    <strong>{{ Math.ceil(progress) }}%</strong>
                                </v-progress-linear>
                                <i style="font-size:14px"><v-icon color="amarillo">info</v-icon>{{ $t('upload_files.info') }}</i>
                            </v-col>
                        </v-col>
                    </v-row>
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-checkbox v-model="required_files.carta_porte" class="ml-8 mb-1" color="azul"></v-checkbox>
                            <v-col :cols="form_size" class="py-0 px-0">
                                <v-file-input prepend-icon="cloud_upload"  class="cloud_upload px-0" :clearable="false" v-model="files.carta_porte"  accept="*" :label="$t('upload_files.carta_porte')" :disabled="!required_files.carta_porte || current_files.carta_porte"></v-file-input>
                            </v-col>
                            <v-col cols="1">
                                <v-row>
                                    <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('carta_porte')" :disabled="current_files.carta_porte == false"><v-icon small >download</v-icon></v-btn>
                                    <v-btn outlined color="red" small class="px-0 mx-1" style="min-width:25px !important" :disabled="current_files.carta_porte == false" @click="deleteFile('carta_porte')"><v-icon  small>delete</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-checkbox v-model="required_files.packing_list" class="ml-8 mb-1" color="azul"></v-checkbox>
                            <v-col :cols="form_size" class="py-0 px-0">
                                <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :clearable="false" v-model="files.packing_list" accept="*" :label="$t('upload_files.packing_list')" :disabled="!required_files.packing_list || current_files.packing_list"></v-file-input>
                            </v-col>
                            <v-col cols="1">
                                <v-row>
                                    <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('packing_list')" :disabled="current_files.packing_list == false"><v-icon small>download</v-icon></v-btn>
                                    <v-btn outlined color="red" small class="px-0 mx-1" style="min-width:25px !important" :disabled="current_files.packing_list == false" @click="deleteFile('packing_list')"><v-icon  small>delete</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-checkbox v-model="required_files.fao" class="ml-8 mb-1" color="azul"></v-checkbox>
                            <v-col :cols="form_size" class="py-0 px-0">
                                   <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :clearable="false" v-model="files.fao" accept="*" :label="$t('upload_files.fao')" :disabled="!required_files.fao || current_files.fao"></v-file-input>
                            </v-col>
                            <v-col cols="1" >
                                <v-row>
                                    <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('fao')" :disabled="current_files.fao == false"><v-icon small>download</v-icon></v-btn>
                                    <v-btn outlined color="red" small class="px-0 mx-1" style="min-width:25px !important" :disabled="current_files.fao == false" @click="deleteFile('fao')"><v-icon  small>delete</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-checkbox v-model="required_files.hand_guide" class="ml-8 mb-1" color="azul"></v-checkbox>
                            <v-col :cols="form_size" class="py-0 px-0">
                                   <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :clearable="false" v-model="files.hand_guide" accept="*" :label="$t('upload_files.hand_guide')" :disabled="!required_files.hand_guide || current_files.hand_guide"></v-file-input>
                            </v-col>
                            <v-col cols="1" >
                                <v-row>
                                    <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('hand_guide')" :disabled="current_files.hand_guide == false"><v-icon small>download</v-icon></v-btn>
                                    <v-btn outlined color="red" small class="px-0 mx-1" style="min-width:25px !important" :disabled="current_files.hand_guide == false" @click="deleteFile('hand_guide')"><v-icon  small>delete</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-checkbox v-model="required_files.invoice" class="ml-8 mb-1" color="azul"></v-checkbox>
                            <v-col :cols="form_size" class="py-0 px-0">
                                   <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :clearable="false" v-model="files.invoice" accept="*" :label="$t('upload_files.invoice')" :disabled="!required_files.invoice || current_files.invoice"></v-file-input>
                            </v-col>
                            <v-col cols="1" >
                                <v-row>
                                    <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('invoice')" :disabled="current_files.invoice == false"><v-icon small>download</v-icon></v-btn>
                                    <v-btn outlined color="red" small class="px-0 mx-1" style="min-width:25px !important" :disabled="current_files.invoice == false" @click="deleteFile('invoice')"><v-icon  small>delete</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-row>    
                        
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-col cols="8" class="py-0 px-0">
                                <v-textarea v-model="observaciones" :label="$t('upload_files.observations')"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0" justify="center" align="center">
                            <v-col cols="8" class="py-0 px-0">
                                <span class="font-weight-bold">{{ $t('upload_files.drawback') }}:</span>
                                <v-radio-group class="my-0" v-model="inconveniente" row style="max-height:33px !important">
                                    <v-radio :label="$t('upload_files.true')" value="1"></v-radio>
                                    <v-radio :label="$t('upload_files.false')" value="0"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-form>

                    <br>
                    <br>

                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="8" align="right">
                            <v-btn class="right mx-3" color="white" @click="cancel">{{ $t('upload_files.cancel') }}</v-btn>
                            <v-btn class="right white--text mx-0" color="amarillo" @click="save" v-permission="'n_pending.file'">{{ $t('upload_files.accept') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
        </v-container>
        <v-dialog v-model="modalDelete" persistent max-width="90%">
            <v-card >
                <v-card-title class="justify-center">
                    <v-row align="center" justify="center">
                            <h2>
                                {{ $t('upload_files.modal.title') }}
                            </h2>
                    </v-row>
                    <v-btn color="amarillo darken-1" small icon @click="modalDelete = false"><v-icon small>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    
                    <v-row align="center" justify="center" class="py-3 my-3 text-center">
                        <v-col cols="12">
                            <h3>{{ $t('upload_files.modal.continue') }}</h3>
                        </v-col>
                    </v-row>
                    
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn class="mx-3" text @click="modalDelete = false; currentDelete = ''">{{ $t('upload_files.modal.cancel') }}</v-btn>
                        <v-btn color="amarillo" class="color" @click="deleteM">
                            {{ $t('upload_files.modal.delete') }}
                        </v-btn>
                        
                    </div>
                </v-card-text> 
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
    },
    data(){
        return {
            id: this.$route.params.id,
            form_size: 7,
            modalDelete: false,
            observaciones: '',
            inconveniente: '0',
            files:{
                carta_porte: [],
                packing_list : [],
                fao : [],
                hand_guide: [],
                invoice: []
            },
            current_files:{
                carta_porte: false,
                packing_list : false,
                fao : false,
                hand_guide: false,
                invoice: false,
                carta_porte_name: '',
                packing_list_name: '',
                fao_name: '',
                hand_guide_name: '',
                invoice_name: ''
            },
            required_files: {
                carta_porte: true,
                packing_list : true,
                fao : true,
                hand_guide: true,
                invoice: true
            },
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            downloadCurrentFiles: [],
            currentDelete: '',
            progress: 0,
        }
    },
    mounted(){
        this.$store.state.module = "headers.manage_files";
        this.index();
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        index(){
            this.downloadCurrentFiles = [];
            this.$store.state.loaderProps.visible = false;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointments/' + this.id + '/getFiles', {headers: {Authorization: lsToken}})
            .then((response) => {
                this.observaciones = response.data.dm.observations;
                this.downloadCurrentFiles = response.data.files;
                this.progress = 0;
                var number_files = 0;
                for (var prop in this.required_files) {
                    if(this.required_files[prop] == true) number_files += 1;
                }
                response.data.files.forEach((x) =>{
                    var tipo = x.name.substring(0, x.name.indexOf('-'));
                    if(number_files > 0) this.progress += 100/number_files;
                    this.current_files[tipo] = true;
                    this.current_files[tipo + '_name'] = x.name.substring(x.name.indexOf('-') + 1, x.name.length);
                    this.files[tipo] = new File(["foo"], x.name.substring(x.name.indexOf('-') + 1, x.name.length), {
                        type: "text/plain",
                    });    
                });
                if (response.data.dm.required_files != null && response.data.dm.required_files != undefined) this.required_files = JSON.parse(response.data.dm.required_files);
                if (response.data.dm.observations != null && response.data.dm.observations != undefined) this.observaciones = response.data.dm.observations;
                if (response.data.dm.inconvenient != null && response.data.dm.drawback != undefined) this.inconveniente = response.data.dm.inconvenient.toString();
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const formData = new FormData();

            formData.append('required_files', JSON.stringify(this.required_files));
            formData.append('observations', this.observaciones);
            formData.append('inconveniente', this.inconveniente);
            // console.log(this.files)
            for(var prop in this.files){
                if(this.files[prop] != null && this.files[prop].type != 'text/plain') formData.append(prop, this.files[prop]);
            }
            // console.log([...formData]) // Print form data displaying all pr

            const lsToken = localStorage.getItem("access_token");
            this.$http.post(this.$store.state.chronosApi + 'uploadFilesNational/' + this.id, formData, {headers: {Authorization: lsToken}})
            .then(response => {
                if(response.status == 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('upload_files.responses.ok')
                    }
                }
            }).catch((error) => {
                console.log(error);
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('upload_files.responses.error')
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        cancel(){
            this.$router.go(-1);
        },
        getFile(type){
            this.$store.state.loaderProps.visible = true;
            this.downloadCurrentFiles.forEach(x => {
                if(x.name.includes(type + '-')){    
                    const link = document.createElement('a');
                    link.href = x.url;
                    window.open(link.href,'_blank');
                }
            })

            this.$store.state.loaderProps.visible = false;
        },
        deleteFile(type){
            this.modalDelete = true;
            this.currentDelete = type;
        },
        deleteM(){
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;
            var index = null; 
            this.downloadCurrentFiles.forEach(x => {
                if(x.name.includes(this.currentDelete)){
                    index = x.index
                }
            });

            console.log(index)
            this.$http.delete(this.$store.state.chronosApi + 'appointments/' + index + '/deleteFile', {headers: {Authorization: lsToken}})
            .then(response => {
                if(response.status == 200){
                    this.modalDelete = false;
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('upload_files.responses.deleted')
                    }
                }
                this.current_files[this.currentDelete] = false;
                this.index();
            }).catch((error) => {
                console.log(error);
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('upload_files.responses.error')
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        }
    },
    watch: {
        'required_files': {
            handler(/*newValue, oldValue */){  // here having access to the new and old value
                this.progress = 0;
                var number_files = 0;
                for (var prop in this.required_files) {
                    if(this.required_files[prop] == true) number_files += 1;
                }
                if(number_files > 0){
                    for (var pr in this.current_files) {
                        if((this.current_files[pr] == true && typeof this.current_files[pr] === "boolean") && this.required_files[pr] == true) this.progress += 100/number_files;
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'progress': function(){
            if(this.progress > 100) this.progress = 100;
        }
    }
}
</script>

<style lang="scss">
.cloud_upload{
    button{
        color: #191970 !important;
    }
}

.color{
    color: white !important;
}
</style>