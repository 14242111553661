import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index";
import axios from "axios";

import Login from '../views/Layouts/Login.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'

// Apointments
import NationalPendings from '../views/Appointments/National/Pendings.vue'
import NationalProcessed from '../views/Appointments/National/Processed.vue'
import InternationalPendings from '../views/Appointments/International/Pendings.vue'
import InternationalProcessed from '../views/Appointments/International/Processed.vue'
// import InternationalNoAnnex from '../views/Appointments/International/NoAnnex.vue'
// import InternationalDigitalHeritage from '../views/Appointments/International/DigitalHeritage.vue'
import ComplianceProcessed from '../views/Appointments/Compliance/Processed.vue'
import ComplianceNoAnnex from '../views/Appointments/Compliance/NoAnnex.vue'
import ComplianceDigitalHeritage from '../views/Appointments/Compliance/DigitalHeritage.vue'
import PreloadIncomeAppointment from '../views/Appointments/PreloadIncome.vue'
import PreloadOutcomeAppointment from '../views/Appointments/PreloadOutcome.vue'
import PickingAppointment from '../views/Appointments/Picking.vue'
import StoreProductIncomeAppointment from '../views/Appointments/StoreIncome.vue'
import CreateAppointment from '../views/Appointments/Create.vue'
import EditAppointment from '../views/Appointments/Edit.vue'
import AppointmentDetails from '../views/Appointments/Detail.vue'

import ShipmentsCodPendings from '../views/Fulfillment/Cod/Pendings.vue'
import ShipmentsCodInProcess from '../views/Fulfillment/Cod/InProcess.vue'
import ShipmentsCodSent from '../views/Fulfillment/Cod/Sent.vue'
import ShipmentsCodReturns from '../views/Fulfillment/Cod/Returns.vue'
import EditPendingShipmentCod from '../views/Fulfillment/Cod/EditPending.vue'
import EditSentShipmentCod from '../views/Fulfillment/Cod/EditSent.vue'
import ShipmentCodIncidence from '../views/Fulfillment/Cod/OrderIncidence.vue'
import ShipmentsPrepaidPendings from '../views/Fulfillment/Prepaid/Pendings.vue'
import ShipmentsPrepaidInProcess from '../views/Fulfillment/Prepaid/InProcess.vue'
import ShipmentsPrepaidSent from '../views/Fulfillment/Prepaid/Sent.vue'
import ShipmentsPrepaidReturns from '../views/Fulfillment/Prepaid/Returns.vue'
import EditPendingShipmentPrepaid from '../views/Fulfillment/Prepaid/EditPending.vue'
import EditSentShipmentPrepaid from '../views/Fulfillment/Prepaid/EditSent.vue'
import ShipmentPrepaidIncidence from '../views/Fulfillment/Prepaid/OrderIncidence.vue'
import WarehousesIndex from '../views/Warehouses/Index.vue'
import WarehouseDetail from '../views/Warehouses/Detail.vue'
import WarehouseEdit from '../views/Warehouses/Edit.vue'
import WarehouseCreate from '../views/Warehouses/Create.vue'
import RolesCreate from '../views/Access/Roles/Create.vue'
import RolesIndex from '../views/Access/Roles/Index.vue'
import RolesEdit from '../views/Access/Roles/Edit.vue'

// Customers Views
import CustomersIndex from '../views/Customers/Index.vue'
import CustomersDetail from '../views/Customers/Detail.vue'
import CustomersEdit from '../views/Customers/Edit.vue'
import CustomersEditFees from '../views/Customers/EditFees.vue'
import CustomersCreate from '../views/Customers/Create.vue'
import CustomersManageStores from '../views/Customers/ManageStores.vue'
import CustomersManageSMS from '../views/Customers/ManageSMS.vue'

import LocationsIndex from '../views/Locations/Index.vue'
import LocationsDetail from '../views/Locations/Detail.vue'
import LocationsEdit from '../views/Locations/Edit.vue'
import LocationsCreate from '../views/Locations/Create.vue'
import CategoriesIndex from '../views/Categories/Index.vue'
import CategoriesDetail from '../views/Categories/Detail.vue'
import CategoriesEdit from '../views/Categories/Edit.vue'
import CategoriesCreate from '../views/Categories/Create.vue'
import CustomAgentsIndex from '../views/CustomAgents/Index.vue'
import CustomAgentsDetail from '../views/CustomAgents/Detail.vue'
import CustomAgentsEdit from '../views/CustomAgents/Edit.vue'
import CustomAgentsCreate from '../views/CustomAgents/Create.vue'
import KitsIndex from '../views/Kits/Index.vue'
import KitsDetail from '../views/Kits/Detail.vue'
import KitsEdit from '../views/Kits/Edit.vue'
import KitsCreate from '../views/Kits/Create.vue'

import ProductsIndex from '../views/Products/Index.vue'
import ProductsDetail from '../views/Products/Detail.vue'
import Refurbish from '../views/Products/Refurbish.vue'
import ProductsEdit from '../views/Products/Edit.vue'
import ProductsCreate from '../views/Products/Create.vue'

import OriginSuppliersIndex from '../views/OriginSuppliers/Index.vue'
import OriginSuppliersDetail from '../views/OriginSuppliers/Detail.vue'
import OriginSuppliersEdit from '../views/OriginSuppliers/Edit.vue'
import OriginSuppliersCreate from '../views/OriginSuppliers/Create.vue'

import GatesIndex from '../views/Gates/Index.vue'
import GatesDetail from '../views/Gates/Detail.vue'
import GatesEdit from '../views/Gates/Edit.vue'
import GatesCreate from '../views/Gates/Create.vue'

import BoxesIndex from '../views/Boxes/Index.vue'
import BoxesEdit from '../views/Boxes/Edit.vue'
import BoxesCreate from '../views/Boxes/Create.vue'

import OperationPendings from '../views/Appointments/Operation/Pendings.vue'
import ProcessPending from '../views/Appointments/Operation/ProcessPending.vue'
import OperationOnPremises from '../views/Appointments/Operation/OnPremises.vue'
import OperationOnRamp from '../views/Appointments/Operation/OnRamp.vue'
import ProcessInPortal from '../views/Appointments/Operation/ProcessInPortal.vue'
import ProcessInProcess from '../views/Appointments/Operation/ProcessInProcess.vue'
import OperationInProcess from '../views/Appointments/Operation/InProcess.vue'
import ProcessEndProcess from '../views/Appointments/Operation/ProcessEndProcess.vue'
import PendingOut from '../views/Appointments/Operation/PendingOut.vue'
import ProcessPendingOut from '../views/Appointments/Operation/ProcessPendingOut.vue'
import IncidenceDetail from '../views/Appointments/Incidences/Detail.vue'
import IncidenceEdit from '../views/Appointments/Incidences/Edit.vue'
import OutcomeAppointment from '../views/Appointments/International/OutcomeAppointment.vue'
import MultipleAppointments from '../views/Appointments/International/MultipleAppointments.vue'
import CollectionIndex from '../views/YegoServices/Collection.vue'
import CreateOrder from '../views/YegoServices/CreateOrder.vue'
import CreateMultipleOrders from '../views/YegoServices/CreateMultipleOrders.vue';
import CodCollectionIndex from '../views/YegoServices/CodCollection.vue'
import UsersIndex from '../views/Access/Users/Index.vue'
import UsersCreate from '../views/Access/Users/Create.vue'
import UsersEdit from '../views/Access/Users/Edit.vue'
import Search from '../views/YegoServices/Search.vue'
import YegoReports from '../views/YegoServices/Reports.vue'
import RelocationsIndex from '../views/Relocations/Index.vue'
import RelocationsDetail from '../views/Relocations/Detail.vue'
import RelocationsCreate from '../views/Relocations/Create.vue'
import RelocationsCreateWithScanner from '../views/Relocations/CreateWithScanner.vue'

import TaxpayerData from '../views/CustomsOperator/Catalogs/TaxpayerData.vue'
import Materials from '../views/CustomsOperator/Catalogs/Materials.vue'
import Products from '../views/CustomsOperator/Catalogs/Products.vue'
import Suppliers from '../views/CustomsOperator/Catalogs/Suppliers.vue'
import Customers from '../views/CustomsOperator/Catalogs/Customers.vue'
import Submanufacturing from '../views/CustomsOperator/Catalogs/Submanufacturing.vue'
import AgentsAndCustomAgents from '../views/CustomsOperator/Catalogs/AgentsAndCustomAgents.vue'
import FixedAssets from '../views/CustomsOperator/Catalogs/FixedAssets.vue'
import IncomingMovements from '../views/CustomsOperator/InterfacesModule/IncomingMovements.vue'
import OutcomingMovements from '../views/CustomsOperator/InterfacesModule/OutcomingMovements.vue'
import ManufacturingMovements from '../views/CustomsOperator/InterfacesModule/ManufacturingMovements.vue'
import CMIncomingMovements from '../views/CustomsOperator/CustomsModule/IncomingMovements.vue'
import CMOutcomingMovements from '../views/CustomsOperator/CustomsModule/OutcomingMovements.vue'
import CMFixedAssets from '../views/CustomsOperator/CustomsModule/FixedAssets.vue'
import RMIncomingMovements from '../views/CustomsOperator/ReportingModule/IncomingMovements.vue'
import RMOutcomingMovements from '../views/CustomsOperator/ReportingModule/OutcomingMovements.vue'
import RMBalances from '../views/CustomsOperator/ReportingModule/Balances.vue'
import RMDisclaimers from '../views/CustomsOperator/ReportingModule/Disclaimers.vue'
import RMSettings from '../views/CustomsOperator/ReportingModule/Settings.vue'
import RMExpirations from '../views/CustomsOperator/ReportingModule/Expirations.vue'

// NEW ANNEX 24 VIEWS
import TaxpayerData2 from '../views/NewAnnex24/Catalogs/TaxpayerData.vue'
import Materials2 from '../views/NewAnnex24/Catalogs/Materials.vue'
import Products2 from '../views/NewAnnex24/Catalogs/Products.vue'
import Suppliers2 from '../views/NewAnnex24/Catalogs/Suppliers.vue'
import Customers2 from '../views/NewAnnex24/Catalogs/Customers.vue'
import AgentsAndCustomAgents2 from '../views/NewAnnex24/Catalogs/AgentsAndCustomAgents.vue'
import CMIncomingMovements2 from '../views/NewAnnex24/CustomsModule/IncomingMovements.vue'
import CMMaterialLetters from '../views/NewAnnex24/CustomsModule/MaterialLetters.vue'
import CMOutcomingMovements2 from '../views/NewAnnex24/CustomsModule/OutcomingMovements.vue'
import CMFixedAssets2 from '../views/NewAnnex24/CustomsModule/FixedAssets.vue'
import RMIncomingMovements2 from '../views/NewAnnex24/ReportingModule/IncomingMovements.vue'
import RMOutcomingMovements2 from '../views/NewAnnex24/ReportingModule/OutcomingMovements.vue'
import RMBalances2 from '../views/NewAnnex24/ReportingModule/Balances.vue'
import RMDisclaimers2 from '../views/NewAnnex24/ReportingModule/Disclaimers.vue'
import RMExpirations2 from '../views/NewAnnex24/ReportingModule/Expirations.vue'
import RMUsedMaterials from '../views/NewAnnex24/ReportingModule/UsedMaterials.vue'
import Visitors from '../views/CustomsAdministrator/Visitors.vue'
import Transportation from '../views/CustomsAdministrator/Transportation.vue'

// Customers Views
import customerDashboard from '../views/CustomersRole/Dashboard.vue'
import MyInventory from '../views/CustomersRole/Inventory/MyInventory.vue'
import MyOperations from '../views/CustomersRole/Appointments/MyOperations.vue'
import CustomerProductsDetail from '../views/CustomersRole/Products/Detail.vue'
import CustomerKitsDetail from '../views/CustomersRole/Kits/Detail.vue'
import MyOrders from '../views/CustomersRole/Fulfillment/MyOrders.vue'
import MyExternalOrders from '../views/CustomersRole/Fulfillment/MyExternalOrders.vue'
import CustomerAppointmentDetails from '../views/CustomersRole/Appointments/Details.vue'
import CustomerCreateOrder from '../views/CustomersRole/Fulfillment/CreateOrder.vue'
import CustomerEditPendingOrder from '../views/CustomersRole/Fulfillment/EditPending.vue'
import CustomerUpdateLabel from '../views/CustomersRole/Fulfillment/EditOtraOrders.vue'

import StockReport from '../views/Reports/Stock.vue'
import StockWithoutLocationReport from '../views/Reports/StockWithoutLocation.vue'
import StockWithoutLocationReportCSide from '../views/Reports/StockWithoutLocationCSide.vue'
import OperationsByCustomerReport from '../views/Reports/OperationsByCustomer.vue'
import OperationsByCustomerCSideReport from '../views/Reports/OperationsByCustomerCSide.vue'
import CODByShippingServiceReport from '../views/Reports/CODByShippingService.vue'
import OperationsByProductCSideReport from '../views/Reports/OperationsByProductCSide.vue'
import OperationsByProductReport from '../views/Reports/OperationsByProduct.vue'
import InternationalOperationsReport from '../views/Reports/InternationalOperations.vue'
import ProductByFulfillmentReport from '../views/Reports/ProductByFulfillment.vue'
import ProductByFulfillmentCSideReport from '../views/Reports/ProductByFulfillmentCSide.vue'
import Annex24 from '../views/Appointments/International/MassiveAnnex.vue'
import HistoryProductReport from '../views/Reports/HistoryProduct.vue'
import FulfillmentSummary from '../views/Reports/FulfillmentSummary.vue'
import BalancePerPallet from '../views/Reports/BalancePerPallet.vue';
import BoxesPerFulfillment from '../views/Reports/BoxesPerFulfillment.vue'
import CondensedFulfillment from '../views/Reports/CondensedFulfillment.vue';

import OrdersPendingsIndex from '../views/StuckOrders/Index.vue'
import OrdersPendingsComplete from '../views/StuckOrders/Create.vue'
import StuckExternalOrdersIndex from '../views/StuckExternalOrders/Index.vue'
import StuckExternalOrdersEdit from '../views/StuckExternalOrders/Edit.vue'

import OrdersPossibleFraudIndex from '../views/OrdersPossibleFraud/Index.vue'
import OrdersPossibleFraudComplete from '../views/OrdersPossibleFraud/Create.vue'

import LiverpoolAcceptance from '../views/Liverpool/Acceptance.vue'
import LiverpoolConfirmation from '../views/Liverpool/Confirmation.vue'
import LiverpoolHistory from '../views/Liverpool/History.vue'
import ProductByOrder from '../views/Reports/ProductByOrder.vue'
import AnnexDocuments from '../views/Reports/AnnexDocuments.vue'

//Customs Patches
import CustomsPatchesIndex from '../views/CustomsPatches/Index.vue';
import CustomsPatchesDetail from '../views/CustomsPatches/Detail.vue';
import CustomsPatchesDischargesReport from '../views/CustomsPatches/Reports/DischargesReport.vue';
import CustomsPatchesBalanceReport from '../views/CustomsPatches/Reports/BalanceReport.vue';
import CustomsPatchesExpirationReport from '../views/CustomsPatches/Reports/ExpirationReport.vue';

// Appointment Services
import ServicesIndex from '../views/AppointmentServices/Index.vue';
import ServicesCreate from '../views/AppointmentServices/Create.vue';
import ServicesEdit from '../views/AppointmentServices/Edit.vue'
import Backlog from '../views/Backlog/Index.vue'

// Track And Trace
import ExtraStatus from '../views/TrackNTrace/ExtraStatus.vue'
import Delivered from '../views/TrackNTrace/Delivered.vue'
import DeliveredDetails from '../views/TrackNTrace/DeliveredDetails.vue'
import WithoutMovement from '../views/TrackNTrace/WithoutMovement.vue'
import DailyCutoff from '../views/TrackNTrace/DailyCutoff.vue'
import Rejections from '../views/TrackNTrace/Rejections.vue'
import ClosingMinute from '../views/TrackNTrace/ClosingMinute.vue'

// Entrance
import VisitorsIndex from '../views/Entrance/Visitors/Index.vue'
import VisitorsCreate from '../views/Entrance/Visitors/Create.vue'
import VisitorsCreateSchedule from '../views/Entrance/Visitors/ScheduledCreate.vue'
import VisitorsEdit from '../views/Entrance/Visitors/Edit.vue'
import VisitorsDetail from '../views/Entrance/Visitors/Detail.vue'
import CarriersIndex from '../views/Entrance/Carriers/Index.vue'
import CarriersEdit from '../views/Entrance/Carriers/Edit.vue'
import CarriersCreate from '../views/Entrance/Carriers/Create.vue'
import CarriersDetail from '../views/Entrance/Carriers/Detail.vue'
import AttendancesIndex from '../views/Entrance/Attendances/Index.vue'

// Advanced Fulfillment
import AFPendings from '../views/AdvancedFulfillment/Pendings.vue'
import AFToBeSupplied from '../views/AdvancedFulfillment/ToBeSupplied.vue'
import AFSupplied from '../views/AdvancedFulfillment/Supplied.vue'
import AFPrepare from '../views/AdvancedFulfillment/Prepare.vue'
import AFSent from '../views/AdvancedFulfillment/Sent.vue'
import AFReturns from '../views/AdvancedFulfillment/Returns.vue'

// Fulfillment EON
import PendingsEon from '../views/FulfillmentEon/Pendings.vue'
import InProcessEon from '../views/FulfillmentEon/InProcess.vue'
import SentEon from '../views/FulfillmentEon/Sent.vue'
import ReturnsEon from '../views/FulfillmentEon/Returns.vue'
import EditPendigEon from '../views/FulfillmentEon/EditPending.vue'

// Fulfillment Leirem
import PendingsLeirem from '../views/FulfillmentLeirem/Pendings.vue'
import InProcessLeirem from '../views/FulfillmentLeirem/InProcess.vue'
import SentLeirem from '../views/FulfillmentLeirem/Sent.vue'
import PrepareLeirem from '../views/FulfillmentLeirem/Prepare.vue'

// Basic Search
import BasicSearch from '../views/Search/Search.vue'

// Consumables 
import ConsumablesIndex from '../views/Consumables/Index.vue'
import ConsumablesCreate from '../views/Consumables/Create.vue'

Vue.use(VueRouter)

const routes = [
  // { path: '/', redirect: '/login' },
  {
    path: '*',
    redirect: function(){
      if(store.state.clientUserId != null && store.state.clientUserId != undefined) return '/customerDashboard';
      else return '/dashboard';
    }
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Chronos - Inicio de sesión',
      auth: false,
    },
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      title: 'Chronos - Dashboard',
      auth: true,
    },
    component: Dashboard
  },
  {
    path: '/national-pendings/:m_type',
    name: 'NationalPendings',
    meta: {
      title: 'Chronos - Pendientes',
      auth: true,
    },
    component: NationalPendings
  },
  {
    path: '/national-processed/:m_type',
    name: 'NationalProcessed',
    meta: {
      title: 'Chronos - Procesadas',
      auth: true,
    },
    component: NationalProcessed
  },
  {
    path: '/international-pendings/:m_type',
    name: 'InternationalPendings',
    meta: {
      title: 'Chronos - Pendientes',
      auth: true,
    },
    component: InternationalPendings
  },
  {
    path: '/international-processed/:m_type',
    name: 'InternationalProcessed',
    meta: {
      title: 'Chronos - Procesadas',
      auth: true,
    },
    component: InternationalProcessed
  },
  {
    path: '/compliance-processed',
    name: 'ComplianceProcessed',
    meta: {
      title: 'Chronos - Procesadas',
      auth: true,
    },
    component: ComplianceProcessed
  },
  {
    path: '/compliance-no-annex',
    name: 'ComplianceNoAnnex',
    meta: {
      title: 'Chronos - Sin Anexo 24',
      auth: true,
    },
    component: ComplianceNoAnnex
  },
  {
    path: '/compliance-digital-heritage',
    name: 'ComplianceDigitalHeritage',
    meta: {
      title: 'Chronos - Acervo Digital',
      auth: true,
    },
    component: ComplianceDigitalHeritage
  },
  {
    path: '/create-appointment/:from/:m_type',
    name: 'Create_appointment',
    meta: {
      title: 'Chronos - Crear Cita',
      auth: true,
    },
    component: CreateAppointment
  },
  {
    path: '/edit-appointment/:id/:from/:m_type',
    name: 'Edit_appointment',
    meta: {
      title: 'Chronos - Editar Cita',
      auth: true,
    },
    component: EditAppointment
  },
  {
    path: '/appointment-preload-income/:id/:from/:m_type',
    name: 'PreloadIncomeAppointment',
    meta: {
      title: 'Chronos - Pre-captura Cita',
      auth: true,
    },
    component: PreloadIncomeAppointment
  },
  {
    path: '/appointment-preload-outcome/:id/:from/:m_type',
    name: 'PreloadOutcomeAppointment',
    meta: {
      title: 'Chronos - Pre-captura Cita',
      auth: true,
    },
    component: PreloadOutcomeAppointment
  },
  {
    path: '/appointment-picking/:id',
    name: 'PickingAppointment',
    meta: {
      title: 'Chronos - Pickeo de Cita',
      auth: true,
    },
    component: PickingAppointment
  },
  {
    path: '/appointment-store-product-income/:id/:from',
    name: 'StoreProductIncomeAppointment',
    meta: {
      title: 'Chronos - Almacenar Producto',
      auth: true,
    },
    component: StoreProductIncomeAppointment
  },
  {
    path: '/appointment-details/:id/:from',
    name: 'Appointment_Details',
    meta: {
      title: 'Chronos - Detalles de Cita',
      auth: true,
    },
    component: AppointmentDetails
  },
  {
    path: '/roles/create',
    name: 'RolesCreate',
    meta: {
      title: 'Chronos - Roles - Nuevo Rol',
      auth: true,
    },
    component: RolesCreate
  },
  {
    path: '/roles/edit/:id',
    name: 'RolesEdit',
    meta: {
      title: 'Chronos - Roles - Editar Rol',
      auth: true,
    },
    component: RolesEdit
  },
  {
    path: '/roles',
    name: 'RolesIndex',
    meta: {
      title: 'Chronos - Roles',
      auth: true,
    },
    component: RolesIndex
  },
  {
    path: '/shipments/:shipping_service/pendings/cod',
    name: 'ShipmentsCodPendings',
    meta: {
      title: 'Chronos - Ordenes Pendientes',
      auth: true,
    },
    component: ShipmentsCodPendings
  },
  {
    path: '/shipments/:shipping_service/in-process/cod',
    name: 'ShipmentsCodInProcess',
    meta: {
      title: 'Chronos - Ordenes En Proceso',
      auth: true,
    },
    component: ShipmentsCodInProcess
  },
  {
    path: '/shipments/:shipping_service/sent/cod',
    name: 'ShipmentsCodSent',
    meta: {
      title: 'Chronos - Ordenes Enviadas',
      auth: true,
    },
    component: ShipmentsCodSent
  },
  {
    path: '/shipments/:shipping_service/returns/cod',
    name: 'ShipmentsCodReturns',
    meta: {
      title: 'Chronos - Ordenes Retornadas',
      auth: true,
    },
    component: ShipmentsCodReturns
  },
  {
    path: '/pending-shipments/cod/edit/:id',
    name: 'EditPendingShipmentCod',
    meta: {
      title: 'Chronos - Editar Orden COD',
      auth: true,
    },
    component: EditPendingShipmentCod
  },
  {
    path: '/sent-shipments/cod/edit/:id',
    name: 'EditSentShipmentCod',
    meta: {
      title: 'Chronos - Editar Orden Enviada COD',
      auth: true,
    },
    component: EditSentShipmentCod
  },
  {
    path: '/shipments/cod/incidence/:id',
    name: 'ShipmentCodIncidence',
    meta: {
      title: 'Chronos - Retornos Incidencias',
      auth: true,
    },
    component: ShipmentCodIncidence
  },
  {
    path: '/shipments/:shipping_service/pendings/prepaid',
    name: 'ShipmentsPrepaidPendings',
    meta: {
      title: 'Chronos - Ordenes Pendientes',
      auth: true,
    },
    component: ShipmentsPrepaidPendings
  },
  {
    path: '/shipments/:shipping_service/in-process/prepaid',
    name: 'ShipmentsPrepaidInProcess',
    meta: {
      title: 'Chronos - Ordenes En Proceso',
      auth: true,
    },
    component: ShipmentsPrepaidInProcess
  },
  {
    path: '/shipments/:shipping_service/sent/prepaid',
    name: 'ShipmentsPrepaidSent',
    meta: {
      title: 'Chronos - Ordenes Enviadas',
      auth: true,
    },
    component: ShipmentsPrepaidSent
  },
  {
    path: '/shipments/:shipping_service/returns/prepaid',
    name: 'ShipmentsPrepaidReturns',
    meta: {
      title: 'Chronos - Ordenes Retornadas',
      auth: true,
    },
    component: ShipmentsPrepaidReturns
  },
  {
    path: '/pending-shipments/prepaid/edit/:id',
    name: 'EditPendingShipmentPrepaid',
    meta: {
      title: 'Chronos - Editar Orden Prepago',
      auth: true,
    },
    component: EditPendingShipmentPrepaid
  },
  {
    path: '/sent-shipments/prepaid/edit/:id',
    name: 'EditSentShipmentPrepago',
    meta: {
      title: 'Chronos - Editar Orden Enviada Prepago',
      auth: true,
    },
    component: EditSentShipmentPrepaid
  },
  {
    path: '/shipments/prepaid/incidence/:id',
    name: 'ShipmentPrepaidIncidence',
    meta: {
      title: 'Chronos - Retornos Incidencias',
      auth: true,
    },
    component: ShipmentPrepaidIncidence
  },
  {
    path: '/warehouses',
    name: 'WarehousesIndex',
    meta: {
      title: 'Chronos - Almacenes',
      auth: true,
    },
    component: WarehousesIndex
  },
  {
    path: '/warehouses/details/:id',
    name: 'WarehouseDetail',
    meta: {
      title: 'Chronos - Almacenes - Detalle',
      auth: true,
    },
    component: WarehouseDetail
  },
  {
    path: '/warehouses/edit/:id',
    name: 'WarehouseEdit',
    meta: {
      title: 'Chronos - Almacenes - Editar',
      auth: true,
    },
    component: WarehouseEdit
  },
  {
    path: '/warehouses/create',
    name: 'WarehouseCreate',
    meta: {
      title: 'Chronos - Almacenes - Nuevo Almacen',
      auth: true,
    },
    component: WarehouseCreate
  },
  {
    path: '/customers',
    name: 'CustomersIndex',
    meta: {
      title: 'Chronos - Clientes',
      auth: true,
    },
    component: CustomersIndex
  },
  {
    path: '/customers/details/:id',
    name: 'CustomersDetail',
    meta: {
      title: 'Chronos - Clientes - Detalle',
      auth: true,
    },
    component: CustomersDetail
  },
  {
    path: '/customers/edit/:id',
    name: 'CustomersEdit',
    meta: {
      title: 'Chronos - Clientes - Editar',
      auth: true,
    },
    component: CustomersEdit
  },
  {
    path: '/customers/edit/fees/:id',
    name: 'CustomersEditFees',
    meta: {
      title: 'Chronos - Clientes - Editar Cuotas',
      auth: true,
    },
    component: CustomersEditFees
  },
  {
    path: '/Customers/create',
    name: 'CustomersCreate',
    meta: {
      title: 'Chronos - Clientes - Nuevo Cliente',
      auth: true,
    },
    component: CustomersCreate
  },
  {
    path: '/customers/manage_stores/:id',
    name: 'CustomersManageStores',
    meta: {
      title: 'Chronos - Clientes - Administrar Tiendas',
      auth: true,
    },
    component: CustomersManageStores
  },
  {
    path: '/customers/manage_sms/:id',
    name: 'CustomersManageSMS',
    meta: {
      title: 'Chronos - Clientes - Configurar SMS',
      auth: true,
    },
    component: CustomersManageSMS
  },
  {
    path: '/locations',
    name: 'LocationsIndex',
    meta: {
      title: 'Chronos - Ubicaciones',
      auth: true,
    },
    component: LocationsIndex
  },
  {
    path: '/locations/details/:id',
    name: 'LocationsDetail',
    meta: {
      title: 'Chronos - Ubicaciones - Detalle',
      auth: true,
    },
    component: LocationsDetail
  },
  {
    path: '/locations/edit/:id',
    name: 'LocationsEdit',
    meta: {
      title: 'Chronos - Ubicaciones - Editar',
      auth: true,
    },
    component: LocationsEdit
  },
  {
    path: '/locations/create',
    name: 'LocationsCreate',
    meta: {
      title: 'Chronos - Ubicaciones - Nueva Ubicación',
      auth: true,
    },
    component: LocationsCreate
  },
  {
    path: '/categories',
    name: 'CategoriesIndex',
    meta: {
      title: 'Chronos - Categorias',
      auth: true,
    },
    component: CategoriesIndex
  },
  {
    path: '/categories/details/:id',
    name: 'CategoriesDetail',
    meta: {
      title: 'Chronos - Categorias - Detalle',
      auth: true,
    },
    component: CategoriesDetail
  },
  {
    path: '/categories/edit/:id',
    name: 'CategoriesEdit',
    meta: {
      title: 'Chronos - Categorias - Editar',
      auth: true,
    },
    component: CategoriesEdit
  },
  {
    path: '/categories/create',
    name: 'CategoriesCreate',
    meta: {
      title: 'Chronos - Categorías - Nueva Categoría',
      auth: true,
    },
    component: CategoriesCreate
  },
  {
    path: '/custom-agents',
    name: 'CustomAgentsIndex',
    meta: {
      title: 'Chronos - Agentes Aduanales',
      auth: true,
    },
    component: CustomAgentsIndex
  },
  {
    path: '/custom-agents/details/:id',
    name: 'CustomAgentsDetail',
    meta: {
      title: 'Chronos - Agentes Aduanales - Detalle',
      auth: true,
    },
    component: CustomAgentsDetail
  },
  {
    path: '/custom-agents/edit/:id',
    name: 'CustomAgentsEdit',
    meta: {
      title: 'Chronos - Agentes Aduanales - Editar',
      auth: true,
    },
    component: CustomAgentsEdit
  },
  {
    path: '/custom-agents/create',
    name: 'CustomAgentsCreate',
    meta: {
      title: 'Chronos - Agentes Aduanales - Nueva Agente Aduanal',
      auth: true,
    },
    component: CustomAgentsCreate
  },
  {
    path: '/kits',
    name: 'KitsIndex',
    meta: {
      title: 'Chronos - Kits',
      auth: true,
    },
    component: KitsIndex
  },
  {
    path: '/kits/details/:id',
    name: 'KitsDetail',
    meta: {
      title: 'Chronos - Kits - Detalle',
      auth: true,
    },
    component: KitsDetail
  },
  {
    path: '/kits/edit/:id',
    name: 'KitsEdit',
    meta: {
      title: 'Chronos - Kits - Editar',
      auth: true,
    },
    component: KitsEdit
  },
  {
    path: '/kits/create',
    name: 'KitsCreate',
    meta: {
      title: 'Chronos - Kits - Nuevo Kit',
      auth: true,
    },
    component: KitsCreate
  },
  {
    path: '/products',
    name: 'ProductsIndex',
    meta: {
      title: 'Chronos - Productos',
      auth: true,
    },
    component: ProductsIndex
  },
  {
    path: '/products/details/:id',
    name: 'ProductsDetail',
    meta: {
      title: 'Chronos - Productos - Detalle',
      auth: true,
    },
    component: ProductsDetail
  },
  {
    path: '/products/refurbish/:id',
    name: 'Refurbish',
    meta: {
      title: 'Chronos - Productos - Reacondicionar',
      auth: true,
    },
    component: Refurbish
  },
  {
    path: '/products/edit/:id',
    name: 'ProductsEdit',
    meta: {
      title: 'Chronos - Productos - Editar',
      auth: true,
    },
    component: ProductsEdit
  },
  {
    path: '/products/create',
    name: 'ProductsCreate',
    meta: {
      title: 'Chronos - Productos - Nuevo Producto',
      auth: true,
    },
    component: ProductsCreate
  },  
  {
    path: '/origin-suppliers',
    name: 'OriginSuppliersIndex',
    meta: {
      title: 'Chronos - Proveedores de Origen',
      auth: true,
    },
    component: OriginSuppliersIndex
  },
  {
    path: '/origin-suppliers/details/:id',
    name: 'OriginSuppliersDetail',
    meta: {
      title: 'Chronos - Proveedores de Origen - Detalle',
      auth: true,
    },
    component: OriginSuppliersDetail
  },
  {
    path: '/origin-suppliers/edit/:id',
    name: 'OriginSuppliersEdit',
    meta: {
      title: 'Chronos - Proveedores de Origen - Editar',
      auth: true,
    },
    component: OriginSuppliersEdit
  },
  {
    path: '/origin-suppliers/create',
    name: 'OriginSuppliersCreate',
    meta: {
      title: 'Chronos - Proveedores de Origen - Nuevo Proveedor de Origen',
      auth: true,
    },
    component: OriginSuppliersCreate
  },
  {
    path: '/gates',
    name: 'GatesIndex',
    meta: {
      title: 'Chronos - Puertas',
      auth: true,
    },
    component: GatesIndex
  },
  {
    path: '/gates/details/:id',
    name: 'GatesDetail',
    meta: {
      title: 'Chronos - Puertas - Detalle',
      auth: true,
    },
    component: GatesDetail
  },
  {
    path: '/gates/edit/:id',
    name: 'GatesEdit',
    meta: {
      title: 'Chronos - Puertas - Editar',
      auth: true,
    },
    component: GatesEdit
  },
  {
    path: '/gates/create',
    name: 'GatesCreate',
    meta: {
      title: 'Chronos - Puertas - Nueva Puerta',
      auth: true,
    },
    component: GatesCreate
  }, 
  {
    path: '/boxes',
    name: 'BoxesIndex',
    meta: {
      title: 'Chronos - Cajas',
      auth: true,
    },
    component: BoxesIndex
  },
  {
    path: '/boxes/edit/:id',
    name: 'BoxesEdit',
    meta: {
      title: 'Chronos - Cajas - Editar',
      auth: true,
    },
    component: BoxesEdit
  },
  {
    path: '/boxes/create',
    name: 'BoxesCreate',
    meta: {
      title: 'Chronos - Cajas - Nueva Caja',
      auth: true,
    },
    component: BoxesCreate
  },
  {
    path: '/operation/pendings/:from/:m_type',
    name: 'OperationPendings',
    meta: {
      title: 'Chronos - Operaciones - Pendientes',
      auth: true,
    },
    component: OperationPendings
  },
  {
    path: '/operation/process-pending/:id/:from/:m_type',
    name: 'ProcessPending',
    meta: {
      title: 'Chronos - Operaciones - Procesar Pendiente',
      auth: true,
    },
    component: ProcessPending
  },


  {
    path: '/operation/on-premises/:from/:m_type',
    name: 'OperationOnPremises',
    meta: {
      title: 'Chronos - Operaciones - En patio',
      auth: true,
    },
    component: OperationOnPremises
  },
  
  {
    path: '/operation/on-ramp/:from/:m_type',
    name: 'OperationOnRamp',
    meta: {
      title: 'Chronos - Operaciones - En rampa',
      auth: true,
    },
    component: OperationOnRamp
  },  
  {
    path: '/operation/process-in-ramp/:id/:from/:m_type',
    name: 'ProcessInPortal',
    meta: {
      title: 'Chronos - Operaciones - Poner en Portal',
      auth: true,
    },
    component: ProcessInPortal
  },
  {
    path: '/operation/process-in-process/:id/:from/:m_type',
    name: 'ProcessInProcess',
      meta: {
        title: 'Chronos - Operaciones - Procesar',
        auth: true,
      },
    component: ProcessInProcess
  },
  {
    path: '/operation/in-process/:from/:m_type',
    name: 'OperationInProcess',
    meta: {
      title: 'Chronos - Operaciones - En Proceso',
      auth: true,
    },
    component: OperationInProcess
  },
  {
    path: '/operation/process-end-process/:id/:from/:m_type',
    name: 'ProcessEndProcess',
      meta: {
        title: 'Chronos - Operaciones - Terminar Proceso',
        auth: true,
      },
    component: ProcessEndProcess
  },
  {
    path: '/operation/pending-out/:from/:m_type',
    name: 'PendingOut',
      meta: {
        title: 'Chronos - Operaciones - Pendiente de Salida',
        auth: true,
      },
    component: PendingOut
  },
  {
    path: '/operation/process-pending-out/:id/:from/:m_type',
    name: 'ProcessPendingOut',
      meta: {
        title: 'Chronos - Operaciones - Registrar Salida',
        auth: true,
      },
    component: ProcessPendingOut
  },
  {
    path: '/incidence_detail/:id',
    name: 'IncidenceDetail',
      meta: {
        title: 'Chronos - Citas - Incidencia',
        auth: true,
      },
    component: IncidenceDetail
  },
  {
    path: '/incidence_edit/:id',
    name: 'IncidenceEdit',
      meta: {
        title: 'Chronos - Citas - Editar Incidencia',
        auth: true,
      },
    component: IncidenceEdit
  },
  {
    path: '/outcome_appointment/:id',
    name: 'OutcomeAppointment',
      meta: {
        title: 'Chronos - Citas - Crossdock Documental Salida',
        auth: true,
      },
    component: OutcomeAppointment
  },
  {
    path: '/upload-multiple-appointments',
    name: 'MultipleAppointments',
      meta: {
        title: 'Chronos - Citas - Multiple',
        auth: true,
      },
    component: MultipleAppointments
  },
  {
    path: '/collection',
    name: 'CollectionIndex',
    meta: {
      title: 'Chronos - Cobranza',
      auth: true,
    },
    component: CollectionIndex
  },
  {
    path: '/cod-collection',
    name: 'CodCollectionIndex',
    meta: {
      title: 'Chronos - Recolección COD',
      auth: true,
    },
    component: CodCollectionIndex
  },
  {
    path: '/users',
    name: 'UsersIndex',
    meta: {
      title: 'Chronos - Usuarios',
      auth: true,
    },
    component: UsersIndex
  },
  {
    path: '/users/create',
    name: 'UsersCreate',
    meta: {
      title: 'Chronos - Usuarios - Nuevo Usuario',
      auth: true,
    },
    component: UsersCreate
  },
  {
    path: '/users/edit/:id',
    name: 'UsersEdit',
    meta: {
      title: 'Chronos - Usuarios - Editar Usuario',
      auth: true,
    },
    component: UsersEdit
  },  
  {
    path: '/search',
    name: 'Search',
    meta: {
      title: 'Chronos - Busqueda - Buscar Orden',
      auth: true,
    },
    component: Search
  },
  {
    path: '/yego-reports',
    name: 'YegoReports',
    meta: {
      title: 'Chronos - Reportes Yego',
      auth: true,
    },
    component: YegoReports
  },
  {
    path: '/relocations',
    name: 'RelocationsIndex',
    meta: {
      title: 'Chronos - Reubicación',
      auth: true,
    },
    component: RelocationsIndex
  },
  {
    path: '/relocations/details/:id',
    name: 'RelocationsDetail',
    meta: {
      title: 'Chronos - Reubicación - Detalle',
      auth: true,
    },
    component: RelocationsDetail
  },
  {
    path: '/relocations/create',
    name: 'RelocationsCreate',
    meta: {
      title: 'Chronos - Reubicaciones - Nueva Reubicación',
      auth: true,
    },
    component: RelocationsCreate
  },
  {
    path: '/relocations/create_with_scanner',
    name: 'RelocationsCreateWithScanner',
    meta: {
      title: 'Chronos - Reubicaciones - Nueva Reubicación',
      auth: true,
    },
    component: RelocationsCreateWithScanner
  },
  {
    path: '/customs_data/taxpayer_data',
    name: 'TaxpayerData',
    meta: {
      title: 'Operador Aduanal - Catálogos - Datos del Contribuyente',
      auth: true,
    },
    component: TaxpayerData
  },
  {
    path: '/customs_data/materials',
    name: 'Materials',
    meta: {
      title: 'Operador Aduanal - Catálogos - Materiales',
      auth: true,
    },
    component: Materials
  },  
  {
    path: '/customs_data/finished_product',
    name: 'Products',
    meta: {
      title: 'Operador Aduanal - Catálogos - Productos',
      auth: true,
    },
    component: Products
  },    
  {
    path: '/customs_data/suppliers',
    name: 'Suppliers',
    meta: {
      title: 'Operador Aduanal - Catálogos - Proveedores',
      auth: true,
    },
    component: Suppliers
  },
  {
    path: '/customs_data/customers',
    name: 'Customers',
    meta: {
      title: 'Operador Aduanal - Catálogos - Clientes',
      auth: true,
    },
    component: Customers
  },
  {
    path: '/customs_data/submanufacturing',
    name: 'Submanufacturing',
    meta: {
      title: 'Operador Aduanal - Catálogos - Submanufactura/Submaquila',
      auth: true,
    },
    component: Submanufacturing
  },
  {
    path: '/customs_data/agents_and_customs_agents',
    name: 'AgentsAndCustomAgents',
    meta: {
      title: 'Operador Aduanal - Catálogos - Agentes/Agentes Aduanales',
      auth: true,
    },
    component: AgentsAndCustomAgents
  },
  {
    path: '/customs_data/fixed_assets',
    name: 'FixedAssets',
    meta: {
      title: 'Operador Aduanal - Catálogos - Activos Fijos',
      auth: true,
    },
    component: FixedAssets
  },
  {
    path: '/customs_data/incoming_movements',
    name: 'IncomingMovements',
    meta: {
      title: 'Operador Aduanal - Módulo de Interfacez - Entradas',
      auth: true,
    },
    component: IncomingMovements
  },
  {
    path: '/customs_data/outcoming_movements',
    name: 'OutcomingMovements',
    meta: {
      title: 'Operador Aduanal - Módulo de Interfacez - Salidas',
      auth: true,
    },
    component: OutcomingMovements
  },
  {
    path: '/customs_data/manufacturing_movements',
    name: 'ManufacturingMovements',
    meta: {
      title: 'Operador Aduanal - Módulo de Interfacez - Movimientos de Manufactura',
      auth: true,
    },
    component: ManufacturingMovements
  },
  {
    path: '/customs_data/cm_incoming_movements',
    name: 'CMIncomingMovements',
    meta: {
      title: 'Operador Aduanal - Módulo de Aduanas - Entradas',
      auth: true,
    },
    component: CMIncomingMovements
  },  
  {
    path: '/customs_data/cm_outcoming_movements',
    name: 'CMOutcomingMovements',
    meta: {
      title: 'Operador Aduanal - Módulo de Aduanas - Salidas',
      auth: true,
    },
    component: CMOutcomingMovements
  },   
  {
    path: '/customs_data/cm_fixed_assets',
    name: 'CMFixedAssets',
    meta: {
      title: 'Operador Aduanal - Módulo de Aduanas - Activo Fijo',
      auth: true,
    },
    component: CMFixedAssets
  },
  {
    path: '/customs_data/rm_incoming_movements',
    name: 'RMIncomingMovements',
    meta: {
      title: 'Operador Aduanal - Módulo de Reportes - Entradas',
      auth: true,
    },
    component: RMIncomingMovements
  },
  {
    path: '/customs_data/rm_outcoming_movements',
    name: 'RMOutcomingMovements',
    meta: {
      title: 'Operador Aduanal - Módulo de Reportes - Salidas',
      auth: true,
    },
    component: RMOutcomingMovements
  },
  {
    path: '/customs_data/rm_balances',
    name: 'RMBalances',
    meta: {
      title: 'Operador Aduanal - Módulo de Reportes - Saldos',
      auth: true,
    },
    component: RMBalances
  },
  {
    path: '/customs_data/rm_disclaimers',
    name: 'RMDisclaimers',
    meta: {
      title: 'Operador Aduanal - Módulo de Reportes - Descargos',
      auth: true,
    },
    component: RMDisclaimers
  },
  {
    path: '/customs_data/rm_settings',
    name: 'RMSettings',
    meta: {
      title: 'Operador Aduanal - Módulo de Reportes - Ajustes',
      auth: true,
    },
    component: RMSettings
  },
  {
    path: '/customs_data/rm_expiration',
    name: 'RMExpirations',
    meta: {
      title: 'Operador Aduanal - Módulo de Reportes - Vencimientos',
      auth: true,
    },
    component: RMExpirations
  },
  {
    path: '/annex_24/taxpayer_data',
    name: 'TaxpayerData2',
    meta: {
      title: 'Operador Aduanal - Catálogos - Datos del Contribuyente',
      auth: true,
    },
    component: TaxpayerData2
  },
  {
    path: '/annex_24/materials',
    name: 'Materials2',
    meta: {
      title: 'Operador Aduanal - Catálogos - Materiales',
      auth: true,
    },
    component: Materials2
  },  
  {
    path: '/annex_24/finished_product',
    name: 'Products2',
    meta: {
      title: 'Operador Aduanal - Catálogos - Productos',
      auth: true,
    },
    component: Products2
  },    
  {
    path: '/annex_24/suppliers',
    name: 'Suppliers2',
    meta: {
      title: 'Operador Aduanal - Catálogos - Proveedores',
      auth: true,
    },
    component: Suppliers2
  },
  {
    path: '/annex_24/customers',
    name: 'Customers2',
    meta: {
      title: 'Operador Aduanal - Catálogos - Clientes',
      auth: true,
    },
    component: Customers2
  },
  {
    path: '/annex_24/agents_and_customs_agents',
    name: 'AgentsAndCustomAgents2',
    meta: {
      title: 'Operador Aduanal - Catálogos - Agentes/Agentes Aduanales',
      auth: true,
    },
    component: AgentsAndCustomAgents2
  },
  {
    path: '/annex_24/cm_incoming_movements',
    name: 'CMIncomingMovements2',
    meta: {
      title: 'Operador Aduanal - Módulo de Aduanas - Entradas',
      auth: true,
    },
    component: CMIncomingMovements2
  },
  {
    path: '/annex_24/cm_material_letters',
    name: 'CMMaterialLetters',
    meta: {
      title: 'Operador Aduanal - Módulo de Aduanas - Cartas de Materiales',
      auth: true,
    },
    component: CMMaterialLetters
  },  
  {
    path: '/annex_24/cm_outcoming_movements',
    name: 'CMOutcomingMovements2',
    meta: {
      title: 'Operador Aduanal - Módulo de Aduanas - Salidas',
      auth: true,
    },
    component: CMOutcomingMovements2
  },   
  {
    path: '/annex_24/cm_fixed_assets',
    name: 'CMFixedAssets2',
    meta: {
      title: 'Operador Aduanal - Módulo de Aduanas - Activo Fijo',
      auth: true,
    },
    component: CMFixedAssets2
  },
  {
    path: '/annex_24/rm_incoming_movements',
    name: 'RMIncomingMovements2',
    meta: {
      title: 'Operador Aduanal - Módulo de Reportes - Entradas',
      auth: true,
    },
    component: RMIncomingMovements2
  },
  {
    path: '/annex_24/rm_outcoming_movements',
    name: 'RMOutcomingMovements2',
    meta: {
      title: 'Operador Aduanal - Módulo de Reportes - Salidas',
      auth: true,
    },
    component: RMOutcomingMovements2
  },
  {
    path: '/annex_24/rm_balances',
    name: 'RMBalances2',
    meta: {
      title: 'Operador Aduanal - Módulo de Reportes - Saldos',
      auth: true,
    },
    component: RMBalances2
  },
  {
    path: '/annex_24/rm_disclaimers',
    name: 'RMDisclaimers2',
    meta: {
      title: 'Operador Aduanal - Módulo de Reportes - Descargos',
      auth: true,
    },
    component: RMDisclaimers2
  },
  {
    path: '/annex_24/rm_expiration',
    name: 'RMExpirations2',
    meta: {
      title: 'Operador Aduanal - Módulo de Reportes - Vencimientos',
      auth: true,
    },
    component: RMExpirations2
  },
  {
    path: '/annex_24/rm_used_materials',
    name: 'RMUsedMaterials',
    meta: {
      title: 'Operador Aduanal - Módulo de Reportes - Materiales usados',
      auth: true,
    },
    component: RMUsedMaterials
  },
  {
    path: '/customs_data/visitors',
    name: 'Visitors',
    meta: {
      title: 'Operador Aduanal - Módulo de Reportes - Visitantes',
      auth: true,
    },
    component: Visitors
  },
  {
    path: '/customs_data/transportation',
    name: 'Transportation',
    meta: {
      title: 'Operador Aduanal - Módulo de Reportes - Transporte E/S',
      auth: true,
    },
    component: Transportation
  },
  {
    path: '/customerDashboard',
    name: 'customerDashboard',
    meta: {
      title: 'home',
      auth: true,
    },
    component: customerDashboard
  },
  {
    path: '/customer_kits_details/:id',
    name: 'CustomerKitsDetail',
    meta: {
      title: 'Kits - Detalles',
      auth: true,
    },
    component: CustomerKitsDetail
  },
  {
    path: '/customer_products_details/:id',
    name: 'CustomerProductsDetail',
    meta: {
      title: 'Productos - Detalles',
      auth: true,
    },
    component: CustomerProductsDetail
  },
  {
    path: '/customer_appointments',
    name: 'MyOperations',
    meta: {
      title: 'Citas',
      auth: true,
    },
    component: MyOperations
  },
  {
    path: '/my_inventory',
    name: 'MyInventory',
    meta: {
      title: 'Inventario',
      auth: true,
    },
    component: MyInventory
  },
  {
    path: '/my_orders',
    name: 'MyOrders',
    meta: {
      title: 'Ordenes',
      auth: true,
    },
    component: MyOrders
  },
  {
    path: '/my_eorders',
    name: 'MyExternalOrders',
    meta: {
      title: 'Ordenes',
      auth: true,
    },
    component: MyExternalOrders
  },
  {
    path: '/customer_appointment_details/:id/:from',
    name: 'CustomerAppointmentDetails',
    meta: {
      title: 'Cita - Detalles',
      auth: true,
    },
    component: CustomerAppointmentDetails
  },

  {
    path: '/customer_fulfillment_create_order',
    name: 'CustomerCreateOrder',
    meta: {
      title: 'Crear Nueva Orden',
      auth: true,
    },
    component: CustomerCreateOrder
  },
  {
    path: '/create_order',
    name: 'CreateOrder',
    meta: {
      title: 'Crear Nueva Orden',
      auth: true,
    },
    component: CreateOrder
  },
  {
    path: '/create_multiple_orders',
    name: 'CreateMultipleOrders',
    meta: {
      title: 'Crear Ordenes',
      auth: true,
    },
    component: CreateMultipleOrders
  },
  {
    path: '/customer_fulfillment_edit_order/:id',
    name: 'CustomerEditPendingOrder',
    meta: {
      title: 'Editar Orden',
      auth: true,
    },
    component: CustomerEditPendingOrder
  },
  {
    path: '/customer_fulfillment_upload_label/:id',
    name: 'CustomerUpdateLabel',
    meta: {
      title: 'Editar Orden',
      auth: true,
    },
    component: CustomerUpdateLabel
  },
  {
    path: '/reports/stock',
    name: 'StockReport',
    meta: {
      title: 'Reportes - Existencias',
      auth: true,
    },
    component: StockReport
  },
  {
    path: '/reports/stocks',
    name: 'StockWithoutLocationReport',
    meta: {
      title: 'Reportes - Existencias',
      auth: true,
    },
    component: StockWithoutLocationReport
  },
  {
    path: '/reports/stocks_c',
    name: 'StockWithoutLocationReportCSide',
    meta: {
      title: 'Reportes - Existencias',
      auth: true,
    },
    component: StockWithoutLocationReportCSide
  },
  {
    path: '/reports/fulfillment_summary',
    name: 'FulfillmentSummary',
    meta: {
      title: 'Reportes - Resumen Fulfillment',
      auth: true,
    },
    component: FulfillmentSummary
  },
  {
    path: '/reports/balance_pallet',
    name: 'BalancePerPallet',
    meta: {
      title: 'Reportes - Saldos Por Tarima',
      auth: true,
    },
    component: BalancePerPallet
  },
  {
    path: '/reports/operations_by_customer',
    name: 'OperationsByCustomerReport',
    meta: {
      title: 'Reportes - Operaciones x Cliente',
      auth: true,
    },
    component: OperationsByCustomerReport
  },
  {
    path: '/reports/operations_by_customer_c',
    name: 'OperationsByCustomerCSideReport',
    meta: {
      title: 'Reportes - Operaciones',
      auth: true,
    },
    component: OperationsByCustomerCSideReport
  },
  
  {
    path: '/reports/cod_by_shipping_service',
    name: 'CODByShippingServiceReport',
    meta: {
      title: 'Reportes - COD por Paquetería',
      auth: true,
    },
    component: CODByShippingServiceReport
  },

  
  {
    path: '/reports/operations_by_product_c',
    name: 'OperationsByProductCSideReport',
    meta: {
      title: 'Reportes - Operaciones Por Producto',
      auth: true,
    },
    component: OperationsByProductCSideReport
  },
  {
    path: '/reports/products_by_fulfillment_c',
    name: 'ProductByFulfillmentCSideReport',
    meta: {
      title: 'Reportes - Operaciones Por Producto',
      auth: true,
    },
    component: ProductByFulfillmentCSideReport
  },
  {
    path: '/reports/boxes_per_fulfillment',
    name: 'BoxesPerFulfillment',
    meta: {
      title: 'Reportes - Cajas Por Orden',
      auth: true,
    },
    component: BoxesPerFulfillment
  },
  {
    path: '/reports/condensed_fulfillment',
    name: 'CondensedFulfillment',
    meta: {
      title: 'Reportes - Condensado Fulfillment',
      auth: true,
    },
    component: CondensedFulfillment
  },
  {
    path: '/reports/operations_by_product',
    name: 'OperationsByProductReport',
    meta: {
      title: 'Reportes - Operaciones x Producto',
      auth: true,
    },
    component: OperationsByProductReport
  },
  {
    path: '/reports/international_operations',
    name: 'InternationalOperationsReport',
    meta: {
      title: 'Reportes - Operaciones Internacionales',
      auth: true,
    },
    component: InternationalOperationsReport
  },
  {
    path: '/reports/products_by_fulfillment',
    name: 'ProductByFulfillmentReport',
    meta: {
      title: 'Reportes - Producto x Fulfillment',
      auth: true,
    },
    component: ProductByFulfillmentReport
  },
  {
    path: '/reports/history_product',
    name: 'HistoryProductReport',
    meta: {
      title: 'Producto - Historial',
      auth: true,
    },
    component: HistoryProductReport
  },
  
  {
    path: '/massive_annex_24',
    name: 'Annex24',
    meta: {
      title: 'Anexo 24 - Masivo',
      auth: true,
    },
    component: Annex24
  },
  {
    path: '/customs_patches/customs_documents',
    name: 'CustomsPatchesIndex',
    component: CustomsPatchesIndex,
    meta: {
      title: 'Anexo 24',
      auth: true,
    },
  },
  {
    path: '/customs_patches/details/:id',
    component: CustomsPatchesDetail,
    meta: {
      title: 'Detalles',
      auth: true,
    },
  },
  {
    path: '/customs_patches/reports/reports_discharges',
    component: CustomsPatchesDischargesReport,
    meta: {
      title: 'Reportes de descargos',
      auth: true,
    },
  },
  {
    path: '/customs_patches/reports/balance',
    component: CustomsPatchesBalanceReport,
    meta: {
      title: 'Reportes de saldos',
      auth: true,
    },
  },
  {
    path: '/customs_patches/reports/expiration',
    component: CustomsPatchesExpirationReport,
    meta: {
      title: 'Reportes de vencimientos',
      auth: true,
    },
  },
  {
    path: '/stuck_orders',
    name: 'OrdersPendingsIndex',
    meta: {
      title: 'Ordenes Pendientes',
      auth: true,
    },
    component: OrdersPendingsIndex
  },
  {
    path: '/stuck_external_orders',
    name: 'StuckExternalOrdersIndex',
    meta: {
      title: 'Ordenes Externas Atoradas',
      auth: true,
    },
    component: StuckExternalOrdersIndex
  },
  {
    path: '/stuck_external_orders/edit/:id',
    name: 'StuckExternalOrdersEdit',
    meta: {
      title: 'Ordenes Externas: Actualizar',
      auth: true,
    },
    component: StuckExternalOrdersEdit
  },
  {
    path: '/stuck_orders/complete/:id',
    name: 'OrdersPendingsComplete',
    meta: {
      title: 'Ordenes Pendientes - Completar Orden',
      auth: true,
    },
    component: OrdersPendingsComplete
  },
  {
    path: '/orders_possible_fraud',
    name: 'OrdersPossibleFraudIndex',
    meta: {
      title: 'Ordenes Posible Fraude',
      auth: true,
    },
    component: OrdersPossibleFraudIndex
  },
  {
    path: '/orders_possible_fraud/complete/:id',
    name: 'OrdersPossibleFraudComplete',
    meta: {
      title: 'Ordenes Posible Fraude - Completar Orden',
      auth: true,
    },
    component: OrdersPossibleFraudComplete
  },
  {
    path: '/liverpool/acceptance',
    name: 'LiverpoolAcceptance',
    meta: {
      title: 'Liverpool - Aceptación',
      auth: true,
    },
    component: LiverpoolAcceptance
  },
  {
    path: '/liverpool/confirmation',
    name: 'LiverpoolConfirmation',
    meta: {
      title: 'Liverpool - Confirmación',
      auth: true,
    },
    component: LiverpoolConfirmation
  },
  {
    path: '/liverpool/history',
    name: 'LiverpoolHistory',
    meta: {
      title: 'Liverpool - Historial',
      auth: true,
    },
    component: LiverpoolHistory
  },
  {
    path: '/reports/products_by_order',
    name: 'ProductByOrder',
    meta: {
      title: 'Reportes - Producto Por Categoría',
      auth: true,
    },
    component: ProductByOrder
  },
  {
    path: '/reports/annex_documents',
    name: 'AnnexDocuments',
    meta: {
      title: 'Reportes - Documento Anexo 24',
      auth: true,
    },
    component: AnnexDocuments
  },
  {
    path: '/services/create',
    name: 'ServicesCreate',
    meta: {
      title: 'Chronos - Servicios - Nuevo Servicio',
      auth: true,
    },
    component: ServicesCreate
  },
  {
    path: '/services/edit/:id',
    name: 'ServicesEdit',
    meta: {
      title: 'Chronos - Servicios - Editar Servicio',
      auth: true,
    },
    component: ServicesEdit
  },
  {
    path: '/services',
    name: 'ServicesIndex',
    meta: {
      title: 'Chronos - Servicios',
      auth: true,
    },
    component: ServicesIndex
  },
  {
    path: '/backlog',
    name: 'Backlog',
    meta: {
      title: 'Chronos - Backlog',
      auth: true,
    },
    component: Backlog
  },
  {
    path: '/tnt_extra_status',
    name: 'ExtraStatus',
    meta: {
      title: 'Chronos - Estatus Extra',
      auth: true,
    },
    component: ExtraStatus
  },
  {
    path: '/tnt_delivered',
    name: 'Delivered',
    meta: {
      title: 'Chronos - Entregados',
      auth: true,
    },
    component: Delivered
  },
  {
    path: '/tnt_delivered_details/:date/:client',
    name: 'DeliveredDetails',
    meta: {
      title: 'Chronos - Entregados',
      auth: true,
    },
    component: DeliveredDetails
  },
  {
    path: '/tnt_without_movement',
    name: 'WithoutMovement',
    meta: {
      title: 'Chronos - Sin Movimiento',
      auth: true,
    },
    component: WithoutMovement
  },
  {
    path: '/tnt_daily_cutoff',
    name: 'DailyCutoff',
    meta: {
      title: 'Chronos - Corte Diario',
      auth: true,
    },
    component: DailyCutoff
  },
  {
    path: '/tnt_rejections',
    name: 'Rejections',
    meta: {
      title: 'Chronos - Rechazos',
      auth: true,
    },
    component: Rejections
  },
  {
    path: '/tnt_closing_minute',
    name: 'ClosingMinute',
    meta: {
      title: 'Chronos - Acta de Cierre',
      auth: true,
    },
    component: ClosingMinute
  },
  {
    path: '/entrance/visitors',
    name: 'VisitorsIndex',
    meta: {
      title: 'Chronos - Visitantes',
      auth: true,
    },
    component: VisitorsIndex
  },
  {
    path: '/entrance/visitors/create/:id',
    name: 'VisitorsCreate',
    meta: {
      title: 'Chronos - Visitantes - Crear',
      auth: true,
    },
    component: VisitorsCreate
  },
  {
    path: '/entrance/visitors/create_schedule',
    name: 'VisitorsCreateSchedule',
    meta: {
      title: 'Chronos - Visitantes - Crear',
      auth: true,
    },
    component: VisitorsCreateSchedule
  },
  {
    path: '/entrance/visitors/edit/:id',
    name: 'VisitorsEdit',
    meta: {
      title: 'Chronos - Visitantes - Editar',
      auth: true,
    },
    component: VisitorsEdit
  },
  {
    path: '/entrance/visitors/detail/:id',
    name: 'VisitorsDetail',
    meta: {
      title: 'Chronos - Visitantes - Detalle',
      auth: true,
    },
    component: VisitorsDetail
  },
  {
    path: '/entrance/carriers',
    name: 'CarriersIndex',
    meta: {
      title: 'Chronos - Transportistas',
      auth: true,
    },
    component: CarriersIndex
  },
  {
    path: '/entrance/carriers/create',
    name: 'CarriersCreate',
    meta: {
      title: 'Chronos - Transportistas - Crear',
      auth: true,
    },
    component: CarriersCreate
  },
  {
    path: '/entrance/carriers/edit/:id',
    name: 'CarriersEdit',
    meta: {
      title: 'Chronos - Transportistas - Editar',
      auth: true,
    },
    component: CarriersEdit
  },
  {
    path: '/entrance/carriers/detail/:id',
    name: 'CarriersDetail',
    meta: {
      title: 'Chronos - Transportistas - Detalle',
      auth: true,
    },
    component: CarriersDetail
  },
  {
    path: '/entrance/attendances',
    name: 'AttendancesIndex',
    meta: {
      title: 'Chronos - Asistencias',
      auth: true,
    },
    component: AttendancesIndex
  },
  {
    path: '/advanced-fulfillment/pendings/:shipping_service',
    name: 'AFPendings',
    meta: {
      title: 'Chronos - Fulfllment Avanzado - Pendientes',
      auth: true,
    },
    component: AFPendings
  },
  {
    path: '/advanced-fulfillment/to_be_supplied/:shipping_service',
    name: 'AFToBeSupplied',
    meta: {
      title: 'Chronos - Fulfllment Avanzado - Por Surtir',
      auth: true,
    },
    component: AFToBeSupplied
  },
  {
    path: '/advanced-fulfillment/supplied/:shipping_service',
    name: 'AFSupplied',
    meta: {
      title: 'Chronos - Fulfllment Avanzado - Surtidas',
      auth: true,
    },
    component: AFSupplied
  },
  {
    path: '/advanced-fulfillment/prepare/:shipping_service',
    name: 'AFPrepare',
    meta: {
      title: 'Chronos - Fulfllment Avanzado - Pendientes',
      auth: true,
    },
    component: AFPrepare
  },
  {
    path: '/advanced-fulfillment/sent/:shipping_service',
    name: 'AFSent',
    meta: {
      title: 'Chronos - Fulfllment Avanzado - Enviadas',
      auth: true,
    },
    component: AFSent
  },
  {
    path: '/advanced-fulfillment/returned/:shipping_service',
    name: 'AFReturns',
    meta: {
      title: 'Chronos - Fulfllment Avanzado - Retornos',
      auth: true,
    },
    component: AFReturns
  },
  {
    path: '/external_shipments/pendings',
    name: 'PendingsEon',
    meta: {
      title: 'Chronos - Fulfllment Eon - Pendientes',
      auth: true,
    },
    component: PendingsEon
  },
  {
    path: '/external_shipments/edit_pending/:id',
    name: 'EditPendigEon',
    meta: {
      title: 'Chronos - Fulfllment Eon - Editar Pendiente',
      auth: true,
    },
    component: EditPendigEon
  },
  {
    path: '/external_shipments/in-process',
    name: 'InProcessEon',
    meta: {
      title: 'Chronos - Fulfllment Eon - En Proceso',
      auth: true,
    },
    component: InProcessEon
  },
  {
    path: '/external_shipments/sent',
    name: 'SentEon',
    meta: {
      title: 'Chronos - Fulfllment Eon - Enviadas',
      auth: true,
    },
    component: SentEon
  },
  {
    path: '/external_shipments/returns',
    name: 'ReturnsEon',
    meta: {
      title: 'Chronos - Fulfllment Eon - Retornos',
      auth: true,
    },
    component: ReturnsEon
  },
  {
    path: '/shipments_leirem/pendings',
    name: 'PendingsLeirem',
    meta: {
      title: 'Chronos - Fulfllment Leirem - Pendientes',
      auth: true,
    },
    component: PendingsLeirem
  },
  {
    path: '/shipments_leirem/in-process',
    name: 'InProcessLeirem',
    meta: {
      title: 'Chronos - Fulfllment Leirem - En Proceso',
      auth: true,
    },
    component: InProcessLeirem
  },
  {
    path: '/shipments_leirem/sent',
    name: 'SentLeirem',
    meta: {
      title: 'Chronos - Fulfllment Leirem - Enviadas',
      auth: true,
    },
    component: SentLeirem
  },
  {
    path: '/shipments_leirem/supply/:id',
    name: 'PrepareLeirem',
    meta: {
      title: 'Chronos - Fulfllment Leirem - Surtir',
      auth: true,
    },
    component: PrepareLeirem
  },
  {
    path: '/basic_search',
    name: 'BasicSearch',
    meta: {
      title: 'Chronos - Busqueda',
      auth: true,
    },
    component: BasicSearch
  },
  {
    path: '/consumables',
    name: 'ConsumablesIndex',
    meta: {
      title: 'Chronos - Consumibles',
      auth: true,
    },
    component: ConsumablesIndex
  },
  {
    path: '/consumables/create',
    name: 'ConsumablesCreate',
    meta: {
      title: 'Chronos - Consumibles - Ingresar Stock',
      auth: true,
    },
    component: ConsumablesCreate
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Comfirm leave from advanced fulfillment view
router.beforeEach((to, from, next) => {
  if(from.name == 'AFPrepare' && store.state.trace_id != null && store.state.trace_id_loaded != false){
    const response = window.confirm('Do you really want to leave? you have unsaved changes!');
    if (response == true ) {
      const lsToken = localStorage.getItem("access_token");
      if (lsToken !== null) {
        axios.get(store.state.chronosApi + "v1/advanced_fulfillment/release_orders/" + store.state.trace_id, {
          headers: { Authorization: lsToken },
        })
        .then((response) => {
          response;
          store.state.trace_id = null;
        });
      }
      
      next();
    }
    next(false);
  }
  else next();
  // next();
})

export default router
