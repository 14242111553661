<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('create_consumable.enter_stock') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="4" class="py-0">
                                <v-autocomplete class="py-0" v-model="entry.id" item-text="name" item-value="id" :items="boxes" :label="$t('create_consumable.select_box')"></v-autocomplete> 
                            </v-col>
                            <v-col cols="2" justify="end" align="end">
                                <v-btn class="right white--text" color="amarillo" @click="add">
                                    {{ $t('create_consumable.add') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>

                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" >
                            <datatable :consumables_quantity_editable="true" :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('create_consumable.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save">
                                {{ $t('create_consumable.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
        headers(){
            return [
                { text: this.$t('create_consumable.box'), align: "center", value: 'name', class: 'text-uppercase' },
                { text: this.$t('create_consumable.quantity'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: "", align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        }
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            entry: {
                id: ""
            },
            props: {
                items: [],
                search:  ""
            },
            boxes: [],
            actions: [
                {
                    name: this.$t('create_consumable.delete'),
                    icon: {
                        color: 'error',
                        icon: 'cancel'
                    },
                    method: 'delete',
                    // permission: "box.entry"
                }
            ]
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.create_consumable";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            // Get Shippment_services
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'boxes/', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x => {
                    this.boxes.push({ id: x.attributes.id, name: x.attributes.name })
                });
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            let result = [];
            this.props.items.forEach( x => {
                result.push({
                    "consumable": {
                        "type": "Box",
                        "consumable_id": x.id
                    },
                    "entry": {
                        "quantity": x.quantity,
                        "batch": null,
                        "invoice": null,
                        "expiry_date": null
                    }
                });
            });

            this.$http.post(this.$store.state.chronosApi + 'consumables/stock_entry', { entries: result }, {headers: {Authorization: lsToken}})
            .then((response) => {
                console.log(response)
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('create_consumable.responses.success')
                }
                this.$router.push({ name: "BoxesIndex" });  
            }).catch((err) => {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: err.response.data.message
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
        add(){
            this.props.items.push({
                id: this.entry.id,
                name: this.boxes.find(x => x.id == this.entry.id).name,
                quantity: 0,
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: this.entry.id
            });
        },
        delete(item){
            let position = this.props.items.findIndex(x => x.consumable.consumable_id == item);
            this.props.items.splice(position, 1);
        }
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>