<template>
    <div class="px-0">
        <v-container class="px-0 py-1 my-0" style="max-width: 100% !important">
            <v-row class="px-0 py-0 my-1 mx-3" style="max-height: 43px !important; min-height: 43px !important;" align="center">
                <v-col cols="3" class="py-0 my-0 px-0" >
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        single-line
                        hide-details
                        class="py-0 px-2 my-0"
                        color="amarillo"
                        style="background-color: transparent; border: 1px solid #9fb700; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="right white--text tools-view-height mx-1" outlined color="amarillo" @click="exportModal = true" style="max-width: 40px !important; min-width: 40px !important" >
                            <v-icon class="material-icons-outlined" small>download</v-icon>
                        </v-btn>
                    </template>

                    <span>{{$t('customer_fulfillment.export_orders')}}</span>
                </v-tooltip>

                <v-btn class="right white--text tools-view-height mr-2" outlined color="amarillo" @click="modalImport = true;" v-permission:any="'advanced_customer_fulfillment.show|customer_all_access.show'">
                    {{ $t('customer_fulfillment.import_shipments') }}
                    <v-icon class="px-1" small>cloud_upload</v-icon>
                </v-btn>
                <v-btn class="right white--text tools-view-height" color="amarillo" :to="'customer_fulfillment_create_order'" v-permission:any="'advanced_customer_fulfillment.show|customer_all_access.show'">
                    {{ $t('orders.create') }}
                </v-btn>
            </v-row>
            <v-card class="px-0 top_bar mb-3">
                <v-card-text class="py-0 my-0">
                    <v-row class="px-0 py-0 my-0" style="max-height: 43px !important; min-height: 43px !important;" align="center">
                        <v-col cols="8" class="py-0 my-0 px-0">
                            <v-tabs v-model="tab"  active-class="customers_active_tab" style="max-height: 43px !important;">
                                <v-tabs-slider class="slider" color="amarillo" style="margin: 0px !important;"></v-tabs-slider>
                                <v-tab v-for="item in tab_items" :key="item" class="non_selected_tab slider" style="max-height: 43px !important;">
                                    {{ item }}
                                </v-tab>
                            </v-tabs>
                        </v-col>
                        
                        <v-spacer></v-spacer>

                        <v-col cols="2" class="py-0 my-0 px-0" v-if="fulfillment == 3">
                            <v-tabs v-model="types"  active-class="customers_active_tab" style="max-height: 43px !important;">
                                <v-tabs-slider class="slider" color="amarillo" style="max-height: 43px !important; margin: 0px !important;"></v-tabs-slider>
                                <v-tab v-for="item in types_items" :key="item" class="non_selected_tab slider" style="max-height: 43px !important;">
                                    {{ item }}
                                </v-tab>
                            </v-tabs>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-tabs-items v-model="tab" class="px-0 mx-3 ">
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" flat>
                        <v-card-text class="px-0 mx-1">
                            <v-row class="px-0 mx-0">
                                <v-col class="py-0 px-0 mx-0">
                                    <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" flat>
                        <v-card-text class="px-0 mx-1">
                            <v-row>
                                <v-col class="py-0">
                                    <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" flat>
                        <v-card-text class="px-0 mx-1">
                            <v-row>
                                <v-col class="py-0">
                                    <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" flat>
                        <v-card-text class="px-0 mx-1">
                            <v-row>
                                <v-col class="py-0">
                                    <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" flat>
                        <v-card-text class="px-0 mx-1">
                            <v-row>
                                <v-col class="py-0">
                                    <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-container>    
        <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
            <v-card >
                <v-card-title class="justify-center">
                    <v-row align="center" justify="center">
                        <h4 class="text-uppercase azul--text py-2">
                            {{ $t('customer_fulfillment.order_details.header') }} {{ this.orderToShow.uniqueOrderNumber }}
                        </h4>
                    </v-row>
                </v-card-title>

                <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('customer_fulfillment.details') }}</label></div>
                <div class="detail_content1"><label style="font-weight:bold">{{ $t("customer_fulfillment.order_number") }}</label> <label>{{ this.orderToShow.uniqueOrderNumber }}</label></div>
                <div class="detail_content1"><label style="font-weight:bold">{{ $t("customer_fulfillment.date") }}</label> <label>{{ this.orderToShow.createdAt }}</label></div>
                <div class="detail_content1"><label style="font-weight:bold">{{ $t("customer_fulfillment.shipping_service") }}</label> <label>{{ this.orderToShow.shippingService }}</label></div>
                <div class="detail_content1" v-if="orderToShow.client != null"><label style="font-weight:bold">{{ $t("customer_fulfillment.client") }}</label> <label>{{ this.orderToShow.client.name }}</label></div>
                <div class="detail_content1"><label style="font-weight:bold">{{ $t("customer_fulfillment.cod") }}</label> <label>{{ this.orderToShow.total }}</label></div>
                <div class="detail_content1" v-if="orderToShow.charge != null"><label style="font-weight:bold">{{ $t("customer_fulfillment.last_status") }}</label> <label>{{ this.orderToShow.charge.lastStatus }}</label></div>
                <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("customer_fulfillment.buyer") }}</label> <label>{{ this.orderToShow.consignee.name }}</label></div>
                <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("customer_fulfillment.state") }}</label> <label>{{ this.orderToShow.consignee.state }}</label></div>
                <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("customer_fulfillment.municipality") }}</label> <label>{{ this.orderToShow.consignee.municipality }}</label></div>
                <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("customer_fulfillment.neighborhood") }}</label> <label>{{ this.orderToShow.consignee.neighborhood }}</label></div>
                <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("customer_fulfillment.street") }}</label> <label>{{ this.orderToShow.consignee.street }}</label></div>
                <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("customer_fulfillment.zip_code") }}</label> <label>{{ this.orderToShow.consignee.zipcode }}</label></div>

                <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('customer_fulfillment.products') }}</label></div>
                <span v-if="orderToShow.shipmentProductDetail != null">
                    <div v-for="(product, i) of orderToShow.shipmentProductDetail.data" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.attributes.product.sku }}</label> <label>{{ product.attributes.quantity }}</label></div>
                </span>

                <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('customer_fulfillment.tracking_history') }}</label></div>
                <div v-for="status of orderToShow.orderStatus" :key="status.id" class="detail_content1"><label style="font-weight:bold">{{ status.attributes.status }}</label> <label>{{ status.attributes.createdAt }}</label></div>

                <v-card-text class="mt-5">
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn color="amarillo" class="white--text" @click="modalDetail = false">
                            {{ $t('customer_fulfillment.go_back') }}
                        </v-btn>
                    </div>
                </v-card-text>
                <div class="marker1"></div>
            </v-card>
        </v-dialog> 
        <v-dialog v-model="modalImport" persistent max-width="90%">
            <v-card>
                <v-card-title class="justify-center">
                    <v-spacer></v-spacer>
                    {{ $t('customer_fulfillment.import_shipments') }}
                    <v-spacer></v-spacer>
                    <v-btn color="amarillo" small icon @click="stepper2 = 1;modalImport = false"><v-icon small>close</v-icon></v-btn>
                </v-card-title>

                <v-card-text>
                    <v-stepper v-model="stepper2">
                        <v-stepper-header>
                            <v-stepper-step :complete="stepper2 > 1" step="1" color="amarillo">{{$t('customer_fulfillment.select_file')}}</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="stepper2 > 2" step="2" color="amarillo">{{ $t('customer_fulfillment.verify_data') }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content step="1" class="mx-0 px-0">
                                <v-card flat class="mb-5">
                                    <v-form ref="form2" lazy-validation>
                                        <v-row class="mx-0 px-0" justify="center" align="center">
                                            <v-col cols="10">
                                                <v-file-input v-model="inputFile2" accept=".csv" color="amarillo" :label="$t('customer_fulfillment.select_file')"></v-file-input>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="mx-0" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="stepper2 = 3">
                                                            <v-icon aria-hidden="false" color="white" small>
                                                                help
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('customer_fulfillment.help') }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card>
                                
                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="right mr-2 white--text" color="amarillo" @click="uploadOrders" >
                                        {{ $t('customer_fulfillment.next') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card flat class="mb-5">
                                    <v-card-text class="my-0 py-0">
                                        <p class="my-0 py-0">{{readRows2.length}} {{ $t('customer_fulfillment.orders_in_file') }}</p>
                                    </v-card-text>
                                    <v-simple-table v-if="this.readRows2.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th v-for="(th, key) in sTHeaders2" :key="key" class="text-left">{{ th }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in readRows2" :key="i">
                                                    <td v-for="(td, j) in item.split(',')" :key="j">{{ td }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="mx-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = []">{{ $t('customer_fulfillment.go_back') }}</v-btn>
                                    <v-btn color="amarillo" class="white--text" @click="modal2 = false; saveOrders()">
                                        {{ $t('customer_fulfillment.save_data') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="3">
                                <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                                <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                                <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                                <p class="py-0 my-0">4.- El archivo de citas debe ser formato CSV.</p> 
                                <p class="py-0 my-0">5.- Paqueterias permitidas: 99MINUTOS, DHL, FEDEX, HERMES, OTRA, QUIKEN, YEGO.</p> 
                                <p class="py-0 my-0">6.- Limite permitido para Nombre_cliente es de 35 caracteres.</p> 
                                <p class="py-0 my-0">7.- Limite permitido para Colonia es de 35 caracteres.</p> 
                                <p class="py-0 my-0">8.- Limite permitido para Calle es de 25 caracteres.</p> 
                                <p class="py-0 my-0">9.- Limite permitido para No_Ext es de 5 caracteres.</p> 
                                <p class="py-0 my-0">10.- Limite permitido para No_Int es de 5 caracteres.</p> 
                                <p class="py-0 my-0">11.- Limite permitido para Telefono es de 10 caracteres.</p>
                                <p class="py-0 my-0">12.- Para multiples productos los Sku deben ser separados por un "|".</p>
                                <p class="py-0 my-0">13.- Para multiples productos las cantidades deben ser separadas por un "|".</p>
                                <p class="py-0 my-0">14.- Para multiples productos los precio_unitario deben ser separadas por un "|".</p>
                                <p class="py-0 my-0">15.- Cuando la paquetería lleve el valor OTRA agrega en la columna pickup del final el nombre del servicio externo que entregará la orden, como FedEx, DHL, UPS, Tres Guerras o cualquier otra, con un máximo de 40 caracteres, caso contrario deja la columna vacía.</p>
                                
                                <p class="py-0 my-0">Columnas: </p>

                                <v-simple-table fixed-header>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left" style="padding: 0 5px !important;">Cod</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Número_de_orden</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Paquetería</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Nombre_cliente</th>
                                                <th class="text-left" style="padding: 0 5px !important;">País</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Estado</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Municipio</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Colonia</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Calle</th>
                                                <th class="text-left" style="padding: 0 5px !important;">No_Ext</th>
                                                <th class="text-left" style="padding: 0 5px !important;">No_Int</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Código_Postal</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Correo</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Teléfono</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Comentarios</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Sku</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Cantidad</th>
                                                <th class="text-left" style="padding: 0 5px !important;">precio_unitario</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Precio_Total</th>
                                                <th class="text-left" style="padding: 0 5px !important;">Pickup</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>false/true</td>
                                                <td>N21697</td>
                                                <td>FEDEX</td>
                                                <td>Felipe Becerra González</td>
                                                <td>México</td>
                                                <td>Guanajuato</td>
                                                <td>San Francisco del Rincón</td>
                                                <td>Centro</td>
                                                <td>Alvaro Obregón</td>
                                                <td>308</td>
                                                <td></td>
                                                <td>36300</td>
                                                <td>felipbeg@gmail.com</td>
                                                <td>4767476419</td>
                                                <td>entre calle "x" y calle "y"</td>
                                                <td>sku1|sku2</td>
                                                <td>cantidad1|cantidad2</td>
                                                <td>precioSku1|precioSku2</td>
                                                <td>1199.99</td>
                                                <td>Tres Guerras</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>

                                <v-row align="center" justify="center" class="my-3">
                                    <v-btn color="amarillo" class="white--text" @click="stepper2 = 1">
                                        {{ $t('products.go_back') }}
                                    </v-btn>
                                </v-row>
                            </v-stepper-content>

                            <v-stepper-content step="4">
                                <v-card flat class="mb-5">
                                    <v-simple-table v-if="this.responses.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" class="text-left">{{ $t('customer_fulfillment.order') }}</th>
                                                    <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" class="text-left">{{ $t('customer_fulfillment.message') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in responses" :key="i">
                                                    <td>{{ item.order }}</td>
                                                    <td>{{ item.message }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-btn class="px-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = []; modalImport = false; responses = []; index()">{{ $t('customer_fulfillment.close') }}</v-btn>
                                </div>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
            </v-card>
        </v-dialog>       
        <v-dialog v-model="modalDelete" persistent max-width="90%">
            <v-card >
                <v-card-title class="justify-center">
                    <v-row align="center" justify="center">
                            <h3 class="text-uppercase">
                                {{ $t('fulfillments_prepaid_pendings.modal.delete') }}
                            </h3>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row align="center" justify="center" class="py-3 my-3 text-center">
                        <v-col cols="12">
                            <h5>{{ $t('fulfillments_prepaid_pendings.modal.message') }}</h5>
                        </v-col>
                    </v-row>
                    
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('fulfillments_prepaid_pendings.no') }}</v-btn>
                        <v-btn color="error" @click="deleteOrder">
                            {{ $t('fulfillments_prepaid_pendings.yes') }}
                        </v-btn>
                    </div>
                </v-card-text> 
                <div class="marker"></div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="exportModal" persistent max-width="90%">    
            <v-card >
                <v-card-title class="justify-center">
                    <v-row align="center" justify="center">
                        <h3 class="text-uppercase">
                            {{ $t('customer_fulfillment.export_orders') }}
                        </h3>
                    </v-row>
                </v-card-title>
                    <v-card-text>
                        <br>
                        <v-row align="center" justify="center">
                            <v-col cols="10" class="py-0">
                                <v-menu ref="initial_date" v-model="menu" :close-on-content-click="false" :return-value.sync="exportData.initial_date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="py-0" v-model="exportData.initial_date" :label="$t('customer_fulfillment.initial_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="exportData.initial_date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">{{ $t('customer_fulfillment.cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.initial_date.save(exportData.initial_date)">{{ $t('customer_fulfillment.save') }}</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="10" class="py-0">
                                <v-menu ref="final_date" v-model="menu2" :close-on-content-click="false" :return-value.sync="exportData.final_date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="py-0" v-model="exportData.final_date" :label="$t('customer_fulfillment.final_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="exportData.final_date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu2 = false">{{ $t('customer_fulfillment.cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.final_date.save(exportData.final_date)">{{ $t('customer_fulfillment.save') }}</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    
                        <br>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="exportModal = false">{{ $t('customer_fulfillment.back') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="exportOrders()">
                                {{ $t('customer_fulfillment.export') }}
                            </v-btn>
                        </div>
                        <br>
                    </v-card-text> 
                <div class="marker1"></div>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('orders.order'), align: "center", value: 'uniqueOrderNumber', class: 'text-uppercase', width: "20%" },
                { text: this.$t('orders.tracking_guide'), align: "center", value: 'shipmentNumber', class: 'text-uppercase', width: "20%"},
                { text: this.$t('orders.created_at'), align: "center", value: 'createdAt', class: 'text-uppercase', width: "10%" },
                { text: this.$t('orders.parcel'), align: "center", value: 'shippingService', class: 'text-uppercase', width: "10%" },
                { text: this.$t('orders.destination'), align: "center", value: 'consignee.state', class: 'text-uppercase', width: "15%" },
                { text: this.$t('orders.status'), align: "center", value: 'coloredStatus', class: 'text-uppercase', width: "15%" },
                { text: this.$t('orders.actions'), align: "center", value: 'actions', class: 'text-uppercase', width: "10%" },
            ];
        } 
    },
    data(){
        return {
            clientId: this.$store.state.clientUserId,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            tab: null,
            tab_items: [this.$t("orders.pendings"), this.$t("orders.in_transit"), this.$t("orders.delivered"), this.$t("orders.returned"), this.$t("orders.all")],
            // tab_items: [this.$t("orders.all"), this.$t("orders.in_process"), this.$t("orders.in_transit"), this.$t("orders.delivered"), this.$t("orders.returned"), this.$t("orders.exceptions")],
            modalDetail: false,
            modalDelete: false,
            modalImport: false, 
            exportModal: false,
            exportData: {
                initial_date: "",
                final_date: "",
                client_id: this.$store.state.clientUserId
            },
            menu: false,
            menu2: false,
            orderToShow: '',
            orderToDelete: '',
            inputFile2: [],
            readFile2: '',
            readRows2: [],
            responses: [],
            stepper2: 1,
            sTHeaders2:'',
            bearer: "",
            types: 0,
            fulfillment: 3,
            types_items: [this.$t('orders.regular'), this.$t('orders.cod')],
            actions: [
            {
                    name: this.$t('orders.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                    permission: "customer.show"
                },
                {
                    name: this.$t('orders.order_summary'),
                    icon: {
                        color: 'accent',
                        icon: 'content_paste_search'
                    },
                    method: 'downloadOrderSummary',
                    permission: "customer.show"
                },
            ],
            actions_otra: [
            {
                    name: this.$t('orders.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                    permission: "customer.show"
                },
                {
                    name: this.$t('orders.upload_label'),
                    icon: {
                        color: 'accent',
                        icon: 'file_present'
                    },
                    method: 'updateLabel',
                    permission: "advanced_customer_fulfillment.show|customer_all_access.show"
                },
                {
                    name: this.$t('orders.order_summary'),
                    icon: {
                        color: 'accent',
                        icon: 'content_paste_search'
                    },
                    method: 'downloadOrderSummary',
                    permission: "customer.show"
                },
            ],
            actions_advanced: [
                {
                    name: this.$t('orders.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                    permission: "customer.show"
                },
                {
                    name: this.$t('orders.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'edit'
                    },
                    method: 'EditPenging',
                    permission: "advanced_customer_fulfillment.show|customer_all_access.show"
                },
                {
                    name: this.$t('orders.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                    permission: "advanced_customer_fulfillment.show|customer_all_access.show"
                },
                // {
                //     name: this.$t('orders.order_summary'),
                //     icon: {
                //         color: 'accent',
                //         icon: 'content_paste_search'
                //     },
                //     method: 'downloadOrderSummary',
                //     permission: "customer.show"
                // },
            ],
            actions_advanced_otra: [
                {
                    name: this.$t('orders.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                    permission: "customer.show"
                },
                {
                    name: this.$t('orders.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'edit'
                    },
                    method: 'EditPenging',
                    permission: "advanced_customer_fulfillment.show|customer_all_access.show"
                },
                {
                    name: this.$t('orders.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                    permission: "advanced_customer_fulfillment.show|customer_all_access.show"
                },
                {
                    name: this.$t('orders.upload_label'),
                    icon: {
                        color: 'accent',
                        icon: 'file_present'
                    },
                    method: 'updateLabel',
                    permission: "advanced_customer_fulfillment.show|customer_all_access.show"
                },
                // {
                //     name: this.$t('orders.order_summary'),
                //     icon: {
                //         color: 'accent',
                //         icon: 'content_paste_search'
                //     },
                //     method: 'downloadOrderSummary',
                //     permission: "customer.show"
                // },
            ],
            props: {
                search: '',
                items: [],
            }
        }
    },
    mounted(){
        this.index();
        this.getClientToken();
        this.$store.state.module = "headers.my_orders";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.tab = 0;
            this.getOrders("pendings");
        },
        getOrders(status){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.props.items = [];
            
            const show_fulfillment = this.$store.state.loggedUserPermissions.includes("advanced_customer_fulfillment.show") || this.$store.state.loggedUserPermissions.includes("customer_all_access.show")
            
            this.$http.get(this.$store.state.chronosApi + 'shipment/getOrdersByClient?warehouse=' + this.$store.state.warehouseId + '&status=' + status + '&cod=' + this.types + '&shipstationOrders=false&client_id=' + this.clientId, {headers: {Authorization: lsToken}})
            .then((response) => {
                // Get Shippment_services
                this.$store.state.loaderProps.visible = true;
                this.props.items = [];

                response.data.data.forEach( x => {
                    x = x.attributes;
                    x.coloredStatus = x.charge.lastStatus;

                    if(x.charge.lastStatus.toLowerCase() == "placed" || x.charge.lastStatus.toLowerCase() == "ready"){
                        x.coloredStatus = this.$t("orders.pending");
                        x.color = 'blue';
                    }
                    else if(x.charge.lastStatus.toLowerCase() != "placed" && x.charge.lastStatus.toLowerCase() != "ready" && x.charge.lastStatus.toLowerCase() != "returned to chronos" && x.charge.lastStatus.toLowerCase() != "delivered"){
                        x.coloredStatus = this.$t("orders.in_transit");
                        x.color = "green";
                    }
                    else if(x.charge.lastStatus.toLowerCase() == "delivered"){
                        x.coloredStatus = this.$t("orders.order_delivered");
                        x.color = "purple";
                    }
                    else if(x.charge.lastStatus.toLowerCase() == "returned to chronos"){
                        x.coloredStatus = this.$t("orders.returned");
                        x.color = "orange";
                    }
                    
                    x.createdAt = x.createdAt.substring(0,10);
                    
                    if(show_fulfillment && x.charge.lastStatus.toLowerCase() == "placed" && x.shippingService == "OTRA"){
                        x['actions'] = JSON.parse(JSON.stringify(this.actions_advanced_otra));
                        x['actions'][1].link = "/customer_fulfillment_edit_order/" + x.id;
                        x['actions'][3].link = "/customer_fulfillment_upload_label/" + x.id;
                    }
                    else if(show_fulfillment && x.charge.lastStatus.toLowerCase() == "placed" && x.shippingService != "OTRA") {
                        x['actions'] = JSON.parse(JSON.stringify(this.actions_advanced));
                        x['actions'][1].link = "/customer_fulfillment_edit_order/" + x.id;
                    }
                    else if(show_fulfillment && x.charge.lastStatus.toLowerCase != "placed" && x.shippingService == "OTRA"){
                        x['actions'] = JSON.parse(JSON.stringify(this.actions_otra));
                        x['actions'][1].link = "/customer_fulfillment_upload_label/" + x.id;
                    }
                    else x['actions'] = JSON.parse(JSON.stringify(this.actions));

                    x.parameters = x;
                    this.props.items.push(x);
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        showDetails(order){
            this.orderToShow = order;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/getOrderInformation/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data.attributes;
                this.$store.state.loaderProps.visible = false;
                this.orderToShow = response.data;
                this.modalDetail = true;
            }).catch((err) => {
                err;
                this.$http.get(this.$store.state.chronosApi + 'shipment/shipstation?id=' + order.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    this.orderToShow.uniqueOrderNumber = response.data.data.orderId;
                    this.orderToShow.createdAt = response.data.data.createDate;
                    this.orderToShow.shippingService = "FEDEX";
                    this.orderToShow.client.name = "PARTICLE FF"
                    this.orderToShow.total = response.data.data.orderTotal;
                    this.orderToShow.consignee = {
                        name: response.data.data.shipTo.name,
                        state: response.data.data.shipTo.state,
                        municipality: response.data.data.shipTo.city,
                        neighborhood: response.data.data.shipTo.street1,
                        street: response.data.data.shipTo.street2,
                        zipcode: response.data.data.shipTo.postalCode
                    };
                    this.orderToShow.shipmentProductDetail = {data:[]};
                    this.orderToShow.charge = {
                        lastStatus: ""
                    };

                    response.data.data.items.forEach( x => {
                        if(x.sku != "total-discount"){
                            this.orderToShow.shipmentProductDetail.data.push({
                                attributes: {
                                    product: {
                                        sku: x.sku
                                    },
                                    quantity: x.quantity
                                }
                            })
                        }
                    })
                    this.orderToShow.orderStatus = null;
                    this.modalDetail = true;
                })
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        uploadOrders(){
            if(this.$refs.form2.validate()){
                this.createInput2(this.inputFile2);
            }
        },
        createInput2(file) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows2 = result.split('\r\n');
                    this.sTHeaders2 = this.readRows2[0].split(',');
                    this.readRows2.shift();
                    this.stepper2 = 2;
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        saveOrders(){
            this.$store.state.loaderProps.visible = true
            const orders = [];
            if(this.readRows2.length > 0){
                var w_id = localStorage.getItem('warehouse_id') || 1;
                this.readRows2.forEach((x) => {
                    if(x != ""){
                        const num = x.split(',');
                        let yourDate = new Date();
                        yourDate = yourDate.toISOString().split('T')[0];

                        var order_details = [];
                        var is_valid = true;
                        var message = "";

                        if(
                            (num[15].split('|').length == num[16].split('|').length && num[15].split('|').length == num[17].split('|').length) 
                            || (num[15].split('|').length == num[16].split('|').length 
                                && (num[17] == "" || num[17] == null || num[17] == undefined))){
                            
                            // check the number of products, quantity and unit price 
                            // sku1|sku2 -> //quantity1|quantity2 -> unit_price1|unit_price2
                            for( var i = 0; i < num[15].split('|').length; i++ ){
                                if(num[15].split('|')[i] == "" || num[15].split('|')[i] == null || num[15].split('|')[i] == undefined || 
                                num[16].split('|')[i] == "" || num[16].split('|')[i] == null || num[16].split('|')[i] == undefined || 
                                num[17].split('|')[i] == "" || num[17].split('|')[i] == null || num[17].split('|')[i] == undefined){
                                    is_valid = false;
                                }

                                if(is_valid == true){
                                    order_details.push({
                                        sku: num[15].split('|')[i],
                                        quantity: parseInt(num[16].split('|')[i]),
                                        unit_price: num[17].split('|')[i]
                                    });
                                }
                                // Check if unit price is empty an fill with 1s
                                else {
                                    is_valid = true;
                                    order_details.push({
                                        sku: num[15].split('|')[i],
                                        quantity: parseInt(num[16].split('|')[i]),
                                        unit_price: 1
                                    });
                                }
                            }
                            
                            if(is_valid){
                                orders.push({
                                    cod: (num[0].toString().toLowerCase() == "false" ? false : true),
                                    unique_order_number: num[1],
                                    shipping_service: num[2],
                                    invoice: "---",
                                    date: yourDate,
                                    warehouse_id: parseInt(w_id),
                                    consignee: {
                                        avoid_validations: true,
                                        name: num[3],
                                        country: num[4],
                                        state: num[5],
                                        neighborhood: num[7], 
                                        external_number: num[9],
                                        street: num[8],
                                        internal_number: num[10],
                                        zipcode: num[11],
                                        email: num[12],
                                        phone: num[13],
                                        municipality: num[6],
                                        comments: num[14].length < 3 ? "---" : num[14],
                                    },
                                    order_details: order_details,
                                    total_price: num[18],
                                    external_shipping_service: num[19] ?? ""
                                });
                            }
                            else{
                                this.responses.push({
                                    order: num[1],
                                    message: message
                                });

                                this.$store.state.loaderProps.visible = false;
                            }
                        }
                        else {
                            this.responses.push({
                                order: num[1],
                                message: this.$t("orders.wrong_details")
                            });
                        }
                        
                    }
                })

                if(this.bearer != ""){
                    orders.forEach( x =>{
                        if(x.shipping_service == "OTRA"){ 
                            this.createOtraShipment(x);
                        }
                        else this.createNormalShipment(x);
                    })

                    this.stepper2 = 4;
                    this.$store.state.loaderProps.visible = false;

                }
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('upload_annex.validations.select_file_first')
                };
            }
        },
        createNormalShipment(x){
            this.$http.post(this.$store.state.chronosApi+'shipment/create', x, { headers: { Authorization: this.bearer } })
            .then((response) => {
                if(response.data.code === 200){
                    var errors = "";
                    if(Object.prototype.hasOwnProperty.call(response.data, "code")){
                        if(response.data.message != "The order has been placed, stock availability will be checked and if confirmed, order status will be updated to READY"){
                            Object.keys(response.data).forEach( x =>{
                                response.data[x].forEach(y => {
                                    errors += x + ": " + y;
                                })
                            })

                            this.responses.push({
                                order: x.unique_order_number,
                                message: errors
                            });
                        }
                        else{
                            this.responses.push({
                                order: x.unique_order_number,
                                message: this.$t("orders.created_successfully")
                            });
                        }
                    }
                    else{
                        if(response.data.code != 200){
                            Object.keys(response.data).forEach( x =>{
                                response.data[x].forEach(y => {
                                    errors += x + ": " + y;
                                })
                            })
                            this.responses.push({
                                order: x.unique_order_number,
                                message: errors
                            });
                        }
                    }
                }
                this.$store.state.loaderProps.visible = false;
            })
            .catch((error) => {
                this.responses.push({
                    order: x.unique_order_number,
                    message: error.response.data.message
                });
                this.$store.state.loaderProps.visible = false;
            });
        },
        createOtraShipment(x){
            const formData = new FormData();
            formData.append('date', x.date);
            formData.append('cod', x.cod);
            formData.append('unique_order_number', x.unique_order_number);
            formData.append('shipping_service', x.external_shipping_service);
            // formData.append('shipment_number', x.shipment_number);

            // Optional files
            // if(x.file.length > 0) formData.append('label', x.file);
            // if(x.attached_files.length > 0)
            //     for(var counter = 0; counter < x.attached_files.length; counter++){
            //         formData.append('attached_files[]', x.attached_files[counter]);
            //     }

            formData.append('invoice', x.invoice);
            formData.append('warehouse_id', x.warehouse_id);
            formData.append('total_price', x.total_price);
            formData.append('order_details', JSON.stringify(x.order_details));

            formData.append('consignee_name', x.consignee.name);
            formData.append('consignee_country', x.consignee.country);
            formData.append('consignee_state', x.consignee.state);
            formData.append('consignee_municipality', x.consignee.municipality);
            formData.append('consignee_neighborhood', x.consignee.neighborhood);
            formData.append('consignee_street', x.consignee.street);
            formData.append('consignee_external_number', x.consignee.external_number);
            formData.append('consignee_internal_number', x.consignee.internal_number);
            formData.append('consignee_zipcode', x.consignee.zipcode);
            formData.append('consignee_email', x.consignee.email);
            formData.append('consignee_phone', x.consignee.phone);
            formData.append('consignee_comments', x.consignee.comments);

            // console.log([...formData]) // Print form data displaying all pr

            this.$http.post(this.$store.state.chronosApi+'shipment/create_with_label', formData, { headers: { Authorization: this.bearer, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.code === 200){
                    var errors = "";
                    if(Object.prototype.hasOwnProperty.call(response.data, "code")){
                        if(response.data.message != "The order has been placed, stock availability will be checked and if confirmed, order status will be updated to READY"){
                            Object.keys(response.data).forEach( x =>{
                                response.data[x].forEach(y => {
                                    errors += x + ": " + y;
                                })
                            })

                            this.responses.push({
                                order: x.unique_order_number,
                                message: errors
                            });
                        }
                        else{
                            this.responses.push({
                                order: x.unique_order_number,
                                message: this.$t("orders.created_successfully")
                            });
                        }
                    }
                    else{
                        if(response.data.code != 200){
                            Object.keys(response.data).forEach( x =>{
                                response.data[x].forEach(y => {
                                    errors += x + ": " + y;
                                })
                            })
                            this.responses.push({
                                order: x.unique_order_number,
                                message: errors
                            });
                        }
                    }
                }
            })
            .catch((error) => {
                this.responses.push({
                    order: x.unique_order_number,
                    message: error.response.data.message
                });
                this.$store.state.loaderProps.visible = false;
            });
            this.$store.state.loaderProps.visible = true;
        },
        getClientToken(){
            this.$http.get(this.$store.state.chronosApi + 'getClientToken/'+ this.clientId)
            .then(response => {
                this.client = response.data.client

                this.$http.get(this.$store.state.chronosApi + 'getCurrenAvailableToken/' + this.clientId)
                .then(response2 => {
                    if(response2.data.token != "---"){
                        this.bearer = "Bearer " + response2.data.token;
                        if(response2.data.client.fulfillment == "regular"){
                            this.fulfillment = 0;
                            this.types = 0;
                        } 
                        else if(response2.data.client.fulfillment == "cod"){
                            this.fulfillment = 1;
                            this.types = 1
                        }
                        else if(response2.data.client.fulfillment == "all") this.fulfillment = 3;
                    }
                    else if(response2.data.token == "---"){
                        if(this.client != null && this.client.token != ""){
                            this.$http.post(this.$store.state.chronosApi + 'token/generate', {email: "alejandro@intech.mx", password: "afuerzaniloscalcetines:" + this.client.token}, {headers:{}})
                            .then( response3 => {
                                this.bearer = "Bearer " + response3.data.token;
                            })
                        }
                    }
                })
            })
        },
        askDelete(order){
            this.modalDelete = true;
            this.orderToDelete = order;
        },
        deleteOrder(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'shipment/deleteOrder/' + this.orderToDelete.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.data.success == true){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('fulfillments_prepaid_pendings.deleted')
                    }
                    this.modalDelete = false;
                    this.index();
                }
                else{
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('fulfillments_prepaid_pendings.deleted_failed')
                    }
                    this.modalDelete = false;
                    this.index();
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        downloadOrderSummary: async function(order){
            this.$store.state.loaderProps.visible = true;

            const ordersSummary = await this.getOrdersSummary(order.id);

            this.$store.state.loaderProps.visible = false;
            FileSaver.saveAs(ordersSummary.data, "Resumen_Orden_" + order.uniqueOrderNumber + ".pdf")
            this.$store.state.loaderProps.visible = false;
        },
        getOrdersSummary: async function(ids){
            const lsToken = localStorage.getItem("access_token");
            const file = await this.$http.get(this.$store.state.chronosApi + 'shipment/ordersSummary?shipments=' + ids, {headers: {Authorization: lsToken}, responseType:'blob'});
            return file;
        },
        exportOrders(){
            if(this.exportData.initial_date != "" && this.exportData.final_date){
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + '/shipment/export_orders_of_client?client_id='+ this.exportData.client_id + '&initial_date=' + this.exportData.initial_date + '&final_date=' + this.exportData.final_date, {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'orders.xlsx');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('customer_fulfillment.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('customer_fulfillment.all_params_required')
                };
            }
        }
    },
    watch:{
        'tab': function(){
            var status = "all";
            if(this.tab == 4) status = 'all';
            else if(this.tab == 0) status = 'pendings';
            else if(this.tab == 1) status = 'sent';
            else if(this.tab == 2) status = 'delivered';
            else if(this.tab == 3) status = 'returned';
            else status = 'all';

            this.getOrders(status);
        },
        'types': function(){
            var status = "all";
            if(this.tab == 4) status = 'all';
            else if(this.tab == 0) status = 'pendings';
            else if(this.tab == 1) status = 'sent';
            else if(this.tab == 2) status = 'delivered';
            else if(this.tab == 3) status = 'returned';
            else status = 'all';

            this.getOrders(status);
        }
    }
}
</script>

<style>

</style>