<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">

            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('create_relocation.new') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0 my-0">
                                <label style="font-weight: bold;" >{{ $t('create_relocation.scan_pallet') }}</label>
                                <v-text-field class="py-0 mt-2" v-model="relocation.origin_text" :readonly="true" :label="$t('create_relocation.origin_location')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="type == 'pallet'">
                            <v-col cols="6" class="py-0 my-0">
                                <v-row class="mt-0 mb-1 px-3" v-if="relocation.products.length > 0">
                                    <label style="font-size:12px">{{ $t('create_relocation.product_client') }}</label>
                                    <v-spacer></v-spacer>
                                    <label style="font-size:12px" class="mr-3">{{ $t('create_relocation.quantity') }}</label>
                                </v-row>   
                                <v-row v-for="(detail) in relocation.products" :key="detail.detailed_stock_id" class="my-0 py-0 px-3" style="vertical-align: bottom;">
                                    <v-col cols="10" class="px-0 mx-0">
                                        <label style="" class="mt-1 py-0 mr-2">{{ detail.text }}</label>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-row class="px-0 mx-0">
                                            <v-text-field class="py-0 mt-0 pt-0" style="max-width: 55px !important; min-width: 55px !important" v-model="detail.quantity" :rules="rules_required"></v-text-field>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" class="right white--text tools-view-height mx-1 mt-1" color="error" @click="removeProduct(detail.detailed_stock_id)" style="max-width: 20px !important; min-width: 20px !important; min-height: 28px !important; max-height: 28px !important;" >
                                                        <v-icon class="material-icons-outlined" small>cancel</v-icon>
                                                    </v-btn>
                                                </template>

                                                <span>{{$t('create_relocation.cancel')}}</span>
                                            </v-tooltip>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="type == 'product'">
                            <v-col cols="6" class="py-0 my-0">
                                <label style="font-weight: bold;">{{ $t('create_relocation.scan_products_to_relocate') }}</label>
                                <br>
                                <v-row class="mt-0 mb-1 px-3" v-if="relocation.products.length > 0">
                                    <label style="font-size:12px">{{ $t('create_relocation.product_client') }}</label>
                                    <v-spacer></v-spacer>
                                    <label style="font-size:12px" class="mr-3">{{ $t('create_relocation.quantity') }}</label>
                                </v-row>   
                                <v-row v-for="(detail) in relocation.products" :key="detail.detailed_stock_id" class="my-0 py-0 px-3" style="vertical-align: bottom;">                                    
                                    <v-col cols="10" class="px-0 mx-0">
                                        <label style="" class="mt-1 py-0 mr-2">{{ detail.text }}</label>
                                    </v-col>

                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-row class="px-0 mx-0" justify="end">
                                            <label style="font-weight: bold; font-size: 17px;" class="mt-2 py-0 mr-2"> X{{ detail.quantity }}</label>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" class="right white--text tools-view-height mx-1 mt-1" color="error" @click="removeProduct(detail.detailed_stock_id)" style="max-width: 20px !important; min-width: 20px !important; min-height: 28px !important; max-height: 28px !important;" >
                                                        <v-icon class="material-icons-outlined" small>cancel</v-icon>
                                                    </v-btn>
                                                </template>

                                                <span>{{$t('create_relocation.cancel')}}</span>
                                            </v-tooltip>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0 my-0">
                                <label style="font-weight: bold;">{{ $t('create_relocation.scan_pallet_to_relocate') }}</label>
                                <v-text-field class="py-0 mt-2" v-model="relocation.destination_text" :readonly="true" :label="$t('create_relocation.destination_location')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="relocation.identifier_type == 'location_name'">
                            <v-col cols="6" class="py-0" v-if="relocation.destination_text != '' && relocation.destination_text != undefined && relocation.destination_text != null">
                                <label for="">{{ $t('create_relocation.destination_pallet') }}</label>
                                <v-radio-group v-model="relocation.pallet">
                                    <v-row class="px-0 py-0 my-0" justify="start" align="start">
                                        <v-col cols="12" class="py-0" style="display:flex">
                                            <v-radio class="mt-0" color="info" :label="$t('create_relocation.new_pallet')" :value="null"></v-radio>
                                        </v-col>                                    
                                    </v-row>
                                    <v-row class="px-0 py-0 my-0" justify="start" align="start">
                                        <v-col cols="12" class="py-0" style="display:flex" v-for="(pallet, i) of destination_pallets" :key="i">
                                            <v-radio class="mt-0" color="info" :label="pallet.pallet.toString() + ' - (' + $t('create_relocation.contains') +': ' + (pallet.products.includes(',') ? $t('create_relocation.various') : pallet.products) + ')'" :value="pallet.pallet"></v-radio>
                                        </v-col>                                    
                                    </v-row>
                                </v-radio-group>
                            </v-col>
                        </v-row> 

                    </v-form>
                    
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('create_relocation.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save()" v-permission="'relocation.create'">
                                {{ $t('create_relocation.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card> 
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            }, 
            relocation: {
                identifier_type: "",
                origin_tag: "",
                origin_text: "",
                destination_tag: "",
                destination_text: "",
                products: [],
                client_id: "",
                pallet: "",
            },
            scaned_epc_by_product: [],
            destination_pallets: [],
            scaned_product_epc: [],
            barcode: "",
            all_pallet: false,
            type: 'product',
            all_success: false,
        }
    },
    mounted(){
        let self = this; 

        const keyPressHandler = (e) => {
            if(self.last == true && /^\S+$/.test(e.key)) { 
                self.barcode = e.key;
                self.last = false;
            }
            else if(e.code == "Enter") {
                self.last = true;
                self.updateList();
            }
            else if(/^\S+$/.test(e.key)) self.barcode += e.key;
        };

        window.addEventListener ('keypress', keyPressHandler, { capture: true });
        this.index();
        this.$store.state.module = "headers.create_relocation";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){},
        async save(){
            if(this.all_success == true){
                this.$store.state.loaderProps.visible = true;

                const data = {
                    "destination_pallet": {
                        "identifier_type": this.relocation.identifier_type,
                        "identifier": this.relocation.destination_tag
                    },
                    "relocations": []
                }

                if(this.type == "product") data["relocations"] = this.scaned_epc_by_product; 
                else if(this.type == "pallet"){
                    this.relocation.products.forEach(x => {
                        data["relocations"].push({
                            "detailed_stock_id": x.detailed_stock_id,
                            "quantity": x.quantity,
                            "epcs": []
                        });
                    })                    
                }

                const lsToken = localStorage.getItem("access_token");
                try{
                    const result = await this.$http.post(this.$store.state.chronosApi + 'relocations/epc', data, {headers: {Authorization: lsToken}});
                    console.log(result, result.data);
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: result.data.message
                    }
                    
                    this.$router.go(-1);
                    this.$store.state.loaderProps.visible = false;
                }
                catch(e){
                    this.$store.state.loaderProps.visible = false;

                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: e.response.data.fault.message
                    }
                }
            }
        },
        cancel(){
            this.$router.go(-1);
        },
        async processData(data){
            this.relocation.products = [];
            this.relocation.origin_text = data.data.data.location.text;
            this.relocation.client_id = data.data.data.client.id;
            if(data.data.data.client.rfid_by_product == true) this.type = "product";
            else this.type = "pallet";

            if(this.type == "pallet") this.relocation.products = data.data.data.products;
            else if(this.type == "product"){
                data.data.data.products.forEach(x => {
                    x.quantity = 0;
                    this.relocation.products.push(x);
                })
            }
        },
        removeProduct(product){
            const result = this.relocation.products.findIndex( x => x.detailed_stock_id == product );
            this.relocation.products.splice(result, 1);
        },
        async updateList(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            if ((this.barcode.startsWith('P') || this.barcode.startsWith('p')) && /^\d+$/.test(this.barcode.substring(1))) {
                this.barcode = this.barcode.substring(1);  
                this.barcode = 'C' + this.barcode.padStart(23, '0'); 
            }
            else if ((this.barcode.startsWith('T') || this.barcode.startsWith('t')) && /^\d+$/.test(this.barcode.substring(1))) {
                this.barcode = this.barcode.substring(1);  
                this.barcode = 'A' + this.barcode.padStart(23, '0'); 
            }

            if(this.barcode.startsWith('C') && this.type == "product"){
                await this.checkProduct(this.barcode);
            }
            else if(this.relocation.origin_tag == "" && this.barcode.startsWith('A')) {
                this.relocation.origin_tag = this.barcode;
                try{
                    const data = await this.$http.get(this.$store.state.chronosApi + 'relocations/location/' + this.barcode, {headers: {Authorization: lsToken}});
                    await this.processData(data);
                }
                catch(e){
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: e.response.data.fault.message
                    }
                }
            }
            else if(this.relocation.destination_tag == "") {
                this.relocation.destination_tag = this.barcode;
                try{
                    this.relocation.identifier_type = "";
                    if(this.barcode.length == 24 && (this.barcode.startsWith('A') || this.barcode.startsWith("C"))) this.relocation.identifier_type = "epc";
                    else this.relocation.identifier_type = "location_name"

                    const data = await this.$http.get(this.$store.state.chronosApi + 'relocations/validate-location?client_id=' + this.relocation.client_id + '&identifier=' + this.barcode + '&identifier_type=' + this.relocation.identifier_type, {headers: {Authorization: lsToken}});

                    if(data.status == 200) {
                        this.all_success = true; 
                        this.relocation.destination_text = data.data.data.location.text;

                        this.$http.get(this.$store.state.chronosApi + 'relocations/get_pallets_of_destination?location_id=' + data.data.data.location.id, {headers: {Authorization: lsToken}})
                        .then((response) => {
                            this.destination_pallets = response.data.data;
                        }).catch((err) => {
                            console.log(err);
                        }).finally(() => {
                            this.$store.state.loaderProps.visible = false;
                        })
                    }
                }
                catch(e){
                    this.relocation.destination_tag = "";
                    this.relocation.destination_text = "";
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: e.response.data.fault.message
                    }
                }
            }

            this.$store.state.loaderProps.visible = false;
        },
        async checkProduct(barcode){
            if(this.scaned_product_epc.includes(barcode)) return;
            var found = false;

            this.relocation.products.forEach(x => {
                if(x.epcs.includes(barcode)){
                    x.quantity += 1;
                    this.scaned_product_epc.push(barcode);

                    const index = this.scaned_epc_by_product.findIndex(y => y.detailed_stock_id == x.detailed_stock_id);
                    if(index == -1){
                        this.scaned_epc_by_product.push({
                            detailed_stock_id: x.detailed_stock_id,
                            quantity: 1,
                            epcs: [barcode]
                        })
                    }
                    else {
                        this.scaned_epc_by_product[index].quantity += 1;
                        this.scaned_epc_by_product[index].epcs.push(barcode);
                    }

                    found = true;        
                }

                if(found) return;
            })
        }
    },
    watch: {}
}
</script>

<style lang="css">
</style>