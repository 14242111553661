<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('create_relocation.new') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0">
                            <client-selector :addAllOption="false" class="py-0 my-0 " ref="clientSelector" @getClients="v => items.clients = v" :is_national="2"></client-selector>  
                        </v-col>
                    </v-row>
                        
                    <v-form ref="form" lazy-validation @submit.prevent="addSku">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="py-0" v-model="relocation.origin.location_id" :loading="locations_load" item-value="id" item-text="name" :items="locations" :label="$t('create_relocation.origin_location')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="py-0" v-model="relocation.product_id" :loading="products_load" item-value="product_id" item-text="sku" :items="products" :label="$t('create_relocation.sku')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="py-0" v-model="relocation.origin.batch" :items="batches" :label="$t('create_relocation.batch')"></v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="py-0" v-model="relocation.origin.reference" :items="references" :label="$t('create_relocation.expiration')"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="py-0" v-model="relocation.origin.pallet" :items="pallets" :label="$t('create_relocation.origin_pallet')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="relocation.quantity" :label="$t('create_relocation.quantity')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-checkbox class="mt-0" color="info" v-model="relocation.all" :label="$t('create_relocation.select_all')"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="py-0" v-model="relocation.destination.location_id" item-value="id" item-text="name" :items="destination_locations" :label="$t('create_relocation.destination_location')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0" v-if="relocation.destination.location_id != '' && relocation.destination.location_id != undefined && relocation.destination.location_id != null">
                                <label for="">{{ $t('create_relocation.destination_pallet') }}</label>
                                <v-radio-group v-model="relocation.destination.pallet">
                                    <v-row class="px-0 py-0 my-0" justify="start" align="start">
                                        <v-col cols="12" class="py-0" style="display:flex">
                                            <v-radio class="mt-0" color="info" :label="$t('create_relocation.new_pallet')" :value="null"></v-radio>
                                        </v-col>                                    
                                    </v-row>
                                    <v-row class="px-0 py-0 my-0" justify="start" align="start">
                                        <v-col cols="12" class="py-0" style="display:flex" v-for="(pallet, i) of destination_pallets" :key="i">
                                            <v-radio class="mt-0" color="info" :label="pallet.pallet.toString() + ' - (' + $t('create_relocation.contains') +': ' + (pallet.products.includes(',') ? $t('create_relocation.various') : pallet.products) + ')'" :value="pallet.pallet"></v-radio>
                                        </v-col>                                    
                                    </v-row>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0" justify="center">
                            <v-col cols="6" justify="end" align="end">
                                <v-btn outlined class="right white--text" color="amarillo" @click="addSku">
                                    {{ $t('create_relocation.add') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="center" align="center">
                            <datatable :props="{headers: headers, items: props.show_items}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('create_relocation.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'relocation.create'">
                                {{ $t('create_relocation.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        headers: function (){
            return [
                { text: this.$t('create_relocation.sku_'), align: "center", value: 'sku', class: 'text-uppercase' },
                { text: this.$t('create_relocation.pallet'), align: "center", value: 'pallet', class: 'text-uppercase' },
                { text: this.$t('create_relocation.quantity'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('create_relocation.batch'), align: "center", value: 'batch', class: 'text-uppercase' },
                { text: this.$t('create_relocation.expiration'), align: "center", value: 'reference', class: 'text-uppercase' },
                { text: this.$t('create_relocation.origin'), align: "center", value: 'origin_location_name', class: 'text-uppercase' },
                { text: this.$t('create_relocation.destination'), align: "center", value: 'destination_location_name', class: 'text-uppercase' },
                { text: "", align: "center", value: 'actions', class: 'text-uppercase' }
            ];
        }
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            }, 
            actions: [
                {
                    name: this.$t('edit_appointment.product_details.delete_sku'),
                    icon: {
                        color: 'error',
                        icon: 'disabled_by_default'
                    },
                    method: 'deleteSku',
                }
            ],
            relocation: {
                id: "",
                product_id: "",
                quantity: "",
                sku: "",
                origin: {
                    location_id: "",
                    pallet: "",
                    box: "1",
                    batch: "",
                    reference: ""
                },
                destination:{
                    location_id: "",
                    pallet: null,
                },
            },
            origin_locations_loader: true,
            props: {
                items: [],
                show_items: []
            },
            locations_load: false,
            products_load: false,
            destination_locations_load: false,
            destination_pallets: [],
            locations: [],
            products: [],
            pallets: [],
            batches: [],
            references: [],
            destination_locations: []
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.create_relocation";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){},
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.props.show_items.forEach( x => {
                delete x.sku;
                delete x.pallet;
                delete x.batch;
                delete x.reference;
                delete x.origin_location_name;
                delete x.destination_location_name;
                delete x.actions;
                delete x.id;

                if(x.origin.batch == "") x.origin.batch = null;
                if(x.origin.reference == "") x.origin.reference = null;
            })

            this.$http.post(this.$store.state.chronosApi + 'relocations', {relocations: this.props.show_items}, {headers: {Authorization: lsToken}})
            .then((response) => {
                response
                this.$router.push({ name: 'RelocationsIndex' });
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
        addSku: async function (){
            var validate = this.$refs.form.validate();
            
            if(validate){             
                this.$store.state.loaderProps.visible = true;

                this.relocation.id = this.makeid(30),
                this.props.show_items.push(JSON.parse(JSON.stringify({
                    id: this.relocation.id,
                    product_id: parseInt(this.relocation.product_id),
                    quantity: parseInt(this.relocation.quantity),
                    origin: {
                        pallet: parseInt(this.relocation.origin.pallet),
                        box: "1",
                        batch: this.relocation.origin.batch,
                        reference: this.relocation.origin.reference
                    },
                    destination: {
                        location_id: this.relocation.destination.location_id,
                        pallet: parseInt(this.relocation.destination.pallet)
                    },
                    sku: this.products.find(x => x.product_id == this.relocation.product_id).sku,
                    pallet: this.relocation.origin.pallet,
                    batch: this.relocation.origin.batch,
                    reference: this.relocation.origin.reference,
                    origin_location_name: this.locations.find(x => x.id == this.relocation.origin.location_id).name,
                    destination_location_name: this.destination_locations.find(x => x.id == this.relocation.destination.location_id).name,
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: this.relocation.id
                })));

                this.$refs.form.reset();

                this.$store.state.loaderProps.visible = false;
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('edit_appointment.responses.error')
                }
            }
        },
        validate: async function (currentRelocation, lsToken){
            const {data} = await this.$http.post(this.$store.state.chronosApi + 'relocations/validateSku', {relocation: currentRelocation}, {headers: {Authorization: lsToken}})
            return data;
        },
        makeid(length) {
            var result           = '';
            var characters       = '-ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        deleteSku(id){
            this.props.show_items = this.props.show_items.filter(function(obj){
                return obj.id !== id;
            });
        },
    },
    watch: {
        "$store.state.clientId": function(){
            const lsToken = localStorage.getItem("access_token");
            this.destination_locations_load = true;
            this.locations = [];
            this.destination_locations = [];

            this.$http.get(this.$store.state.chronosApi + 'relocations/get_locations?client_id=' + this.$store.state.clientId + '&occupied=true', {headers: {Authorization: lsToken}})
            .then((response) => {
                this.locations = response.data.data;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.locations_load = false;
                this.$store.state.loaderProps.visible = false;
            })

            this.$http.get(this.$store.state.chronosApi + 'relocations/get_locations?client_id=' + this.$store.state.clientId + '&occupied=false', {headers: {Authorization: lsToken}})
            .then((response) => {
                this.destination_locations = response.data.data;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        "relocation.origin.location_id": function(){
            const lsToken = localStorage.getItem("access_token");
            this.products_load = true;
            this.products = [];

            this.$http.get(this.$store.state.chronosApi + 'relocations/get_products_by_location?location_id=' + this.relocation.origin.location_id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.products = response.data.data;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.products_load = false;
                this.$store.state.loaderProps.visible = false;
            })
        },
        "relocation.product_id": function(){
            this.batches = [];
            this.products.forEach(x => {
                if(x.product_id == this.relocation.product_id){
                    x.detailed_stocks.forEach(y => {
                        if(y.batch == null || y.batch == undefined || y.batch == "" && !this.batches.includes(" ")) this.batches.push(" ")
                        else this.batches.push(y.batch);
                    })

                    return;
                }
            })            
        },
        "relocation.origin.pallet": function(){
            this.products.forEach(x => {
                if(x.product_id == this.relocation.product_id){
                    var result = x.detailed_stocks.filter( y => y.pallet == this.relocation.origin.pallet && y.batch == (this.relocation.origin.batch == " " ? null : this.relocation.origin.batch) && y.reference == (this.relocation.origin.reference == " " ? null : this.relocation.origin.reference))
                    
                    if(result.length == 1){
                        this.relocation.quantity = result[0].quantity;
                    }

                    return;
                }
            })
        },
        "relocation.origin.batch": function(){
            this.references = [];
            this.products.forEach(x => {
                if(x.product_id == this.relocation.product_id){
                    var result = x.detailed_stocks.filter( y => y.batch == (this.relocation.origin.batch == " " ? null : this.relocation.origin.batch))
                    console.log(result)
                    if(result.length == 1){
                        if(result[0].reference == null || result[0].reference == undefined || result[0].reference == "" && !this.references.includes(" ")) {
                            this.references.push(" ")
                            this.relocation.origin.reference = " ";
                        }
                        else {
                            this.references.push(result[0].reference)
                            this.relocation.origin.reference = result[0].reference;
                        }

                        this.relocation.quantity = result[0].quantity;
                    } 
                    else if(result.length == 0) { this.relocation.origin.reference = null; }
                    else {
                        result.forEach(r => {
                            if(r.reference == null || r.reference == undefined || r.reference == "" && !this.references.includes(" ")) this.references.push(" ")
                            else this.references.push(r.reference)
                        })
                    }

                    return;
                }
            })
        },
        "relocation.origin.reference": function(){
            this.pallets = [];
            this.products.forEach(x => {
                if(x.product_id == this.relocation.product_id){
                    var result = x.detailed_stocks.filter( y => y.batch == (this.relocation.origin.batch == " " ? null : this.relocation.origin.batch) && y.reference == (this.relocation.origin.reference == " " ? null : this.relocation.origin.reference))

                    result.forEach(r => {
                        this.pallets.push(r.pallet)
                    })

                    return;
                }
            })
        },
        "relocation.destination.location_id": function(){
            this.destination_pallets = [];

            const lsToken = localStorage.getItem("access_token");
            if(this.relocation.destination.location_id != undefined && this.relocation.destination.location_id != null && this.relocation.destination.location_id != ""){
                this.$http.get(this.$store.state.chronosApi + 'relocations/get_pallets_of_destination?location_id=' + this.relocation.destination.location_id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    this.destination_pallets = response.data.data;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            
        }
    }
}
</script>

<style lang="css">
</style>
