<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_role.new_role') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="8" class="py-0">
                                <v-text-field class="py-0" v-model="role.name" :label="$t('edit_role.name')" :rules="rules_required"></v-text-field>  
                            </v-col>
                        </v-row>

                        <v-row class="px-5 py-0 my-0" align="center" justify="center">
                            <v-col class="py-0" cols="8" align="start" justify="center">
                                <label for="" class="azul--text mt-3" style="font-weight: bold; font-size: 18px; width:100%; text-align: start">{{$t('edit_role.permissions')}}</label>  
                                <v-expansion-panels class="my-3">
                                    <v-expansion-panel v-for="item in this.data" :key="item.id">
                                        <v-expansion-panel-header class="my-0 py-0">
                                            <h4 class="azul--text uppercase" >{{ getHeaders(item.id, 'collapse')[0].text }}</h4>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <table-checkbox :props="{headers:getHeaders(item.id, 'table'), items: item.items}" @methodHandler="methodHandler"></table-checkbox>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center" align="end">
                        <v-col cols="8" justify="center" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('create_appointment.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'role.create'">
                                {{ $t('create_appointment.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>

export default {
    computed: {
        client: function(){
            return this.$store.state.clientId
        },
        generalDashboard: function(){
            return this.data.general.items[0].all;
        },
        generalClient: function(){
            return this.data.general.items[1].all;
        },
        generalRelocation: function(){
            return this.data.general.items[2].all;
        },
        generalRelocationWithScanner: function(){
            return this.data.general.items[3].all;
        },
        generalOperation: function(){
            return this.data.general.items[4].all;
        },
        inventoryCategory: function(){
            return this.data.inventary.items[0].all;
        },
        inventoryProduct: function(){
            return this.data.inventary.items[1].all;
        },
        inventoryKit: function(){
            return this.data.inventary.items[2].all;
        },
        appointmentOperationPendings: function (){
            return this.data.appointment_operation.items[0].all;
        },
        appointmentOperationOnPremises: function (){
            return this.data.appointment_operation.items[1].all;
        },
        appointmentOperationInProcess: function (){
            return this.data.appointment_operation.items[2].all;
        },
        appointmentOperationPendingOut: function (){
            return this.data.appointment_operation.items[3].all;
        },
        nationalAppointmentsPending: function(){
            return this.data.national_appointments.items[0].all;
        },
        nationalAppointmentsProcessed: function(){
            return this.data.national_appointments.items[1].all;
        },
        internationalAppointmentsPending: function(){
            return this.data.international_appointments.items[0].all;
        },
        internnationalAppointmentsWithoutAnnex: function(){
            return this.data.international_appointments.items[1].all;
        },
        internnationalAppointmentsProcessed: function(){
            return this.data.international_appointments.items[2].all;
        },
        internnationalAppointmentsDigitalHeritage: function(){
            return this.data.international_appointments.items[3].all;
        },
        complianceWithoutAnnex: function() {
            return this.data.compliance.items[0].all;
        },
        complianceProcessed: function() {
            return this.data.compliance.items[1].all;
        },
        complianceDigitalHeritage: function() {
            return this.data.compliance.items[2].all;
        },
        fedexRegular: function(){
            return this.data.fulfillment_fedex.items[0].all;
        },
        fedexCod: function(){
            return this.data.fulfillment_fedex.items[1].all;
        },
        fedexPendingCod: function(){
            return this.data.fulfillment_fedex.items[2].all;
        },
        fedexPendingRegular: function(){
            return this.data.fulfillment_fedex.items[3].all;
        },
        fedexProcessedCod: function(){
            return this.data.fulfillment_fedex.items[4].all;
        },
        fedexProcessedRegular: function(){
            return this.data.fulfillment_fedex.items[5].all;
        },
        fedexReturnedCod: function(){
            return this.data.fulfillment_fedex.items[6].all;
        },
        fedexReturneddRegular: function(){
            return this.data.fulfillment_fedex.items[7].all;
        },
        fedexAdvancedFulfillment: function(){
            return this.data.fulfillment_fedex.items[8].all;
        },
        minRegular: function(){
            return this.data.fulfillment_99min.items[0].all;
        },
        minCod: function(){
            return this.data.fulfillment_99min.items[1].all;
        },
        minPendingCod: function(){
            return this.data.fulfillment_99min.items[2].all;
        },
        minPendingRegular: function(){
            return this.data.fulfillment_99min.items[3].all;
        },
        minProcessedCod: function(){
            return this.data.fulfillment_99min.items[4].all;
        },
        minProcessedRegular: function(){
            return this.data.fulfillment_99min.items[5].all;
        },
        minReturnedCod: function(){
            return this.data.fulfillment_99min.items[6].all;
        },
        minReturneddRegular: function(){
            return this.data.fulfillment_99min.items[7].all;
        },
        minAdvancedFulfillment: function(){
            return this.data.fulfillment_99min.items[8].all;
        },
        pxpressRegular: function(){
            return this.data.fulfillment_pxpress.items[0].all;
        },
        pxpressCod: function(){
            return this.data.fulfillment_pxpress.items[1].all;
        },
        pxpressPendingCod: function(){
            return this.data.fulfillment_pxpress.items[2].all;
        },
        pxpressPendingRegular: function(){
            return this.data.fulfillment_pxpress.items[3].all;
        },
        pxpressProcessedCod: function(){
            return this.data.fulfillment_pxpress.items[4].all;
        },
        pxpressProcessedRegular: function(){
            return this.data.fulfillment_pxpress.items[5].all;
        },
        pxpressReturnedCod: function(){
            return this.data.fulfillment_pxpress.items[6].all;
        },
        pxpressReturneddRegular: function(){
            return this.data.fulfillment_pxpress.items[7].all;
        },
        pxpressAdvancedFulfillment: function(){
            return this.data.fulfillment_pxpress.items[8].all;
        },
        dhlRegular: function(){
            return this.data.fulfillment_dhl.items[0].all;
        },
        dhlCod: function(){
            return this.data.fulfillment_dhl.items[1].all;
        },
        dhlPendingCod: function(){
            return this.data.fulfillment_dhl.items[2].all;
        },
        dhlPendingRegular: function(){
            return this.data.fulfillment_dhl.items[3].all;
        },
        dhlProcessedCod: function(){
            return this.data.fulfillment_dhl.items[4].all;
        },
        dhlProcessedRegular: function(){
            return this.data.fulfillment_dhl.items[5].all;
        },
        dhlReturnedCod: function(){
            return this.data.fulfillment_dhl.items[6].all;
        },
        dhlReturneddRegular: function(){
            return this.data.fulfillment_dhl.items[7].all;
        },
        dhlAdvancedFulfillment: function(){
            return this.data.fulfillment_dhl.items[8].all;
        },
        estafetaRegular: function(){
            return this.data.fulfillment_estafeta.items[0].all;
        },
        estafetaCod: function(){
            return this.data.fulfillment_estafeta.items[1].all;
        },
        estafetaPendingCod: function(){
            return this.data.fulfillment_estafeta.items[2].all;
        },
        estafetaPendingRegular: function(){
            return this.data.fulfillment_estafeta.items[3].all;
        },
        estafetaProcessedCod: function(){
            return this.data.fulfillment_estafeta.items[4].all;
        },
        estafetaProcessedRegular: function(){
            return this.data.fulfillment_estafeta.items[5].all;
        },
        estafetaReturnedCod: function(){
            return this.data.fulfillment_estafeta.items[6].all;
        },
        estafetaReturneddRegular: function(){
            return this.data.fulfillment_estafeta.items[7].all;
        },
        estafetaAdvancedFulfillment: function(){
            return this.data.fulfillment_estafeta.items[8].all;
        },
        upsRegular: function(){
            return this.data.fulfillment_ups.items[0].all;
        },
        upsCod: function(){
            return this.data.fulfillment_ups.items[1].all;
        },
        upsPendingCod: function(){
            return this.data.fulfillment_ups.items[2].all;
        },
        upsPendingRegular: function(){
            return this.data.fulfillment_ups.items[3].all;
        },
        upsProcessedCod: function(){
            return this.data.fulfillment_ups.items[4].all;
        },
        upsProcessedRegular: function(){
            return this.data.fulfillment_ups.items[5].all;
        },
        upsReturnedCod: function(){
            return this.data.fulfillment_ups.items[6].all;
        },
        upsReturneddRegular: function(){
            return this.data.fulfillment_ups.items[7].all;
        },
        upsAdvancedFulfillment: function(){
            return this.data.fulfillment_ups.items[8].all;
        },
        uspsRegular: function(){
            return this.data.fulfillment_usps.items[0].all;
        },
        uspsCod: function(){
            return this.data.fulfillment_usps.items[1].all;
        },
        uspsPendingCod: function(){
            return this.data.fulfillment_usps.items[2].all;
        },
        uspsPendingRegular: function(){
            return this.data.fulfillment_usps.items[3].all;
        },
        uspsProcessedCod: function(){
            return this.data.fulfillment_usps.items[4].all;
        },
        uspsProcessedRegular: function(){
            return this.data.fulfillment_usps.items[5].all;
        },
        uspsReturnedCod: function(){
            return this.data.fulfillment_usps.items[6].all;
        },
        uspsReturneddRegular: function(){
            return this.data.fulfillment_usps.items[7].all;
        },
        uspsAdvancedFulfillment: function(){
            return this.data.fulfillment_usps.items[8].all;
        },
        quikenRegular: function(){
            return this.data.fulfillment_quiken.items[0].all;
        },
        quikenCod: function(){
            return this.data.fulfillment_quiken.items[1].all;
        },
        quikenPendingCod: function(){
            return this.data.fulfillment_quiken.items[2].all;
        },
        quikenPendingRegular: function(){
            return this.data.fulfillment_quiken.items[3].all;
        },
        quikenProcessedCod: function(){
            return this.data.fulfillment_quiken.items[4].all;
        },
        quikenProcessedRegular: function(){
            return this.data.fulfillment_quiken.items[5].all;
        },
        quikenReturnedCod: function(){
            return this.data.fulfillment_quiken.items[6].all;
        },
        quikenReturneddRegular: function(){
            return this.data.fulfillment_quiken.items[7].all;
        },
        quikenAdvancedFulfillment: function(){
            return this.data.fulfillment_quiken.items[8].all;
        },
        yegoRegular: function(){
            return this.data.fulfillment_yego.items[0].all;
        },
        yegoCod: function(){
            return this.data.fulfillment_yego.items[1].all;
        },
        yegoPendingCod: function(){
            return this.data.fulfillment_yego.items[2].all;
        },
        yegoPendingRegular: function(){
            return this.data.fulfillment_yego.items[3].all;
        },
        yegoProcessedCod: function(){
            return this.data.fulfillment_yego.items[4].all;
        },
        yegoProcessedRegular: function(){
            return this.data.fulfillment_yego.items[5].all;
        },
        yegoReturnedCod: function(){
            return this.data.fulfillment_yego.items[6].all;
        },
        yegoReturneddRegular: function(){
            return this.data.fulfillment_yego.items[7].all;
        },
        yegoAdvancedFulfillment: function(){
            return this.data.fulfillment_yego.items[8].all;
        },
        hermesRegular: function() {
            return this.data.fulfillment_hermes.items[0].all;
        },
        hermesCod: function() {
            return this.data.fulfillment_hermes.items[1].all;
        },
        hermesPendingCod: function() {
            return this.data.fulfillment_hermes.items[2].all;
        },
        hermesPendingRegular: function() {
            return this.data.fulfillment_hermes.items[3].all;
        },
        hermesProcessedCod: function() {
            return this.data.fulfillment_hermes.items[4].all;
        },
        hermesProcessedRegular: function() {
            return this.data.fulfillment_hermes.items[5].all;
        },
        hermesReturnedCod: function() {
            return this.data.fulfillment_hermes.items[6].all;
        },
        hermesReturneddRegular: function() {
            return this.data.fulfillment_hermes.items[7].all;
        },
        hermesAdvancedFulfillment: function(){
            return this.data.fulfillment_hermes.items[8].all;
        },
        otraRegular: function() {
            return this.data.fulfillment_otra.items[0].all;
        },
        otraCod: function() {
            return this.data.fulfillment_otra.items[1].all;
        },
        otraPendingCod: function() {
            return this.data.fulfillment_otra.items[2].all;
        },
        otraPendingRegular: function() {
            return this.data.fulfillment_otra.items[3].all;
        },
        otraProcessedCod: function() {
            return this.data.fulfillment_otra.items[4].all;
        },
        otraProcessedRegular: function() {
            return this.data.fulfillment_otra.items[5].all;
        },
        otraReturnedCod: function() {
            return this.data.fulfillment_otra.items[6].all;
        },
        otraReturneddRegular: function() {
            return this.data.fulfillment_otra.items[7].all;
        },
        otraAdvancedFulfillment: function(){
            return this.data.fulfillment_otra.items[8].all;
        },
        merqRegular: function() {
            return this.data.fulfillment_merq.items[0].all;
        },
        merqCod: function() {
            return this.data.fulfillment_merq.items[1].all;
        },
        merqPendingCod: function() {
            return this.data.fulfillment_merq.items[2].all;
        },
        merqPendingRegular: function() {
            return this.data.fulfillment_merq.items[3].all;
        },
        merqProcessedCod: function() {
            return this.data.fulfillment_merq.items[4].all;
        },
        merqProcessedRegular: function() {
            return this.data.fulfillment_merq.items[5].all;
        },
        merqReturnedCod: function() {
            return this.data.fulfillment_merq.items[6].all;
        },
        merqReturneddRegular: function() {
            return this.data.fulfillment_merq.items[7].all;
        },
        merqAdvancedFulfillment: function(){
            return this.data.fulfillment_merq.items[8].all;
        },
        jtexpressRegular: function() {
            return this.data.fulfillment_jtexpress.items[0].all;
        },
        jtexpressCod: function() {
            return this.data.fulfillment_jtexpress.items[1].all;
        },
        jtexpressPendingCod: function() {
            return this.data.fulfillment_jtexpress.items[2].all;
        },
        jtexpressPendingRegular: function() {
            return this.data.fulfillment_jtexpress.items[3].all;
        },
        jtexpressProcessedCod: function() {
            return this.data.fulfillment_jtexpress.items[4].all;
        },
        jtexpressProcessedRegular: function() {
            return this.data.fulfillment_jtexpress.items[5].all;
        },
        jtexpressReturnedCod: function() {
            return this.data.fulfillment_jtexpress.items[6].all;
        },
        jtexpressReturneddRegular: function() {
            return this.data.fulfillment_jtexpress.items[7].all;
        },
        jtexpressAdvancedFulfillment: function(){
            return this.data.fulfillment_jtexpress.items[8].all;
        },
        fulfillmentEonOrder: function() {
            return this.data.fulfillment_eon.items[0].all;
        },
        fulfillmentEonPending: function() {
            return this.data.fulfillment_eon.items[1].all;
        },
        fulfillmentEonProcessed: function() {
            return this.data.fulfillment_eon.items[2].all;
        },
        fulfillmentEonReturned: function() {
            return this.data.fulfillment_eon.items[3].all;
        },
        fulfillmentEonAdvancedFulfillment: function(){
            return this.data.fulfillment_eon.items[4].all;
        },
        fulfillmentLeiremOrder: function() {
            return this.data.fulfillment_leirem.items[0].all;
        },
        fulfillmentLeiremPending: function() {
            return this.data.fulfillment_leirem.items[1].all;
        },
        fulfillmentLeiremProcessed: function() {
            return this.data.fulfillment_leirem.items[2].all;
        },
        fulfillmentLeiremReturned: function() {
            return this.data.fulfillment_leirem.items[3].all;
        },
        fulfillmentLeiremAdvancedFulfillment: function(){
            return this.data.fulfillment_leirem.items[4].all;
        },
        afimexRegular: function() {
            return this.data.fulfillment_afimex.items[0].all;
        },
        afimexCod: function() {
            return this.data.fulfillment_afimex.items[1].all;
        },
        afimexPendingCod: function() {
            return this.data.fulfillment_afimex.items[2].all;
        },
        afimexPendingRegular: function() {
            return this.data.fulfillment_afimex.items[3].all;
        },
        afimexProcessedCod: function() {
            return this.data.fulfillment_afimex.items[4].all;
        },
        afimexProcessedRegular: function() {
            return this.data.fulfillment_afimex.items[5].all;
        },
        afimexReturnedCod: function() {
            return this.data.fulfillment_afimex.items[6].all;
        },
        afimexReturneddRegular: function() {
            return this.data.fulfillment_afimex.items[7].all;
        },
        afimexAdvancedFulfillment: function(){
            return this.data.fulfillment_afimex.items[8].all;
        },
        cexpressRegular: function(){
            return this.data.fulfillment_cexpress.items[0].all;
        },
        cexpressCod: function(){
            return this.data.fulfillment_cexpress.items[1].all;
        },
        cexpressPendingCod: function(){
            return this.data.fulfillment_cexpress.items[2].all;
        },
        cexpressPendingRegular: function(){
            return this.data.fulfillment_cexpress.items[3].all;
        },
        cexpressProcessedCod: function(){
            return this.data.fulfillment_cexpress.items[4].all;
        },
        cexpressProcessedRegular: function(){
            return this.data.fulfillment_cexpress.items[5].all;
        },
        cexpressReturnedCod: function(){
            return this.data.fulfillment_cexpress.items[6].all;
        },
        cexpressReturneddRegular: function(){
            return this.data.fulfillment_cexpress.items[7].all;
        },
        cexpressAdvancedFulfillment: function(){
            return this.data.fulfillment_cexpress.items[8].all;
        },
        catalogsWarehouse: function(){
            return this.data.catalogs.items[0].all;
        },
        catalogsLocation: function(){
            return this.data.catalogs.items[1].all;
        },
        catalogsGate: function(){
            return this.data.catalogs.items[2].all;
        },
        catalogsBox: function(){
            return this.data.catalogs.items[3].all;
        },
        catalogsCustomsBroker: function(){
            return this.data.catalogs.items[4].all;
        },
        catalogsOriginSupplier: function(){
            return this.data.catalogs.items[5].all;
        },
        catalogsServices: function() {
            return this.data.catalogs.items[6].all;
        },
        catalogsConsumables: function() {
            return this.data.catalogs.items[7].all;
        },
        accessRole: function(){
            return this.data.access.items[0].all;
        },
        accessUser: function(){
            return this.data.access.items[0].all;
        },
        customer: function(){
            return this.data.customers.items[0].view;
        },
        customer_fulfillment: function(){
            return this.data.customers.items[1].view;
        },
        customer_fulfillment_eon: function(){
          return this.data.customers.items[3].view;  
        },
        advanced_customer_fulfillment: function(){
            return this.data.customers.items[2].view;
        },
        customer_all_access: function(){
            return this.data.customers.items[4].view;
        },
        orders_pendings: function(){
            return this.data.orders_pendings.items[0].all;
        },
        orders_posible_fraud: function(){
            return this.data.orders_pendings.items[1].all;
        },
        external_stuck_orders: function(){
            return this.data.orders_pendings.items[2].all;
        },
        customs_patch: function(){
            return this.data.customs_patch.items[0].all;
        },
        visitors: function(){
            return this.data.entrance.items[0].all;
        },
        carriers: function(){
            return this.data.entrance.items[1].all;
        },
        attendances: function(){
            return this.data.entrance.items[2].all;
        },
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            role:{
                name: "",
            },
            shipping_services: ["fedex", "min", "pxpress", "dhl", "estafeta", "ups", "usps", "quiken", "yego", "hermes", "otra", "merq", "jtexpress", "afimex", "cexpress"],
            data:{
                general: {
                    id: 0,
                    items: [
                    {
                            concept: this.$t("edit_role.headers.dashboard"),
                            values: "dashboard",
                            all: false,
                            create: null,
                            view: false,
                            edit: null,
                            delete: null,
                            manage_stores: null
                        },
                        {
                            concept: this.$t("edit_role.headers.client"),
                            values: "client",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false,
                            manage_stores: false
                        },
                        {
                            concept: this.$t("edit_role.headers.relocation"),
                            values: "relocation",
                            all: false,
                            create: false,
                            view: null,
                            edit: null,
                            delete: null,
                            manage_stores: null
                        },
                        {
                            concept: this.$t("edit_role.headers.relocation_with_scanner"),
                            values: "relocation_with_scanner",
                            all: false,
                            create: false,
                            view: null,
                            edit: null,
                            delete: null,
                            manage_stores: null
                        },
                        {
                            concept: this.$t("edit_role.headers.operation"),
                            values: "operation",
                            all: false,
                            create: null,
                            view: false,
                            edit: false,
                            delete: null,
                            manage_stores: null
                        }
                    ]
                },
                inventary: {
                    id: 1,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.category"),
                            values: "category",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false
                        },
                        {
                            concept: this.$t("edit_role.headers.product"),
                            values: "product",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false
                        },
                        {
                            concept: this.$t("edit_role.headers.kit"),
                            values: "kit",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false
                        }
                    ]
                },
                special: {
                    id: 2,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.create_damaged"),
                            values: "damaged",
                            create: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.create_refurbish"),
                            values: "refurbish",
                            create: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.create_massive_products"),
                            values: "massive_products",
                            create: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.create_shipments"),
                            values: "shipment",
                            create: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.customer_fees"),
                            values: "customer_fees",
                            create: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.basic_search"),
                            values: "basic_search",
                            create: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.generate_epcs"),
                            values: "generate_epcs",
                            create: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.update_batch_reference"),
                            values: "update_batch_reference",
                            create: false,
                        }
                    ]
                },
                appointment_operation: {
                    id: 3,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.op_pendings"),
                            values: "op_pendings",
                            all: false,
                            view: false,
                            edit: false
                        },
                        {
                            concept: this.$t("edit_role.headers.op_on_premises"),
                            values: "op_on_premises",
                            all: false,
                            view: false,
                            edit: false
                        },
                        {
                            concept: this.$t("edit_role.headers.op_in_process"),
                            values: "op_in_process",
                            all: false,
                            view: false,
                            edit: false
                        },
                        {
                            concept: this.$t("edit_role.headers.op_pending_out"),
                            values: "op_pending_out",
                            all: false,
                            view: false,
                            edit: false
                        },
                    ]
                },
                national_appointments: {
                    id: 4,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.n_pending"),
                            values: "n_pending",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            file: false,
                            delete: false
                        },
                        // {
                        //     concept: this.$t("edit_role.headers.n_stored"),
                        //     values: "n_stored",
                        //     all: false,
                        //     create: false,
                        //     view: false,
                        //     edit: false,
                        //     file: false,
                        //     delete: false
                        // },
                        {
                            concept: this.$t("edit_role.headers.n_processed"),
                            values: "n_processed",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            file: false,
                            delete: false
                        },
                        {
                            concept: this.$t("edit_role.headers.n_pdf"),
                            values: "n_pdf",
                            all: null,
                            create: null,
                            view: false,
                            edit: null,
                            file: null,
                            delete: null
                        }
                    ]
                },
                international_appointments: {
                    id: 5,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.i_pending"),
                            values: "i_pending",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false,
                            file: false,
                            validate: null
                        },
                        {
                            concept: this.$t("edit_role.headers.i_without_annex"),
                            values: "i_without_annex",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false,
                            file: false,
                            validate: null
                        },
                        {
                            concept: this.$t("edit_role.headers.i_processed"),
                            values: "i_processed",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false,
                            file: false,
                            validate: false
                        },
                        // {
                        //     concept: this.$t("edit_role.headers.i_stored"),
                        //     values: "i_stored",
                        //     all: false,
                        //     create: false,
                        //     view: false,
                        //     edit: false,
                        //     file: false,
                        //     delete: false
                        // },
                        {
                            concept: this.$t("edit_role.headers.i_digital_heritage"),
                            values: "i_digital_heritage",
                            all: false,
                            create: null,
                            view: false,
                            edit: null,
                            delete: null,
                            file: false,
                            validate: null
                        },
                        {
                            concept: this.$t("edit_role.headers.i_pdf"),
                            values: "i_pdf",
                            all: null,
                            create: null,
                            view: false,
                            edit: null,
                            delete: null,
                            file: null,
                            validate: null
                        },
                        {
                            concept: this.$t("edit_role.headers.i_multiple"),
                            values: "i_multiple",
                            all: null,
                            create: false,
                            view: null,
                            edit: null,
                            delete: null,
                            file: null,
                            validate: null
                        }
                    ]
                },
                compliance: {
                    id: 6,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.i_without_annex"),
                            values: "c_without_annex",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false,
                            file: false,
                            validate: null
                        },
                        {
                            concept: this.$t("edit_role.headers.i_processed"),
                            values: "c_processed",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false,
                            file: false,
                            validate: false
                        },
                        {
                            concept: this.$t("edit_role.headers.i_digital_heritage"),
                            values: "c_digital_heritage",
                            all: false,
                            create: null,
                            view: false,
                            edit: null,
                            delete: null,
                            file: false,
                            validate: null
                        }
                    ]
                },
                fulfillment_fedex: {
                    id: 7,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_99min: {
                    id: 8,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_pxpress: {
                    id: 9,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_dhl: {
                    id: 10,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_estafeta: {
                    id: 11,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_ups: {
                    id: 12,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_usps: {
                    id: 13,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_quiken: {
                    id: 14,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_yego: {
                    id: 15,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_hermes: {
                    id: 16,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_otra: {
                    id: 17,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_merq: {
                    id: 18,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_jtexpress: {
                    id: 19,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_afimex: {
                    id: 20,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_cexpress: {
                    id: 21,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.cod_order"),
                            values: "cod_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_cod"),
                            values: "pending_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_cod"),
                            values: "processed_cod",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_returned"),
                            values: "cod_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_order"),
                            values: "regular_order",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.pending_regular"),
                            values: "pending_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.processed_regular"),
                            values: "processed_regular",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.regular_returned"),
                            values: "regular_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_eon: {
                    id:22,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.pendings"),
                            values: "eon_pendings",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.in_process"),
                            values: "eon_in_process",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.sent"),
                            values: "eon_sent",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.returned"),
                            values: "eon_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "eon_advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                fulfillment_leirem: {
                    id:23,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.pendings"),
                            values: "leirem_pendings",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        },
                        {
                            concept: this.$t("edit_role.headers.in_process"),
                            values: "leirem_in_process",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.sent"),
                            values: "leirem_sent",
                            all: false,
                            process: null,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.returned"),
                            values: "leirem_returned",
                            all: false,
                            process: false,
                            view: false,
                            edit: null,
                            restore: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_fulfillment"),
                            values: "leirem_advanced_fulfillment",
                            all: false,
                            process: false,
                            view: false,
                            edit: false,
                            restore: null,
                        }
                    ]
                },
                c_reports: {
                    id: 24,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.stock"),
                            values: "stock",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.operations_by_client"),
                            values: "operations_by_client",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.operations_by_product"),
                            values: "operations_by_product",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.international_operations"),
                            values: "international_operations",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.products_by_fulfillment"),
                            values: "products_by_fulfillment",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.products_by_order"),
                            values: "products_by_order",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.annex_documents"),
                            values: "annex_documents",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.history_product"),
                            values: "history_product",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_by_shipping_service"),
                            values: "cod_by_shipping_service",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.fulfillment_summary"),
                            values: "fulfillment_summary",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.balance_pallet"),
                            values: "balance_pallet",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.boxes_per_fulfillment"),
                            values: "boxes_per_fulfillment",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.condensed_fulfillment"),
                            values: "condensed_fulfillment",
                            view: false,
                        }
                    ]
                },
                catalogs: {
                    id: 25,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.warehouse"),
                            values: "warehouse",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false
                        },
                        {
                            concept: this.$t("edit_role.headers.location"),
                            values: "location",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false
                        },
                        {
                            concept: this.$t("edit_role.headers.gate"),
                            values: "gate",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false
                        },
                        {
                            concept: this.$t("edit_role.headers.box"),
                            values: "box",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false
                        },
                        {
                            concept: this.$t("edit_role.headers.customs_broker"),
                            values: "customs_broker",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false
                        },
                        {
                            concept: this.$t("edit_role.headers.origin_supplier"),
                            values: "origin_supplier",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false
                        },
                        {
                            concept: this.$t("edit_role.headers.services"),
                            values: "service",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: null
                        },
                        {
                            concept: this.$t("edit_role.headers.consumables"),
                            values: "consumable",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false
                        }
                    ]
                },
                access: {
                    id: 26,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.role"),
                            values: "role",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false
                        },
                        {
                            concept: this.$t("edit_role.headers.user"),
                            values: "user",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false
                        }
                    ]
                }, 
                service_to_client: { // Yego services
                    id: 27,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.administrative_history"),
                            values: "administrative_history",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.operative_history"),
                            values: "operative_history",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.balance"),
                            values: "balance",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_search"),
                            values: "search",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.cod_collection"),
                            values: "cod_collection",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.collection"),
                            values: "collection",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.incidence"),
                            values: "incidence",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.hermes_update_order_status"),
                            values: "hermes_update_order_status",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.yego_update_order_status"),
                            values: "yego_update_order_status",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.paypal_update_order_status"),
                            values: "paypal_update_order_status",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.paypal_orders_report"),
                            values: "paypal_orders_report",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.address_corrections"),
                            values: "address_corrections",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.billing"),
                            values: "billing",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.returned_billing"),
                            values: "returned_billing",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.orders_identifier"),
                            values: "orders_identifier",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.products_summary"),
                            values: "products_summary",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.create_multiple_orders"),
                            values: "create_multiple_orders",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.odoo_report"),
                            values: "odoo_report",
                            view: false,
                        },
                    ]
                },
                track_n_trace: {
                    id: 28,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.extra_status"),
                            values: "extra_status",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.daily_cutoff"),
                            values: "daily_cutoff",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.closing_minute"),
                            values: "closing_minute",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.without_movement"),
                            values: "without_movement",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.rejections"),
                            values: "rejections",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.delivered"),
                            values: "delivered",
                            view: false,
                        }
                    ]
                },
                bonded_warehouse:{
                    id: 29,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.customs_operator"),
                            values: "customs_operator",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.customs_administrator"),
                            values: "customs_administrator",
                            view: false,
                        },
                        {
                            concept: this.$t("edit_role.headers.new_annex_24"),
                            values: "new_annex_24",
                            view: false,
                        }
                    ]
                },
                customers:{
                    id: 30,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.customer"),
                            values: "customer",
                            view: false
                        },
                        {
                            concept: this.$t("edit_role.headers.customer_fulfillment"),
                            values: "customer_fulfillment",
                            view: false
                        },
                        {
                            concept: this.$t("edit_role.headers.advanced_customer_fulfillment"),
                            values: "advanced_customer_fulfillment",
                            view: false
                        },
                        {
                            concept: this.$t("edit_role.headers.customer_fulfillment_eon"),
                            values: "customer_fulfillment_eon",
                            view: false
                        },
                        {
                            concept: this.$t("edit_role.headers.customer_all_access"),
                            values: "customer_all_access",
                            view: false
                        }
                    ]
                },
                orders_pendings: {
                    id: 31,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.orders_pendings"),
                            values: "orders_pendings",
                            all: false,
                            view: false,
                            edit: false,
                            delete: false
                        },
                        {
                            concept: this.$t("edit_role.headers.orders_possible_fraud"),
                            values: "orders_possible_fraud",
                            all: false,
                            view: false,
                            edit: false,
                            delete: false
                        },
                        {
                            concept: this.$t("edit_role.headers.stuck_external_orders"),
                            values: "stuck_external_orders",
                            all: false,
                            view: false,
                            edit: null,
                            delete: false
                        }
                    ]
                },
                customs_patch:{
                    id: 32,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.customs_patch"),
                            values: "customs_patch",
                            all: false,
                            create: false,
                            view: false,
                            delete: false
                        }
                    ]
                },
                entrance: {
                    id: 33,
                    items: [
                        {
                            concept: this.$t("edit_role.headers.visitors"),
                            values: "visitors",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false,
                            qr: false,
                            gafete: false
                        },
                        {
                            concept: this.$t("edit_role.headers.carriers"),
                            values: "carriers",
                            all: false,
                            create: false,
                            view: false,
                            edit: false,
                            delete: false,
                            qr: null,
                            gafete: null
                        },
                        {
                            concept: this.$t("edit_role.headers.attendances"),
                            values: "attendances",
                            all: false,
                            create: false,
                            view: false,
                            edit: null,
                            delete: false,
                            qr: null,
                            gafete: null
                        }
                    ]
                }
            },
            headers: [
                "edit_role.headers.general", 
                "edit_role.headers.inventory", 
                "edit_role.headers.special", 
                "edit_role.headers.appointment_operation",
                "edit_role.headers.national_appointment", 
                "edit_role.headers.international_appointment", 
                "edit_role.headers.compliance", 
                "edit_role.headers.fulfillment_fedex", 
                "edit_role.headers.fulfillment_99min", 
                "edit_role.headers.fulfillment_pxpress", 
                "edit_role.headers.fulfillment_dhl", 
                "edit_role.headers.fulfillment_estafeta", 
                "edit_role.headers.fulfillment_ups", 
                "edit_role.headers.fulfillment_usps", 
                "edit_role.headers.fulfillment_quiken", 
                "edit_role.headers.fulfillment_yego", 
                "edit_role.headers.fulfillment_hermes", 
                "edit_role.headers.fulfillment_otra", 
                "edit_role.headers.fulfillment_merq", 
                "edit_role.headers.fulfillment_jtexpress", 
                "edit_role.headers.fulfillment_afimex", 
                "edit_role.headers.fulfillment_cexpress",
                "edit_role.headers.fulfillment_eon", 
                "edit_role.headers.fulfillment_leirem", 
                "edit_role.headers.reports",
                "edit_role.headers.catalog", 
                "edit_role.headers.access", 
                "edit_role.headers.customer_service",
                "edit_role.headers.track_n_trace",
                "edit_role.headers.bonded_warehouse",
                "edit_role.headers.customers",
                "edit_role.headers.orders_pendings",
                "edit_role.headers.customs_patch",
                "edit_role.headers.entrance"
            ],
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.edit_role";
    },
    methods: {
        getHeaders(i, type) {
            var headers = [];
            if(i == 0) {
                headers = [
                    { text: this.$t(this.headers[i]), value: 'concept', class: 'text-uppercase', width:"40%"},
                    { text: this.$t('edit_role.headers.all'), value: 'all', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.create'), value: 'create', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.view'), value: 'view', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.edit'), value: 'edit', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.delete'), value: 'delete', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.manage_stores'), value: 'manage_stores', class: 'text-uppercase' },
                ]
            }
            else if(i == 2){
                headers = [
                    { text: this.$t(this.headers[i]), value: 'concept', class: 'text-uppercase', width:"92%"},
                    { text: this.$t('edit_role.headers.create'), value: 'create', class: 'text-uppercase', width:"8%" }
                ]
            }
            else if(i == 5 || i == 6) { //position of international appointments
                headers = [
                    { text: this.$t(this.headers[i]), value: 'concept', class: 'text-uppercase', width:"40%"},
                    { text: this.$t('edit_role.headers.all'), value: 'all', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.create'), value: 'create', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.view'), value: 'view', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.edit'), value: 'edit', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.delete'), value: 'delete', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.files'), value: 'file', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.validate'), value: 'validate', class: 'text-uppercase' }
                ]
            }
            else if(i == 4) { // national appointments
                headers = [
                    { text: this.$t(this.headers[i]), value: 'concept', class: 'text-uppercase', width:"40%"},
                    { text: this.$t('edit_role.headers.all'), value: 'all', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.create'), value: 'create', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.view'), value: 'view', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.edit'), value: 'edit', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.files'), value: 'file', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.delete'), value: 'delete', class: 'text-uppercase' },
                ]
            }
            else if( i == 7 || i == 8 || i == 9 || i == 10 || i == 11|| i == 12|| i == 13 || i == 14 || i == 15 || i == 16 || i == 17 || i == 18 || i == 19 || i == 20 || i == 21 || i == 22 || i == 23) { //position of fulfillments (fedex, ups, etc)
                headers = [
                    { text: this.$t(this.headers[i]), value: 'concept', class: 'text-uppercase', width:"40%"},
                    { text: this.$t('edit_role.headers.all'), value: 'all', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.process'), value: 'process', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.view'), value: 'view', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.edit'), value: 'edit', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.restore'), value: 'restore', class: 'text-uppercase' }
                ]
            }
            else if( i == 24 || i == 27 || i == 28 || i == 29 || i == 30 ) { //position of report and custom service
                headers = [
                    { text: this.$t(this.headers[i]), value: 'concept', class: 'text-uppercase', width:"92%"},
                    { text: this.$t('edit_role.headers.view'), value: 'view', class: 'text-uppercase', width:"8%" }
                ]
            }
            else if(i == 33) { // entrance
                headers = [
                    { text: this.$t(this.headers[i]), value: 'concept', class: 'text-uppercase', width:"40%"},
                    { text: this.$t('edit_role.headers.all'), value: 'all', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.create'), value: 'create', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.view'), value: 'view', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.edit'), value: 'edit', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.delete'), value: 'delete', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.qr'), value: 'qr', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.gafete'), value: 'gafete', class: 'text-uppercase' },
                ]
            }
            else {
                headers = [
                    { text: this.$t(this.headers[i]), value: 'concept', class: 'text-uppercase', width:"40%"},
                    { text: this.$t('edit_role.headers.all'), value: 'all', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.create'), value: 'create', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.view'), value: 'view', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.edit'), value: 'edit', class: 'text-uppercase' },
                    { text: this.$t('edit_role.headers.delete'), value: 'delete', class: 'text-uppercase' },
                ]
            }

            if(type == "table") headers[0].text = "";
            
            return headers;
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'roles/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200) {
                    response.data = response.data.data.attributes;
                    this.role.name = response.data.name; 
                    this.$store.state.loaderProps.visible = false;
                    response.data.permissions.forEach(x => {
                        x = x.attributes;
                        var srv = 0;
                        var shippings_s = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
                        Object.entries(this.data).map(([, val]) => {
                            val.items.forEach(element => {
                                var concept =  element.values.toLowerCase();
                
                                if(shippings_s.includes(val.id)){
                                    concept = this.shipping_services[srv] + "_" + concept;
                                }
                
                                if(x.name.split('.')[0] == concept && x.name != "customer.show" && x.name != "customer_fulfillment.show" && x.name != "advanced_customer_fulfillment.show" && x.name != "customer_fulfillment_eon.show"){    
                                    Object.entries(element).map(([ekey]) => {
                                        if(x.name.split('.')[1] == "index" || x.name.split('.')[1] == "show") {
                                            element["view"] = true;
                                            return;
                                        }
                                        else if(x.name.split('.')[1] == "update") {
                                            element["edit"] = true;
                                            return;
                                        }
                                        else if(x.name.split('.')[1] == ekey) {
                                            element[ekey] = true;
                                            return;
                                        }
                                        // conditional?permissions.push({'name': concept+'.'+ekey}):0;
                                    })
                                }
                            });
                                        
                            if(shippings_s.includes(val.id)) srv++;
                        });
                    })

                    // Customers Roles
                    if(response.data.permissions.find(e => e.attributes.name == "advanced_customer_fulfillment.show")) this.data.customers.items[2].view = true;
                    else if(response.data.permissions.find(e => e.attributes.name == "customer_fulfillment_eon.show")) this.data.customers.items[3].view = true;
                    else if(response.data.permissions.find(e => e.attributes.name == "customer_fulfillment.show") && this.data.customers.items[2].view == false && this.data.customers.items[3].view == false) this.data.customers.items[1].view = true
                    else if(response.data.permissions.find(e => e.attributes.name == "customer.show") && this.data.customers.items[2].view == false && this.data.customers.items[3].view == false && this.data.customers.items[1].view == false) this.data.customers.items[0].view = true
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            var permissions = []
            var srv = 0;
            var shippings_s =  [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
            Object.entries(this.data).map(([, val]) => {
                val.items.forEach(element => {
                    var concept =  element.values.toLowerCase();
                    
                    if(shippings_s.includes(val.id)){
                        concept = this.shipping_services[srv] + "_" + concept;
                    }
                    delete element.concept;
                    delete element.all;
                    delete element.values,
                    Object.entries(element).map(([ekey, conditional]) => {
                        conditional?permissions.push({'name': concept+'.'+ekey}):0;
                    })
                });

                if(shippings_s.includes(val.id)) srv++;
            });
            var request = {'name': this.role.name , permissions}
            this.role.data = this.data;

            const lsToken = localStorage.getItem("access_token");
            this.$http.put(this.$store.state.chronosApi + 'roles/'+ this.id, request, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                this.$store.state.loaderProps.visible = true;

                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('edit_role.responses.success')
                    }
                    this.$router.push({ name: "RolesIndex" });  
                }
            })
            .catch((response) => {
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch: {
        'generalDashboard': function() {
            if(this.data.general.items[0].all != undefined) { 
                if (this.data.general.items[0].view != null) this.data.general.items[0].view = this.data.general.items[0].all;
            }
        },
        'generalClient': function() {
            if(this.data.general.items[1].all != undefined) { 
                if (this.data.general.items[1].create != null) this.data.general.items[1].create = this.data.general.items[1].all;
                if (this.data.general.items[1].view != null) this.data.general.items[1].view = this.data.general.items[1].all;
                if (this.data.general.items[1].edit != null) this.data.general.items[1].edit = this.data.general.items[1].all;
                if (this.data.general.items[1].delete != null) this.data.general.items[1].delete = this.data.general.items[1].all;
                if (this.data.general.items[1].manage_stores != null) this.data.general.items[1].manage_stores = this.data.general.items[1].all;
            }
        },
        'generalRelocation': function() {
            if(this.data.general.items[2].all != undefined) { 
                if (this.data.general.items[2].create != null) this.data.general.items[2].create = this.data.general.items[2].all;
            }
        },
        'generalRelocationWithScanner': function() {
            if(this.data.general.items[3].all != undefined) { 
                if (this.data.general.items[3].create != null) this.data.general.items[3].create = this.data.general.items[3].all;
            }
        },
        'generalOperation': function() {
            if(this.data.general.items[4].all != undefined) { 
                if (this.data.general.items[4].view != null) this.data.general.items[4].view = this.data.general.items[4].all;
                if (this.data.general.items[4].edit != null) this.data.general.items[4].edit = this.data.general.items[4].all;
            }
        },
        'inventoryCategory': function() {
            if(this.data.inventary.items[0].all != undefined) { 
                if (this.data.inventary.items[0].create != null) this.data.inventary.items[0].create = this.data.inventary.items[0].all;
                if (this.data.inventary.items[0].view != null) this.data.inventary.items[0].view = this.data.inventary.items[0].all;
                if (this.data.inventary.items[0].edit != null) this.data.inventary.items[0].edit = this.data.inventary.items[0].all;
                if (this.data.inventary.items[0].delete != null) this.data.inventary.items[0].delete = this.data.inventary.items[0].all;
            }
        },
        'inventoryProduct': function() {
            if(this.data.inventary.items[1].all != undefined) { 
                if (this.data.inventary.items[1].create != null) this.data.inventary.items[1].create = this.data.inventary.items[1].all;
                if (this.data.inventary.items[1].view != null) this.data.inventary.items[1].view = this.data.inventary.items[1].all;
                if (this.data.inventary.items[1].edit != null) this.data.inventary.items[1].edit = this.data.inventary.items[1].all;
                if (this.data.inventary.items[1].delete != null) this.data.inventary.items[1].delete = this.data.inventary.items[1].all;
            }
        },
        'inventoryKit': function() {
            if(this.data.inventary.items[2].all != undefined) { 
                if (this.data.inventary.items[2].create != null) this.data.inventary.items[2].create = this.data.inventary.items[2].all;
                if (this.data.inventary.items[2].view != null) this.data.inventary.items[2].view = this.data.inventary.items[2].all;
                if (this.data.inventary.items[2].edit != null) this.data.inventary.items[2].edit = this.data.inventary.items[2].all;
                if (this.data.inventary.items[2].delete != null) this.data.inventary.items[2].delete = this.data.inventary.items[2].all;
            }
        },
        'appointmentOperationPendings': function(){
            if(this.data.appointment_operation.items[0].all != undefined) { 
                if (this.data.appointment_operation.items[0].view != null) this.data.appointment_operation.items[0].view = this.data.appointment_operation.items[0].all;
                if (this.data.appointment_operation.items[0].edit != null) this.data.appointment_operation.items[0].edit = this.data.appointment_operation.items[0].all;
            }
        },
        'appointmentOperationOnPremises': function(){
            if(this.data.appointment_operation.items[1].all != undefined) { 
                if (this.data.appointment_operation.items[1].view != null) this.data.appointment_operation.items[1].view = this.data.appointment_operation.items[1].all;
                if (this.data.appointment_operation.items[1].edit != null) this.data.appointment_operation.items[1].edit = this.data.appointment_operation.items[1].all;
            }
        },
        'appointmentOperationInProcess': function(){
            if(this.data.appointment_operation.items[2].all != undefined) { 
                if (this.data.appointment_operation.items[2].view != null) this.data.appointment_operation.items[2].view = this.data.appointment_operation.items[2].all;
                if (this.data.appointment_operation.items[2].edit != null) this.data.appointment_operation.items[2].edit = this.data.appointment_operation.items[2].all;
            }
        },
        'appointmentOperationPendingOut': function(){
            if(this.data.appointment_operation.items[3].all != undefined) { 
                if (this.data.appointment_operation.items[3].view != null) this.data.appointment_operation.items[3].view = this.data.appointment_operation.items[3].all;
                if (this.data.appointment_operation.items[3].edit != null) this.data.appointment_operation.items[3].edit = this.data.appointment_operation.items[3].all;
            }
        },
        'nationalAppointmentsPending': function() {
            if(this.data.national_appointments.items[0].all != undefined) { 
                if (this.data.national_appointments.items[0].create != null) this.data.national_appointments.items[0].create = this.data.national_appointments.items[0].all;
                if (this.data.national_appointments.items[0].view != null) this.data.national_appointments.items[0].view = this.data.national_appointments.items[0].all;
                if (this.data.national_appointments.items[0].edit != null) this.data.national_appointments.items[0].edit = this.data.national_appointments.items[0].all;
                if (this.data.national_appointments.items[0].delete != null) this.data.national_appointments.items[0].delete = this.data.national_appointments.items[0].all;
                if (this.data.national_appointments.items[0].file != undefined && this.data.national_appointments.items[0].file != null) this.data.national_appointments.items[0].file = this.data.national_appointments.items[0].all;
            }
        },
        'nationalAppointmentsProcessed': function() {
            if(this.data.national_appointments.items[1].all != undefined) { 
                if (this.data.national_appointments.items[1].create != null) this.data.national_appointments.items[1].create = this.data.national_appointments.items[1].all;
                if (this.data.national_appointments.items[1].view != null) this.data.national_appointments.items[1].view = this.data.national_appointments.items[1].all;
                if (this.data.national_appointments.items[1].edit != null) this.data.national_appointments.items[1].edit = this.data.national_appointments.items[1].all;
                if (this.data.national_appointments.items[1].delete != null) this.data.national_appointments.items[1].delete = this.data.national_appointments.items[1].all;
                if (this.data.national_appointments.items[1].file != undefined && this.data.national_appointments.items[1].file != null) this.data.national_appointments.items[1].file = this.data.national_appointments.items[1].all;
            }
        },
        'internationalAppointmentsPending': function() {
            if(this.data.international_appointments.items[0].all != undefined) { 
                if (this.data.international_appointments.items[0].create != null) this.data.international_appointments.items[0].create = this.data.international_appointments.items[0].all;
                if (this.data.international_appointments.items[0].view != null) this.data.international_appointments.items[0].view = this.data.international_appointments.items[0].all;
                if (this.data.international_appointments.items[0].edit != null) this.data.international_appointments.items[0].edit = this.data.international_appointments.items[0].all;
                if (this.data.international_appointments.items[0].delete != null) this.data.international_appointments.items[0].delete = this.data.international_appointments.items[0].all;
                if (this.data.international_appointments.items[0].file != undefined && this.data.international_appointments.items[0].file != null) this.data.international_appointments.items[0].file = this.data.international_appointments.items[0].all;
            }
        },
        'internnationalAppointmentsWithoutAnnex': function() {
            if(this.data.international_appointments.items[1].all != undefined) { 
                if (this.data.international_appointments.items[1].create != null) this.data.international_appointments.items[1].create = this.data.international_appointments.items[1].all;
                if (this.data.international_appointments.items[1].view != null) this.data.international_appointments.items[1].view = this.data.international_appointments.items[1].all;
                if (this.data.international_appointments.items[1].edit != null) this.data.international_appointments.items[1].edit = this.data.international_appointments.items[1].all;
                if (this.data.international_appointments.items[1].delete != null) this.data.international_appointments.items[1].delete = this.data.international_appointments.items[1].all;
                if (this.data.international_appointments.items[1].file != undefined && this.data.international_appointments.items[1].file != null) this.data.international_appointments.items[1].file = this.data.international_appointments.items[1].all;
            }
        },
        'internnationalAppointmentsProcessed': function() {
            if(this.data.international_appointments.items[2].all != undefined) { 
                if (this.data.international_appointments.items[2].create != null) this.data.international_appointments.items[2].create = this.data.international_appointments.items[2].all;
                if (this.data.international_appointments.items[2].view != null) this.data.international_appointments.items[2].view = this.data.international_appointments.items[2].all;
                if (this.data.international_appointments.items[2].edit != null) this.data.international_appointments.items[2].edit = this.data.international_appointments.items[2].all;
                if (this.data.international_appointments.items[2].delete != null) this.data.international_appointments.items[2].delete = this.data.international_appointments.items[2].all;
                if (this.data.international_appointments.items[2].file != undefined && this.data.international_appointments.items[2].file != null) this.data.international_appointments.items[2].file = this.data.international_appointments.items[2].all;
                if (this.data.international_appointments.items[2].validate != undefined && this.data.international_appointments.items[2].validate != null) this.data.international_appointments.items[2].validate = this.data.international_appointments.items[2].all;
            }
        },
        'internnationalAppointmentsDigitalHeritage': function() {
            if(this.data.international_appointments.items[3].all != undefined) { 
                if (this.data.international_appointments.items[3].view != null) this.data.international_appointments.items[3].view = this.data.international_appointments.items[3].all;
                if (this.data.international_appointments.items[3].file != undefined && this.data.international_appointments.items[3].file != null) this.data.international_appointments.items[3].file = this.data.international_appointments.items[3].all;
            }
        },
        'complianceWithoutAnnex': function() {
            if(this.data.compliance.items[0].all != undefined) { 
                if (this.data.compliance.items[0].create != null) this.data.compliance.items[0].create = this.data.compliance.items[0].all;
                if (this.data.compliance.items[0].view != null) this.data.compliance.items[0].view = this.data.compliance.items[0].all;
                if (this.data.compliance.items[0].edit != null) this.data.compliance.items[0].edit = this.data.compliance.items[0].all;
                if (this.data.compliance.items[0].delete != null) this.data.compliance.items[0].delete = this.data.compliance.items[0].all;
                if (this.data.compliance.items[0].file != undefined && this.data.compliance.items[0].file != null) this.data.compliance.items[0].file = this.data.compliance.items[0].all;
            }
        },
        'complianceProcessed': function() {
            if(this.data.compliance.items[1].all != undefined) { 
                if (this.data.compliance.items[1].create != null) this.data.compliance.items[1].create = this.data.compliance.items[1].all;
                if (this.data.compliance.items[1].view != null) this.data.compliance.items[1].view = this.data.compliance.items[1].all;
                if (this.data.compliance.items[1].edit != null) this.data.compliance.items[1].edit = this.data.compliance.items[1].all;
                if (this.data.compliance.items[1].delete != null) this.data.compliance.items[1].delete = this.data.compliance.items[1].all;
                if (this.data.compliance.items[1].file != undefined && this.data.compliance.items[1].file != null) this.data.compliance.items[1].file = this.data.compliance.items[1].all;
                if (this.data.compliance.items[1].validate != undefined && this.data.compliance.items[1].validate != null) this.data.compliance.items[1].validate = this.data.compliance.items[1].all;
            }
        },
        'complianceDigitalHeritage': function() {
            if(this.data.compliance.items[2].all != undefined) { 
                if (this.data.compliance.items[2].view != null) this.data.compliance.items[2].view = this.data.compliance.items[2].all;
                if (this.data.compliance.items[2].file != undefined && this.data.compliance.items[2].file != null) this.data.compliance.items[2].file = this.data.compliance.items[2].all;
            }
        },
        'fedexRegular': function() {
            if(this.data.fulfillment_fedex.items[0].all != undefined) { 
                if (this.data.fulfillment_fedex.items[0].view != null) this.data.fulfillment_fedex.items[0].view = this.data.fulfillment_fedex.items[0].all;
                if (this.data.fulfillment_fedex.items[0].edit != null) this.data.fulfillment_fedex.items[0].edit = this.data.fulfillment_fedex.items[0].all;
                if (this.data.fulfillment_fedex.items[0].process != undefined && this.data.fulfillment_fedex.items[0].process != null) this.data.fulfillment_fedex.items[0].process = this.data.fulfillment_fedex.items[0].all;
            }
        },
        'fedexCod': function() {
            if(this.data.fulfillment_fedex.items[1].all != undefined) { 
                if (this.data.fulfillment_fedex.items[1].view != null) this.data.fulfillment_fedex.items[1].view = this.data.fulfillment_fedex.items[1].all;
                if (this.data.fulfillment_fedex.items[1].edit != null) this.data.fulfillment_fedex.items[1].edit = this.data.fulfillment_fedex.items[1].all;
                if (this.data.fulfillment_fedex.items[1].process != undefined && this.data.fulfillment_fedex.items[1].process != null) this.data.fulfillment_fedex.items[1].process = this.data.fulfillment_fedex.items[1].all;
            }
        },
        'fedexPendingCod': function() {
            if(this.data.fulfillment_fedex.items[2].all != undefined) { 
                if (this.data.fulfillment_fedex.items[2].view != null) this.data.fulfillment_fedex.items[2].view = this.data.fulfillment_fedex.items[2].all;
                if (this.data.fulfillment_fedex.items[2].restore != undefined && this.data.fulfillment_fedex.items[2].restore != null) this.data.fulfillment_fedex.items[2].restore = this.data.fulfillment_fedex.items[2].all;
            }
        },
        'fedexPendingRegular': function() {
            if(this.data.fulfillment_fedex.items[3].all != undefined) { 
                if (this.data.fulfillment_fedex.items[3].view != null) this.data.fulfillment_fedex.items[3].view = this.data.fulfillment_fedex.items[3].all;
                if (this.data.fulfillment_fedex.items[3].restore != undefined && this.data.fulfillment_fedex.items[3].restore != null) this.data.fulfillment_fedex.items[3].restore = this.data.fulfillment_fedex.items[3].all;
            }
        },
        'fedexProcessedCod': function() {
            if(this.data.fulfillment_fedex.items[4].all != undefined) { 
                if (this.data.fulfillment_fedex.items[4].view != null) this.data.fulfillment_fedex.items[4].view = this.data.fulfillment_fedex.items[4].all;
                if (this.data.fulfillment_fedex.items[4].restore != undefined && this.data.fulfillment_fedex.items[4].restore != null) this.data.fulfillment_fedex.items[4].restore = this.data.fulfillment_fedex.items[4].all;
            }
        },
        'fedexProcessedRegular': function() {
            if(this.data.fulfillment_fedex.items[5].all != undefined) { 
                if (this.data.fulfillment_fedex.items[5].view != null) this.data.fulfillment_fedex.items[5].view = this.data.fulfillment_fedex.items[5].all;
                if (this.data.fulfillment_fedex.items[5].restore != undefined && this.data.fulfillment_fedex.items[5].restore != null) this.data.fulfillment_fedex.items[5].restore = this.data.fulfillment_fedex.items[5].all;
            }
        },
        'fedexReturnedCod': function() {
            if(this.data.fulfillment_fedex.items[6].all != undefined) { 
                if (this.data.fulfillment_fedex.items[6].view != null) this.data.fulfillment_fedex.items[6].view = this.data.fulfillment_fedex.items[6].all;
                if (this.data.fulfillment_fedex.items[6].process != undefined && this.data.fulfillment_fedex.items[6].process != null) this.data.fulfillment_fedex.items[6].process = this.data.fulfillment_fedex.items[6].all;
                if (this.data.fulfillment_fedex.items[6].restore != undefined && this.data.fulfillment_fedex.items[6].restore != null) this.data.fulfillment_fedex.items[6].restore = this.data.fulfillment_fedex.items[6].all;
            }
        },
        'fedexReturneddRegular': function() {
            if(this.data.fulfillment_fedex.items[7].all != undefined) { 
                if (this.data.fulfillment_fedex.items[7].view != null) this.data.fulfillment_fedex.items[7].view = this.data.fulfillment_fedex.items[7].all;
                if (this.data.fulfillment_fedex.items[7].process != undefined && this.data.fulfillment_fedex.items[7].process != null) this.data.fulfillment_fedex.items[7].process = this.data.fulfillment_fedex.items[7].all;
                if (this.data.fulfillment_fedex.items[7].restore != undefined && this.data.fulfillment_fedex.items[7].restore != null) this.data.fulfillment_fedex.items[7].restore = this.data.fulfillment_fedex.items[7].all;
            }
        },
        'fedexAdvancedFulfillment': function(){
            if(this.data.fulfillment_fedex.items[8].all != undefined) { 
                if (this.data.fulfillment_fedex.items[8].view != null) this.data.fulfillment_fedex.items[8].view = this.data.fulfillment_fedex.items[8].all;
                if (this.data.fulfillment_fedex.items[8].process != undefined && this.data.fulfillment_fedex.items[8].process != null) this.data.fulfillment_fedex.items[8].process = this.data.fulfillment_fedex.items[8].all;
                if (this.data.fulfillment_fedex.items[8].edit != undefined && this.data.fulfillment_fedex.items[8].edit != null) this.data.fulfillment_fedex.items[8].edit = this.data.fulfillment_fedex.items[8].all;
            }
        },
        'minRegular': function() {
            if(this.data.fulfillment_99min.items[0].all != undefined) { 
                if (this.data.fulfillment_99min.items[0].view != null) this.data.fulfillment_99min.items[0].view = this.data.fulfillment_99min.items[0].all;
                if (this.data.fulfillment_99min.items[0].edit != null) this.data.fulfillment_99min.items[0].edit = this.data.fulfillment_99min.items[0].all;
                if (this.data.fulfillment_99min.items[0].process != undefined && this.data.fulfillment_99min.items[0].process != null) this.data.fulfillment_99min.items[0].process = this.data.fulfillment_99min.items[0].all;
            }
        },
        'minCod': function() {
            if(this.data.fulfillment_99min.items[1].all != undefined) { 
                if (this.data.fulfillment_99min.items[1].view != null) this.data.fulfillment_99min.items[1].view = this.data.fulfillment_99min.items[1].all;
                if (this.data.fulfillment_99min.items[1].edit != null) this.data.fulfillment_99min.items[1].edit = this.data.fulfillment_99min.items[1].all;
                if (this.data.fulfillment_99min.items[1].process != undefined && this.data.fulfillment_99min.items[1].process != null) this.data.fulfillment_99min.items[1].process = this.data.fulfillment_99min.items[1].all;
            }
        },
        'minPendingCod': function() {
            if(this.data.fulfillment_99min.items[2].all != undefined) { 
                if (this.data.fulfillment_99min.items[2].view != null) this.data.fulfillment_99min.items[2].view = this.data.fulfillment_99min.items[2].all;
                if (this.data.fulfillment_99min.items[2].restore != undefined && this.data.fulfillment_99min.items[2].restore != null) this.data.fulfillment_99min.items[2].restore = this.data.fulfillment_99min.items[2].all;
            }
        },
        'minPendingRegular': function() {
            if(this.data.fulfillment_99min.items[3].all != undefined) { 
                if (this.data.fulfillment_99min.items[3].view != null) this.data.fulfillment_99min.items[3].view = this.data.fulfillment_99min.items[3].all;
                if (this.data.fulfillment_99min.items[3].restore != undefined && this.data.fulfillment_99min.items[3].restore != null) this.data.fulfillment_99min.items[3].restore = this.data.fulfillment_99min.items[3].all;
            }
        },
        'minProcessedCod': function() {
            if(this.data.fulfillment_99min.items[4].all != undefined) { 
                if (this.data.fulfillment_99min.items[4].view != null) this.data.fulfillment_99min.items[4].view = this.data.fulfillment_99min.items[4].all;
                if (this.data.fulfillment_99min.items[4].restore != undefined && this.data.fulfillment_99min.items[4].restore != null) this.data.fulfillment_99min.items[4].restore = this.data.fulfillment_99min.items[4].all;
            }
        },
        'minProcessedRegular': function() {
            if(this.data.fulfillment_99min.items[5].all != undefined) { 
                if (this.data.fulfillment_99min.items[5].view != null) this.data.fulfillment_99min.items[5].view = this.data.fulfillment_99min.items[5].all;
                if (this.data.fulfillment_99min.items[5].restore != undefined && this.data.fulfillment_99min.items[5].restore != null) this.data.fulfillment_99min.items[5].restore = this.data.fulfillment_99min.items[5].all;
            }
        },
        'minReturnedCod': function() {
            if(this.data.fulfillment_99min.items[6].all != undefined) { 
                if (this.data.fulfillment_99min.items[6].view != null) this.data.fulfillment_99min.items[6].view = this.data.fulfillment_99min.items[6].all;
                if (this.data.fulfillment_99min.items[6].process != undefined && this.data.fulfillment_99min.items[6].process != null) this.data.fulfillment_99min.items[6].process = this.data.fulfillment_99min.items[6].all;
                if (this.data.fulfillment_99min.items[6].restore != undefined && this.data.fulfillment_99min.items[6].restore != null) this.data.fulfillment_99min.items[6].restore = this.data.fulfillment_99min.items[6].all;
            }
        },
        'minReturneddRegular': function() {
            if(this.data.fulfillment_99min.items[7].all != undefined) { 
                if (this.data.fulfillment_99min.items[7].view != null) this.data.fulfillment_99min.items[7].view = this.data.fulfillment_99min.items[7].all;
                if (this.data.fulfillment_99min.items[7].process != undefined && this.data.fulfillment_99min.items[7].process != null) this.data.fulfillment_99min.items[7].process = this.data.fulfillment_99min.items[7].all;
                if (this.data.fulfillment_99min.items[7].restore != undefined && this.data.fulfillment_99min.items[7].restore != null) this.data.fulfillment_99min.items[7].restore = this.data.fulfillment_99min.items[7].all;
            }
        },
        'minAdvancedFulfillment': function(){
            if(this.data.fulfillment_99min.items[8].all != undefined) { 
                if (this.data.fulfillment_99min.items[8].view != null) this.data.fulfillment_99min.items[8].view = this.data.fulfillment_99min.items[8].all;
                if (this.data.fulfillment_99min.items[8].process != undefined && this.data.fulfillment_99min.items[8].process != null) this.data.fulfillment_99min.items[8].process = this.data.fulfillment_99min.items[8].all;
                if (this.data.fulfillment_99min.items[8].edit != undefined && this.data.fulfillment_99min.items[8].edit != null) this.data.fulfillment_99min.items[8].edit = this.data.fulfillment_99min.items[8].all;
            }
        },
        'pxpressRegular': function() {
            if(this.data.fulfillment_pxpress.items[0].all != undefined) { 
                if (this.data.fulfillment_pxpress.items[0].view != null) this.data.fulfillment_pxpress.items[0].view = this.data.fulfillment_pxpress.items[0].all;
                if (this.data.fulfillment_pxpress.items[0].edit != null) this.data.fulfillment_pxpress.items[0].edit = this.data.fulfillment_pxpress.items[0].all;
                if (this.data.fulfillment_pxpress.items[0].process != undefined && this.data.fulfillment_pxpress.items[0].process != null) this.data.fulfillment_pxpress.items[0].process = this.data.fulfillment_pxpress.items[0].all;
            }
        },
        'pxpressCod': function() {
            if(this.data.fulfillment_pxpress.items[1].all != undefined) { 
                if (this.data.fulfillment_pxpress.items[1].view != null) this.data.fulfillment_pxpress.items[1].view = this.data.fulfillment_pxpress.items[1].all;
                if (this.data.fulfillment_pxpress.items[1].edit != null) this.data.fulfillment_pxpress.items[1].edit = this.data.fulfillment_pxpress.items[1].all;
                if (this.data.fulfillment_pxpress.items[1].process != undefined && this.data.fulfillment_pxpress.items[1].process != null) this.data.fulfillment_pxpress.items[1].process = this.data.fulfillment_pxpress.items[1].all;
            }
        },
        'pxpressPendingCod': function() {
            if(this.data.fulfillment_pxpress.items[2].all != undefined) { 
                if (this.data.fulfillment_pxpress.items[2].view != null) this.data.fulfillment_pxpress.items[2].view = this.data.fulfillment_pxpress.items[2].all;
                if (this.data.fulfillment_pxpress.items[2].restore != undefined && this.data.fulfillment_pxpress.items[2].restore != null) this.data.fulfillment_pxpress.items[2].restore = this.data.fulfillment_pxpress.items[2].all;
            }
        },
        'pxpressPendingRegular': function() {
            if(this.data.fulfillment_pxpress.items[3].all != undefined) { 
                if (this.data.fulfillment_pxpress.items[3].view != null) this.data.fulfillment_pxpress.items[3].view = this.data.fulfillment_pxpress.items[3].all;
                if (this.data.fulfillment_pxpress.items[3].restore != undefined && this.data.fulfillment_pxpress.items[3].restore != null) this.data.fulfillment_pxpress.items[3].restore = this.data.fulfillment_pxpress.items[3].all;
            }
        },
        'pxpressProcessedCod': function() {
            if(this.data.fulfillment_pxpress.items[4].all != undefined) { 
                if (this.data.fulfillment_pxpress.items[4].view != null) this.data.fulfillment_pxpress.items[4].view = this.data.fulfillment_pxpress.items[4].all;
                if (this.data.fulfillment_pxpress.items[4].restore != undefined && this.data.fulfillment_pxpress.items[4].restore != null) this.data.fulfillment_pxpress.items[4].restore = this.data.fulfillment_pxpress.items[4].all;
            }
        },
        'pxpressProcessedRegular': function() {
            if(this.data.fulfillment_pxpress.items[5].all != undefined) { 
                if (this.data.fulfillment_pxpress.items[5].view != null) this.data.fulfillment_pxpress.items[5].view = this.data.fulfillment_pxpress.items[5].all;
                if (this.data.fulfillment_pxpress.items[5].restore != undefined && this.data.fulfillment_pxpress.items[5].restore != null) this.data.fulfillment_pxpress.items[5].restore = this.data.fulfillment_pxpress.items[5].all;
            }
        },
        'pxpressReturnedCod': function() {
            if(this.data.fulfillment_pxpress.items[6].all != undefined) { 
                if (this.data.fulfillment_pxpress.items[6].view != null) this.data.fulfillment_pxpress.items[6].view = this.data.fulfillment_pxpress.items[6].all;
                if (this.data.fulfillment_pxpress.items[6].process != undefined && this.data.fulfillment_pxpress.items[6].process != null) this.data.fulfillment_pxpress.items[6].process = this.data.fulfillment_pxpress.items[6].all;
                if (this.data.fulfillment_pxpress.items[6].restore != undefined && this.data.fulfillment_pxpress.items[6].restore != null) this.data.fulfillment_pxpress.items[6].restore = this.data.fulfillment_pxpress.items[6].all;
            }
        },
        'pxpressReturneddRegular': function() {
            if(this.data.fulfillment_pxpress.items[7].all != undefined) { 
                if (this.data.fulfillment_pxpress.items[7].view != null) this.data.fulfillment_pxpress.items[7].view = this.data.fulfillment_pxpress.items[7].all;
                if (this.data.fulfillment_pxpress.items[7].process != undefined && this.data.fulfillment_pxpress.items[7].process != null) this.data.fulfillment_pxpress.items[7].process = this.data.fulfillment_pxpress.items[7].all;
                if (this.data.fulfillment_pxpress.items[7].restore != undefined && this.data.fulfillment_pxpress.items[7].restore != null) this.data.fulfillment_pxpress.items[7].restore = this.data.fulfillment_pxpress.items[7].all;
            }
        },
        'pxpressAdvancedFulfillment': function(){
            if(this.data.fulfillment_pxpress.items[8].all != undefined) { 
                if (this.data.fulfillment_pxpress.items[8].view != null) this.data.fulfillment_pxpress.items[8].view = this.data.fulfillment_pxpress.items[8].all;
                if (this.data.fulfillment_pxpress.items[8].process != undefined && this.data.fulfillment_pxpress.items[8].process != null) this.data.fulfillment_pxpress.items[8].process = this.data.fulfillment_pxpress.items[8].all;
                if (this.data.fulfillment_pxpress.items[8].edit != undefined && this.data.fulfillment_pxpress.items[8].edit != null) this.data.fulfillment_pxpress.items[8].edit = this.data.fulfillment_pxpress.items[8].all;
            }
        },
        'dhlRegular': function() {
            if(this.data.fulfillment_dhl.items[0].all != undefined) { 
                if (this.data.fulfillment_dhl.items[0].view != null) this.data.fulfillment_dhl.items[0].view = this.data.fulfillment_dhl.items[0].all;
                if (this.data.fulfillment_dhl.items[0].edit != null) this.data.fulfillment_dhl.items[0].edit = this.data.fulfillment_dhl.items[0].all;
                if (this.data.fulfillment_dhl.items[0].process != undefined && this.data.fulfillment_dhl.items[0].process != null) this.data.fulfillment_dhl.items[0].process = this.data.fulfillment_dhl.items[0].all;
            }
        },
        'dhlCod': function() {
            if(this.data.fulfillment_dhl.items[1].all != undefined) { 
                if (this.data.fulfillment_dhl.items[1].view != null) this.data.fulfillment_dhl.items[1].view = this.data.fulfillment_dhl.items[1].all;
                if (this.data.fulfillment_dhl.items[1].edit != null) this.data.fulfillment_dhl.items[1].edit = this.data.fulfillment_dhl.items[1].all;
                if (this.data.fulfillment_dhl.items[1].process != undefined && this.data.fulfillment_dhl.items[1].process != null) this.data.fulfillment_dhl.items[1].process = this.data.fulfillment_dhl.items[1].all;
            }
        },
        'dhlPendingCod': function() {
            if(this.data.fulfillment_dhl.items[2].all != undefined) { 
                if (this.data.fulfillment_dhl.items[2].view != null) this.data.fulfillment_dhl.items[2].view = this.data.fulfillment_dhl.items[2].all;
                if (this.data.fulfillment_dhl.items[2].restore != undefined && this.data.fulfillment_dhl.items[2].restore != null) this.data.fulfillment_dhl.items[2].restore = this.data.fulfillment_dhl.items[2].all;
            }
        },
        'dhlPendingRegular': function() {
            if(this.data.fulfillment_dhl.items[3].all != undefined) { 
                if (this.data.fulfillment_dhl.items[3].view != null) this.data.fulfillment_dhl.items[3].view = this.data.fulfillment_dhl.items[3].all;
                if (this.data.fulfillment_dhl.items[3].restore != undefined && this.data.fulfillment_dhl.items[3].restore != null) this.data.fulfillment_dhl.items[3].restore = this.data.fulfillment_dhl.items[3].all;
            }
        },
        'dhlProcessedCod': function() {
            if(this.data.fulfillment_dhl.items[4].all != undefined) { 
                if (this.data.fulfillment_dhl.items[4].view != null) this.data.fulfillment_dhl.items[4].view = this.data.fulfillment_dhl.items[4].all;
                if (this.data.fulfillment_dhl.items[4].restore != undefined && this.data.fulfillment_dhl.items[4].restore != null) this.data.fulfillment_dhl.items[4].restore = this.data.fulfillment_dhl.items[4].all;
            }
        },
        'dhlProcessedRegular': function() {
            if(this.data.fulfillment_dhl.items[5].all != undefined) { 
                if (this.data.fulfillment_dhl.items[5].view != null) this.data.fulfillment_dhl.items[5].view = this.data.fulfillment_dhl.items[5].all;
                if (this.data.fulfillment_dhl.items[5].restore != undefined && this.data.fulfillment_dhl.items[5].restore != null) this.data.fulfillment_dhl.items[5].restore = this.data.fulfillment_dhl.items[5].all;
            }
        },
        'dhlReturnedCod': function() {
            if(this.data.fulfillment_dhl.items[6].all != undefined) { 
                if (this.data.fulfillment_dhl.items[6].view != null) this.data.fulfillment_dhl.items[6].view = this.data.fulfillment_dhl.items[6].all;
                if (this.data.fulfillment_dhl.items[6].process != undefined && this.data.fulfillment_dhl.items[6].process != null) this.data.fulfillment_dhl.items[6].process = this.data.fulfillment_dhl.items[6].all;
                if (this.data.fulfillment_dhl.items[6].restore != undefined && this.data.fulfillment_dhl.items[6].restore != null) this.data.fulfillment_dhl.items[6].restore = this.data.fulfillment_dhl.items[6].all;
            }
        },
        'dhlReturneddRegular': function() {
            if(this.data.fulfillment_dhl.items[7].all != undefined) { 
                if (this.data.fulfillment_dhl.items[7].view != null) this.data.fulfillment_dhl.items[7].view = this.data.fulfillment_dhl.items[7].all;
                if (this.data.fulfillment_dhl.items[7].process != undefined && this.data.fulfillment_dhl.items[7].process != null) this.data.fulfillment_dhl.items[7].process = this.data.fulfillment_dhl.items[7].all;
                if (this.data.fulfillment_dhl.items[7].restore != undefined && this.data.fulfillment_dhl.items[7].restore != null) this.data.fulfillment_dhl.items[7].restore = this.data.fulfillment_dhl.items[7].all;
            }
        },
        'dhlAdvancedFulfillment': function(){
            if(this.data.fulfillment_dhl.items[8].all != undefined) { 
                if (this.data.fulfillment_dhl.items[8].view != null) this.data.fulfillment_dhl.items[8].view = this.data.fulfillment_dhl.items[8].all;
                if (this.data.fulfillment_dhl.items[8].process != undefined && this.data.fulfillment_dhl.items[8].process != null) this.data.fulfillment_dhl.items[8].process = this.data.fulfillment_dhl.items[8].all;
                if (this.data.fulfillment_dhl.items[8].edit != undefined && this.data.fulfillment_dhl.items[8].edit != null) this.data.fulfillment_dhl.items[8].edit = this.data.fulfillment_dhl.items[8].all;
            }
        },
        'estafetaRegular': function() {
            if(this.data.fulfillment_estafeta.items[0].all != undefined) { 
                if (this.data.fulfillment_estafeta.items[0].view != null) this.data.fulfillment_estafeta.items[0].view = this.data.fulfillment_estafeta.items[0].all;
                if (this.data.fulfillment_estafeta.items[0].edit != null) this.data.fulfillment_estafeta.items[0].edit = this.data.fulfillment_estafeta.items[0].all;
                if (this.data.fulfillment_estafeta.items[0].process != undefined && this.data.fulfillment_estafeta.items[0].process != null) this.data.fulfillment_estafeta.items[0].process = this.data.fulfillment_estafeta.items[0].all;
            }
        },
        'estafetaCod': function() {
            if(this.data.fulfillment_estafeta.items[1].all != undefined) { 
                if (this.data.fulfillment_estafeta.items[1].view != null) this.data.fulfillment_estafeta.items[1].view = this.data.fulfillment_estafeta.items[1].all;
                if (this.data.fulfillment_estafeta.items[1].edit != null) this.data.fulfillment_estafeta.items[1].edit = this.data.fulfillment_estafeta.items[1].all;
                if (this.data.fulfillment_estafeta.items[1].process != undefined && this.data.fulfillment_estafeta.items[1].process != null) this.data.fulfillment_estafeta.items[1].process = this.data.fulfillment_estafeta.items[1].all;
            }
        },
        'estafetaPendingCod': function() {
            if(this.data.fulfillment_estafeta.items[2].all != undefined) { 
                if (this.data.fulfillment_estafeta.items[2].view != null) this.data.fulfillment_estafeta.items[2].view = this.data.fulfillment_estafeta.items[2].all;
                if (this.data.fulfillment_estafeta.items[2].restore != undefined && this.data.fulfillment_estafeta.items[2].restore != null) this.data.fulfillment_estafeta.items[2].restore = this.data.fulfillment_estafeta.items[2].all;
            }
        },
        'estafetaPendingRegular': function() {
            if(this.data.fulfillment_estafeta.items[3].all != undefined) { 
                if (this.data.fulfillment_estafeta.items[3].view != null) this.data.fulfillment_estafeta.items[3].view = this.data.fulfillment_estafeta.items[3].all;
                if (this.data.fulfillment_estafeta.items[3].restore != undefined && this.data.fulfillment_estafeta.items[3].restore != null) this.data.fulfillment_estafeta.items[3].restore = this.data.fulfillment_estafeta.items[3].all;
            }
        },
        'estafetaProcessedCod': function() {
            if(this.data.fulfillment_estafeta.items[4].all != undefined) { 
                if (this.data.fulfillment_estafeta.items[4].view != null) this.data.fulfillment_estafeta.items[4].view = this.data.fulfillment_estafeta.items[4].all;
                if (this.data.fulfillment_estafeta.items[4].restore != undefined && this.data.fulfillment_estafeta.items[4].restore != null) this.data.fulfillment_estafeta.items[4].restore = this.data.fulfillment_estafeta.items[4].all;
            }
        },
        'estafetaProcessedRegular': function() {
            if(this.data.fulfillment_estafeta.items[5].all != undefined) { 
                if (this.data.fulfillment_estafeta.items[5].view != null) this.data.fulfillment_estafeta.items[5].view = this.data.fulfillment_estafeta.items[5].all;
                if (this.data.fulfillment_estafeta.items[5].restore != undefined && this.data.fulfillment_estafeta.items[5].restore != null) this.data.fulfillment_estafeta.items[5].restore = this.data.fulfillment_estafeta.items[5].all;
            }
        },
        'estafetaReturnedCod': function() {
            if(this.data.fulfillment_estafeta.items[6].all != undefined) { 
                if (this.data.fulfillment_estafeta.items[6].view != null) this.data.fulfillment_estafeta.items[6].view = this.data.fulfillment_estafeta.items[6].all;
                if (this.data.fulfillment_estafeta.items[6].process != undefined && this.data.fulfillment_estafeta.items[6].process != null) this.data.fulfillment_estafeta.items[6].process = this.data.fulfillment_estafeta.items[6].all;
                if (this.data.fulfillment_estafeta.items[6].restore != undefined && this.data.fulfillment_estafeta.items[6].restore != null) this.data.fulfillment_estafeta.items[6].restore = this.data.fulfillment_estafeta.items[6].all;
            }
        },
        'estafetaReturneddRegular': function() {
            if(this.data.fulfillment_estafeta.items[7].all != undefined) { 
                if (this.data.fulfillment_estafeta.items[7].view != null) this.data.fulfillment_estafeta.items[7].view = this.data.fulfillment_estafeta.items[7].all;
                if (this.data.fulfillment_estafeta.items[7].process != undefined && this.data.fulfillment_estafeta.items[7].process != null) this.data.fulfillment_estafeta.items[7].process = this.data.fulfillment_estafeta.items[7].all;
                if (this.data.fulfillment_estafeta.items[7].restore != undefined && this.data.fulfillment_estafeta.items[7].restore != null) this.data.fulfillment_estafeta.items[7].restore = this.data.fulfillment_estafeta.items[7].all;
            }
        },
        'estafetaAdvancedFulfillment': function(){
            if(this.data.fulfillment_estafeta.items[8].all != undefined) { 
                if (this.data.fulfillment_estafeta.items[8].view != null) this.data.fulfillment_estafeta.items[8].view = this.data.fulfillment_estafeta.items[8].all;
                if (this.data.fulfillment_estafeta.items[8].process != undefined && this.data.fulfillment_estafeta.items[8].process != null) this.data.fulfillment_estafeta.items[8].process = this.data.fulfillment_estafeta.items[8].all;
                if (this.data.fulfillment_estafeta.items[8].edit != undefined && this.data.fulfillment_estafeta.items[8].edit != null) this.data.fulfillment_estafeta.items[8].edit = this.data.fulfillment_estafeta.items[8].all;
            }
        },
        'upsRegular': function() {
            if(this.data.fulfillment_ups.items[0].all != undefined) { 
                if (this.data.fulfillment_ups.items[0].view != null) this.data.fulfillment_ups.items[0].view = this.data.fulfillment_ups.items[0].all;
                if (this.data.fulfillment_ups.items[0].edit != null) this.data.fulfillment_ups.items[0].edit = this.data.fulfillment_ups.items[0].all;
                if (this.data.fulfillment_ups.items[0].process != undefined && this.data.fulfillment_ups.items[0].process != null) this.data.fulfillment_ups.items[0].process = this.data.fulfillment_ups.items[0].all;
            }
        },
        'upsCod': function() {
            if(this.data.fulfillment_ups.items[1].all != undefined) { 
                if (this.data.fulfillment_ups.items[1].view != null) this.data.fulfillment_ups.items[1].view = this.data.fulfillment_ups.items[1].all;
                if (this.data.fulfillment_ups.items[1].edit != null) this.data.fulfillment_ups.items[1].edit = this.data.fulfillment_ups.items[1].all;
                if (this.data.fulfillment_ups.items[1].process != undefined && this.data.fulfillment_ups.items[1].process != null) this.data.fulfillment_ups.items[1].process = this.data.fulfillment_ups.items[1].all;
            }
        },
        'upsPendingCod': function() {
            if(this.data.fulfillment_ups.items[2].all != undefined) { 
                if (this.data.fulfillment_ups.items[2].view != null) this.data.fulfillment_ups.items[2].view = this.data.fulfillment_ups.items[2].all;
                if (this.data.fulfillment_ups.items[2].restore != undefined && this.data.fulfillment_ups.items[2].restore != null) this.data.fulfillment_ups.items[2].restore = this.data.fulfillment_ups.items[2].all;
            }
        },
        'upsPendingRegular': function() {
            if(this.data.fulfillment_ups.items[3].all != undefined) { 
                if (this.data.fulfillment_ups.items[3].view != null) this.data.fulfillment_ups.items[3].view = this.data.fulfillment_ups.items[3].all;
                if (this.data.fulfillment_ups.items[3].restore != undefined && this.data.fulfillment_ups.items[3].restore != null) this.data.fulfillment_ups.items[3].restore = this.data.fulfillment_ups.items[3].all;
            }
        },
        'upsProcessedCod': function() {
            if(this.data.fulfillment_ups.items[4].all != undefined) { 
                if (this.data.fulfillment_ups.items[4].view != null) this.data.fulfillment_ups.items[4].view = this.data.fulfillment_ups.items[4].all;
                if (this.data.fulfillment_ups.items[4].restore != undefined && this.data.fulfillment_ups.items[4].restore != null) this.data.fulfillment_ups.items[4].restore = this.data.fulfillment_ups.items[4].all;
            }
        },
        'upsProcessedRegular': function() {
            if(this.data.fulfillment_ups.items[5].all != undefined) { 
                if (this.data.fulfillment_ups.items[5].view != null) this.data.fulfillment_ups.items[5].view = this.data.fulfillment_ups.items[5].all;
                if (this.data.fulfillment_ups.items[5].restore != undefined && this.data.fulfillment_ups.items[5].restore != null) this.data.fulfillment_ups.items[5].restore = this.data.fulfillment_ups.items[5].all;
            }
        },
        'upsReturnedCod': function() {
            if(this.data.fulfillment_ups.items[6].all != undefined) { 
                if (this.data.fulfillment_ups.items[6].view != null) this.data.fulfillment_ups.items[6].view = this.data.fulfillment_ups.items[6].all;
                if (this.data.fulfillment_ups.items[6].process != undefined && this.data.fulfillment_ups.items[6].process != null) this.data.fulfillment_ups.items[6].process = this.data.fulfillment_ups.items[6].all;
                if (this.data.fulfillment_ups.items[6].restore != undefined && this.data.fulfillment_ups.items[6].restore != null) this.data.fulfillment_ups.items[6].restore = this.data.fulfillment_ups.items[6].all;
            }
        },
        'upsReturneddRegular': function() {
            if(this.data.fulfillment_ups.items[7].all != undefined) { 
                if (this.data.fulfillment_ups.items[7].view != null) this.data.fulfillment_ups.items[7].view = this.data.fulfillment_ups.items[7].all;
                if (this.data.fulfillment_ups.items[7].process != undefined && this.data.fulfillment_ups.items[7].process != null) this.data.fulfillment_ups.items[7].process = this.data.fulfillment_ups.items[7].all;
                if (this.data.fulfillment_ups.items[7].restore != undefined && this.data.fulfillment_ups.items[7].restore != null) this.data.fulfillment_ups.items[7].restore = this.data.fulfillment_ups.items[7].all;
            }
        },
        'upsAdvancedFulfillment': function(){
            if(this.data.fulfillment_ups.items[8].all != undefined) { 
                if (this.data.fulfillment_ups.items[8].view != null) this.data.fulfillment_ups.items[8].view = this.data.fulfillment_ups.items[8].all;
                if (this.data.fulfillment_ups.items[8].process != undefined && this.data.fulfillment_ups.items[8].process != null) this.data.fulfillment_ups.items[8].process = this.data.fulfillment_ups.items[8].all;
                if (this.data.fulfillment_ups.items[8].edit != undefined && this.data.fulfillment_ups.items[8].edit != null) this.data.fulfillment_ups.items[8].edit = this.data.fulfillment_ups.items[8].all;
            }
        },
        'uspsRegular': function() {
            if(this.data.fulfillment_usps.items[0].all != undefined) { 
                if (this.data.fulfillment_usps.items[0].view != null) this.data.fulfillment_usps.items[0].view = this.data.fulfillment_usps.items[0].all;
                if (this.data.fulfillment_usps.items[0].edit != null) this.data.fulfillment_usps.items[0].edit = this.data.fulfillment_usps.items[0].all;
                if (this.data.fulfillment_usps.items[0].process != undefined && this.data.fulfillment_usps.items[0].process != null) this.data.fulfillment_usps.items[0].process = this.data.fulfillment_usps.items[0].all;
            }
        },
        'uspsCod': function() {
            if(this.data.fulfillment_usps.items[1].all != undefined) { 
                if (this.data.fulfillment_usps.items[1].view != null) this.data.fulfillment_usps.items[1].view = this.data.fulfillment_usps.items[1].all;
                if (this.data.fulfillment_usps.items[1].edit != null) this.data.fulfillment_usps.items[1].edit = this.data.fulfillment_usps.items[1].all;
                if (this.data.fulfillment_usps.items[1].process != undefined && this.data.fulfillment_usps.items[1].process != null) this.data.fulfillment_usps.items[1].process = this.data.fulfillment_usps.items[1].all;
            }
        },
        'uspsPendingCod': function() {
            if(this.data.fulfillment_usps.items[2].all != undefined) { 
                if (this.data.fulfillment_usps.items[2].view != null) this.data.fulfillment_usps.items[2].view = this.data.fulfillment_usps.items[2].all;
                if (this.data.fulfillment_usps.items[2].restore != undefined && this.data.fulfillment_usps.items[2].restore != null) this.data.fulfillment_usps.items[2].restore = this.data.fulfillment_usps.items[2].all;
            }
        },
        'uspsPendingRegular': function() {
            if(this.data.fulfillment_usps.items[3].all != undefined) { 
                if (this.data.fulfillment_usps.items[3].view != null) this.data.fulfillment_usps.items[3].view = this.data.fulfillment_usps.items[3].all;
                if (this.data.fulfillment_usps.items[3].restore != undefined && this.data.fulfillment_usps.items[3].restore != null) this.data.fulfillment_usps.items[3].restore = this.data.fulfillment_usps.items[3].all;
            }
        },
        'uspsProcessedCod': function() {
            if(this.data.fulfillment_usps.items[4].all != undefined) { 
                if (this.data.fulfillment_usps.items[4].view != null) this.data.fulfillment_usps.items[4].view = this.data.fulfillment_usps.items[4].all;
                if (this.data.fulfillment_usps.items[4].restore != undefined && this.data.fulfillment_usps.items[4].restore != null) this.data.fulfillment_usps.items[4].restore = this.data.fulfillment_usps.items[4].all;
            }
        },
        'uspsProcessedRegular': function() {
            if(this.data.fulfillment_usps.items[5].all != undefined) { 
                if (this.data.fulfillment_usps.items[5].view != null) this.data.fulfillment_usps.items[5].view = this.data.fulfillment_usps.items[5].all;
                if (this.data.fulfillment_usps.items[5].restore != undefined && this.data.fulfillment_usps.items[5].restore != null) this.data.fulfillment_usps.items[5].restore = this.data.fulfillment_usps.items[5].all;
            }
        },
        'uspsReturnedCod': function() {
            if(this.data.fulfillment_usps.items[6].all != undefined) { 
                if (this.data.fulfillment_usps.items[6].view != null) this.data.fulfillment_usps.items[6].view = this.data.fulfillment_usps.items[6].all;
                if (this.data.fulfillment_usps.items[6].process != undefined && this.data.fulfillment_usps.items[6].process != null) this.data.fulfillment_usps.items[6].process = this.data.fulfillment_usps.items[6].all;
                if (this.data.fulfillment_usps.items[6].restore != undefined && this.data.fulfillment_usps.items[6].restore != null) this.data.fulfillment_usps.items[6].restore = this.data.fulfillment_usps.items[6].all;
            }
        },
        'uspsReturneddRegular': function() {
            if(this.data.fulfillment_usps.items[7].all != undefined) { 
                if (this.data.fulfillment_usps.items[7].view != null) this.data.fulfillment_usps.items[7].view = this.data.fulfillment_usps.items[7].all;
                if (this.data.fulfillment_usps.items[7].process != undefined && this.data.fulfillment_usps.items[7].process != null) this.data.fulfillment_usps.items[7].process = this.data.fulfillment_usps.items[7].all;
                if (this.data.fulfillment_usps.items[7].restore != undefined && this.data.fulfillment_usps.items[7].restore != null) this.data.fulfillment_usps.items[7].restore = this.data.fulfillment_usps.items[7].all;
            }
        },
        'uspsAdvancedFulfillment': function(){
            if(this.data.fulfillment_usps.items[8].all != undefined) { 
                if (this.data.fulfillment_usps.items[8].view != null) this.data.fulfillment_usps.items[8].view = this.data.fulfillment_usps.items[8].all;
                if (this.data.fulfillment_usps.items[8].process != undefined && this.data.fulfillment_usps.items[8].process != null) this.data.fulfillment_usps.items[8].process = this.data.fulfillment_usps.items[8].all;
                if (this.data.fulfillment_usps.items[8].edit != undefined && this.data.fulfillment_usps.items[8].edit != null) this.data.fulfillment_usps.items[8].edit = this.data.fulfillment_usps.items[8].all;
            }
        },
        'quikenRegular': function() {
            if(this.data.fulfillment_quiken.items[0].all != undefined) { 
                if (this.data.fulfillment_quiken.items[0].view != null) this.data.fulfillment_quiken.items[0].view = this.data.fulfillment_quiken.items[0].all;
                if (this.data.fulfillment_quiken.items[0].edit != null) this.data.fulfillment_quiken.items[0].edit = this.data.fulfillment_quiken.items[0].all;
                if (this.data.fulfillment_quiken.items[0].process != undefined && this.data.fulfillment_quiken.items[0].process != null) this.data.fulfillment_quiken.items[0].process = this.data.fulfillment_quiken.items[0].all;
            }
        },
        'quikenCod': function() {
            if(this.data.fulfillment_quiken.items[1].all != undefined) { 
                if (this.data.fulfillment_quiken.items[1].view != null) this.data.fulfillment_quiken.items[1].view = this.data.fulfillment_quiken.items[1].all;
                if (this.data.fulfillment_quiken.items[1].edit != null) this.data.fulfillment_quiken.items[1].edit = this.data.fulfillment_quiken.items[1].all;
                if (this.data.fulfillment_quiken.items[1].process != undefined && this.data.fulfillment_quiken.items[1].process != null) this.data.fulfillment_quiken.items[1].process = this.data.fulfillment_quiken.items[1].all;
            }
        },
        'quikenPendingCod': function() {
            if(this.data.fulfillment_quiken.items[2].all != undefined) { 
                if (this.data.fulfillment_quiken.items[2].view != null) this.data.fulfillment_quiken.items[2].view = this.data.fulfillment_quiken.items[2].all;
                if (this.data.fulfillment_quiken.items[2].restore != undefined && this.data.fulfillment_quiken.items[2].restore != null) this.data.fulfillment_quiken.items[2].restore = this.data.fulfillment_quiken.items[2].all;
            }
        },
        'quikenPendingRegular': function() {
            if(this.data.fulfillment_quiken.items[3].all != undefined) { 
                if (this.data.fulfillment_quiken.items[3].view != null) this.data.fulfillment_quiken.items[3].view = this.data.fulfillment_quiken.items[3].all;
                if (this.data.fulfillment_quiken.items[3].restore != undefined && this.data.fulfillment_quiken.items[3].restore != null) this.data.fulfillment_quiken.items[3].restore = this.data.fulfillment_quiken.items[3].all;
            }
        },
        'quikenProcessedCod': function() {
            if(this.data.fulfillment_quiken.items[4].all != undefined) { 
                if (this.data.fulfillment_quiken.items[4].view != null) this.data.fulfillment_quiken.items[4].view = this.data.fulfillment_quiken.items[4].all;
                if (this.data.fulfillment_quiken.items[4].restore != undefined && this.data.fulfillment_quiken.items[4].restore != null) this.data.fulfillment_quiken.items[4].restore = this.data.fulfillment_quiken.items[4].all;
            }
        },
        'quikenProcessedRegular': function() {
            if(this.data.fulfillment_quiken.items[5].all != undefined) { 
                if (this.data.fulfillment_quiken.items[5].view != null) this.data.fulfillment_quiken.items[5].view = this.data.fulfillment_quiken.items[5].all;
                if (this.data.fulfillment_quiken.items[5].restore != undefined && this.data.fulfillment_quiken.items[5].restore != null) this.data.fulfillment_quiken.items[5].restore = this.data.fulfillment_quiken.items[5].all;
            }
        },
        'quikenReturnedCod': function() {
            if(this.data.fulfillment_quiken.items[6].all != undefined) { 
                if (this.data.fulfillment_quiken.items[6].view != null) this.data.fulfillment_quiken.items[6].view = this.data.fulfillment_quiken.items[6].all;
                if (this.data.fulfillment_quiken.items[6].process != undefined && this.data.fulfillment_quiken.items[6].process != null) this.data.fulfillment_quiken.items[6].process = this.data.fulfillment_quiken.items[6].all;
                if (this.data.fulfillment_quiken.items[6].restore != undefined && this.data.fulfillment_quiken.items[6].restore != null) this.data.fulfillment_quiken.items[6].restore = this.data.fulfillment_quiken.items[6].all;
            }
        },
        'quikenReturneddRegular': function() {
            if(this.data.fulfillment_quiken.items[7].all != undefined) { 
                if (this.data.fulfillment_quiken.items[7].view != null) this.data.fulfillment_quiken.items[7].view = this.data.fulfillment_quiken.items[7].all;
                if (this.data.fulfillment_quiken.items[7].process != undefined && this.data.fulfillment_quiken.items[7].process != null) this.data.fulfillment_quiken.items[7].process = this.data.fulfillment_quiken.items[7].all;
                if (this.data.fulfillment_quiken.items[7].restore != undefined && this.data.fulfillment_quiken.items[7].restore != null) this.data.fulfillment_quiken.items[7].restore = this.data.fulfillment_quiken.items[7].all;
            }
        },
        'quikenAdvancedFulfillment': function(){
            if(this.data.fulfillment_quiken.items[8].all != undefined) { 
                if (this.data.fulfillment_quiken.items[8].view != null) this.data.fulfillment_quiken.items[8].view = this.data.fulfillment_quiken.items[8].all;
                if (this.data.fulfillment_quiken.items[8].process != undefined && this.data.fulfillment_quiken.items[8].process != null) this.data.fulfillment_quiken.items[8].process = this.data.fulfillment_quiken.items[8].all;
                if (this.data.fulfillment_quiken.items[8].edit != undefined && this.data.fulfillment_quiken.items[8].edit != null) this.data.fulfillment_quiken.items[8].edit = this.data.fulfillment_quiken.items[8].all;
            }
        },
        'yegoRegular': function() {
            if(this.data.fulfillment_yego.items[0].all != undefined) { 
                if (this.data.fulfillment_yego.items[0].view != null) this.data.fulfillment_yego.items[0].view = this.data.fulfillment_yego.items[0].all;
                if (this.data.fulfillment_yego.items[0].edit != null) this.data.fulfillment_yego.items[0].edit = this.data.fulfillment_yego.items[0].all;
                if (this.data.fulfillment_yego.items[0].process != undefined && this.data.fulfillment_yego.items[0].process != null) this.data.fulfillment_yego.items[0].process = this.data.fulfillment_yego.items[0].all;
            }
        },
        'yegoCod': function() {
            if(this.data.fulfillment_yego.items[1].all != undefined) { 
                if (this.data.fulfillment_yego.items[1].view != null) this.data.fulfillment_yego.items[1].view = this.data.fulfillment_yego.items[1].all;
                if (this.data.fulfillment_yego.items[1].edit != null) this.data.fulfillment_yego.items[1].edit = this.data.fulfillment_yego.items[1].all;
                if (this.data.fulfillment_yego.items[1].process != undefined && this.data.fulfillment_yego.items[1].process != null) this.data.fulfillment_yego.items[1].process = this.data.fulfillment_yego.items[1].all;
            }
        },
        'yegoPendingCod': function() {
            if(this.data.fulfillment_yego.items[2].all != undefined) { 
                if (this.data.fulfillment_yego.items[2].view != null) this.data.fulfillment_yego.items[2].view = this.data.fulfillment_yego.items[2].all;
                if (this.data.fulfillment_yego.items[2].restore != undefined && this.data.fulfillment_yego.items[2].restore != null) this.data.fulfillment_yego.items[2].restore = this.data.fulfillment_yego.items[2].all;
            }
        },
        'yegoPendingRegular': function() {
            if(this.data.fulfillment_yego.items[3].all != undefined) { 
                if (this.data.fulfillment_yego.items[3].view != null) this.data.fulfillment_yego.items[3].view = this.data.fulfillment_yego.items[3].all;
                if (this.data.fulfillment_yego.items[3].restore != undefined && this.data.fulfillment_yego.items[3].restore != null) this.data.fulfillment_yego.items[3].restore = this.data.fulfillment_yego.items[3].all;
            }
        },
        'yegoProcessedCod': function() {
            if(this.data.fulfillment_yego.items[4].all != undefined) { 
                if (this.data.fulfillment_yego.items[4].view != null) this.data.fulfillment_yego.items[4].view = this.data.fulfillment_yego.items[4].all;
                if (this.data.fulfillment_yego.items[4].restore != undefined && this.data.fulfillment_yego.items[4].restore != null) this.data.fulfillment_yego.items[4].restore = this.data.fulfillment_yego.items[4].all;
            }
        },
        'yegoProcessedRegular': function() {
            if(this.data.fulfillment_yego.items[5].all != undefined) { 
                if (this.data.fulfillment_yego.items[5].view != null) this.data.fulfillment_yego.items[5].view = this.data.fulfillment_yego.items[5].all;
                if (this.data.fulfillment_yego.items[5].restore != undefined && this.data.fulfillment_yego.items[5].restore != null) this.data.fulfillment_yego.items[5].restore = this.data.fulfillment_yego.items[5].all;
            }
        },
        'yegoReturnedCod': function() {
            if(this.data.fulfillment_yego.items[6].all != undefined) { 
                if (this.data.fulfillment_yego.items[6].view != null) this.data.fulfillment_yego.items[6].view = this.data.fulfillment_yego.items[6].all;
                if (this.data.fulfillment_yego.items[6].process != undefined && this.data.fulfillment_yego.items[6].process != null) this.data.fulfillment_yego.items[6].process = this.data.fulfillment_yego.items[6].all;
                if (this.data.fulfillment_yego.items[6].restore != undefined && this.data.fulfillment_yego.items[6].restore != null) this.data.fulfillment_yego.items[6].restore = this.data.fulfillment_yego.items[6].all;
            }
        },
        'yegoReturneddRegular': function() {
            if(this.data.fulfillment_yego.items[7].all != undefined) { 
                if (this.data.fulfillment_yego.items[7].view != null) this.data.fulfillment_yego.items[7].view = this.data.fulfillment_yego.items[7].all;
                if (this.data.fulfillment_yego.items[7].process != undefined && this.data.fulfillment_yego.items[7].process != null) this.data.fulfillment_yego.items[7].process = this.data.fulfillment_yego.items[7].all;
                if (this.data.fulfillment_yego.items[7].restore != undefined && this.data.fulfillment_yego.items[7].restore != null) this.data.fulfillment_yego.items[7].restore = this.data.fulfillment_yego.items[7].all;
            }
        },
        'yegoAdvancedFulfillment': function(){
            if(this.data.fulfillment_yego.items[8].all != undefined) { 
                if (this.data.fulfillment_yego.items[8].view != null) this.data.fulfillment_yego.items[8].view = this.data.fulfillment_yego.items[8].all;
                if (this.data.fulfillment_yego.items[8].process != undefined && this.data.fulfillment_yego.items[8].process != null) this.data.fulfillment_yego.items[8].process = this.data.fulfillment_yego.items[8].all;
                if (this.data.fulfillment_yego.items[8].edit != undefined && this.data.fulfillment_yego.items[8].edit != null) this.data.fulfillment_yego.items[8].edit = this.data.fulfillment_yego.items[8].all;
            }
        },
        'hermesRegular': function() {
            if(this.data.fulfillment_hermes.items[0].all != undefined) { 
                if (this.data.fulfillment_hermes.items[0].view != null) this.data.fulfillment_hermes.items[0].view = this.data.fulfillment_hermes.items[0].all;
                if (this.data.fulfillment_hermes.items[0].edit != null) this.data.fulfillment_hermes.items[0].edit = this.data.fulfillment_hermes.items[0].all;
                if (this.data.fulfillment_hermes.items[0].process != undefined && this.data.fulfillment_hermes.items[0].process != null) this.data.fulfillment_hermes.items[0].process = this.data.fulfillment_hermes.items[0].all;
            }
        },
        'hermesCod': function() {
            if(this.data.fulfillment_hermes.items[1].all != undefined) { 
                if (this.data.fulfillment_hermes.items[1].view != null) this.data.fulfillment_hermes.items[1].view = this.data.fulfillment_hermes.items[1].all;
                if (this.data.fulfillment_hermes.items[1].edit != null) this.data.fulfillment_hermes.items[1].edit = this.data.fulfillment_hermes.items[1].all;
                if (this.data.fulfillment_hermes.items[1].process != undefined && this.data.fulfillment_hermes.items[1].process != null) this.data.fulfillment_hermes.items[1].process = this.data.fulfillment_hermes.items[1].all;
            }
        },
        'hermesPendingCod': function() {
            if(this.data.fulfillment_hermes.items[2].all != undefined) { 
                if (this.data.fulfillment_hermes.items[2].view != null) this.data.fulfillment_hermes.items[2].view = this.data.fulfillment_hermes.items[2].all;
                if (this.data.fulfillment_hermes.items[2].restore != undefined && this.data.fulfillment_hermes.items[2].restore != null) this.data.fulfillment_hermes.items[2].restore = this.data.fulfillment_hermes.items[2].all;
            }
        },
        'hermesPendingRegular': function() {
            if(this.data.fulfillment_hermes.items[3].all != undefined) { 
                if (this.data.fulfillment_hermes.items[3].view != null) this.data.fulfillment_hermes.items[3].view = this.data.fulfillment_hermes.items[3].all;
                if (this.data.fulfillment_hermes.items[3].restore != undefined && this.data.fulfillment_hermes.items[3].restore != null) this.data.fulfillment_hermes.items[3].restore = this.data.fulfillment_hermes.items[3].all;
            }
        },
        'hermesProcessedCod': function() {
            if(this.data.fulfillment_hermes.items[4].all != undefined) { 
                if (this.data.fulfillment_hermes.items[4].view != null) this.data.fulfillment_hermes.items[4].view = this.data.fulfillment_hermes.items[4].all;
                if (this.data.fulfillment_hermes.items[4].restore != undefined && this.data.fulfillment_hermes.items[4].restore != null) this.data.fulfillment_hermes.items[4].restore = this.data.fulfillment_hermes.items[4].all;
            }
        },
        'hermesProcessedRegular': function() {
            if(this.data.fulfillment_hermes.items[5].all != undefined) { 
                if (this.data.fulfillment_hermes.items[5].view != null) this.data.fulfillment_hermes.items[5].view = this.data.fulfillment_hermes.items[5].all;
                if (this.data.fulfillment_hermes.items[5].restore != undefined && this.data.fulfillment_hermes.items[5].restore != null) this.data.fulfillment_hermes.items[5].restore = this.data.fulfillment_hermes.items[5].all;
            }
        },
        'hermesReturnedCod': function() {
            if(this.data.fulfillment_hermes.items[6].all != undefined) { 
                if (this.data.fulfillment_hermes.items[6].view != null) this.data.fulfillment_hermes.items[6].view = this.data.fulfillment_hermes.items[6].all;
                if (this.data.fulfillment_hermes.items[6].process != undefined && this.data.fulfillment_hermes.items[6].process != null) this.data.fulfillment_hermes.items[6].process = this.data.fulfillment_hermes.items[6].all;
                if (this.data.fulfillment_hermes.items[6].restore != undefined && this.data.fulfillment_hermes.items[6].restore != null) this.data.fulfillment_hermes.items[6].restore = this.data.fulfillment_hermes.items[6].all;
            }
        },
        'hermesReturneddRegular': function() {
            if(this.data.fulfillment_hermes.items[7].all != undefined) { 
                if (this.data.fulfillment_hermes.items[7].view != null) this.data.fulfillment_hermes.items[7].view = this.data.fulfillment_hermes.items[7].all;
                if (this.data.fulfillment_hermes.items[7].process != undefined && this.data.fulfillment_hermes.items[7].process != null) this.data.fulfillment_hermes.items[7].process = this.data.fulfillment_hermes.items[7].all;
                if (this.data.fulfillment_hermes.items[7].restore != undefined && this.data.fulfillment_hermes.items[7].restore != null) this.data.fulfillment_hermes.items[7].restore = this.data.fulfillment_hermes.items[7].all;
            }
        },
        'hermesAdvancedFulfillment': function(){
            if(this.data.fulfillment_hermes.items[8].all != undefined) { 
                if (this.data.fulfillment_hermes.items[8].view != null) this.data.fulfillment_hermes.items[8].view = this.data.fulfillment_hermes.items[8].all;
                if (this.data.fulfillment_hermes.items[8].process != undefined && this.data.fulfillment_hermes.items[8].process != null) this.data.fulfillment_hermes.items[8].process = this.data.fulfillment_hermes.items[8].all;
                if (this.data.fulfillment_hermes.items[8].edit != undefined && this.data.fulfillment_hermes.items[8].edit != null) this.data.fulfillment_hermes.items[8].edit = this.data.fulfillment_hermes.items[8].all;
            }
        },
        'otraRegular': function() {
            if(this.data.fulfillment_otra.items[0].all != undefined) { 
                if (this.data.fulfillment_otra.items[0].view != null) this.data.fulfillment_otra.items[0].view = this.data.fulfillment_otra.items[0].all;
                if (this.data.fulfillment_otra.items[0].edit != null) this.data.fulfillment_otra.items[0].edit = this.data.fulfillment_otra.items[0].all;
                if (this.data.fulfillment_otra.items[0].process != undefined && this.data.fulfillment_otra.items[0].process != null) this.data.fulfillment_otra.items[0].process = this.data.fulfillment_otra.items[0].all;
            }
        },
        'otraCod': function() {
            if(this.data.fulfillment_otra.items[1].all != undefined) { 
                if (this.data.fulfillment_otra.items[1].view != null) this.data.fulfillment_otra.items[1].view = this.data.fulfillment_otra.items[1].all;
                if (this.data.fulfillment_otra.items[1].edit != null) this.data.fulfillment_otra.items[1].edit = this.data.fulfillment_otra.items[1].all;
                if (this.data.fulfillment_otra.items[1].process != undefined && this.data.fulfillment_otra.items[1].process != null) this.data.fulfillment_otra.items[1].process = this.data.fulfillment_otra.items[1].all;
            }
        },
        'otraPendingCod': function() {
            if(this.data.fulfillment_otra.items[2].all != undefined) { 
                if (this.data.fulfillment_otra.items[2].view != null) this.data.fulfillment_otra.items[2].view = this.data.fulfillment_otra.items[2].all;
                if (this.data.fulfillment_otra.items[2].restore != undefined && this.data.fulfillment_otra.items[2].restore != null) this.data.fulfillment_otra.items[2].restore = this.data.fulfillment_otra.items[2].all;
            }
        },
        'otraPendingRegular': function() {
            if(this.data.fulfillment_otra.items[3].all != undefined) { 
                if (this.data.fulfillment_otra.items[3].view != null) this.data.fulfillment_otra.items[3].view = this.data.fulfillment_otra.items[3].all;
                if (this.data.fulfillment_otra.items[3].restore != undefined && this.data.fulfillment_otra.items[3].restore != null) this.data.fulfillment_otra.items[3].restore = this.data.fulfillment_otra.items[3].all;
            }
        },
        'otraProcessedCod': function() {
            if(this.data.fulfillment_otra.items[4].all != undefined) { 
                if (this.data.fulfillment_otra.items[4].view != null) this.data.fulfillment_otra.items[4].view = this.data.fulfillment_otra.items[4].all;
                if (this.data.fulfillment_otra.items[4].restore != undefined && this.data.fulfillment_otra.items[4].restore != null) this.data.fulfillment_otra.items[4].restore = this.data.fulfillment_otra.items[4].all;
            }
        },
        'otraProcessedRegular': function() {
            if(this.data.fulfillment_otra.items[5].all != undefined) { 
                if (this.data.fulfillment_otra.items[5].view != null) this.data.fulfillment_otra.items[5].view = this.data.fulfillment_otra.items[5].all;
                if (this.data.fulfillment_otra.items[5].restore != undefined && this.data.fulfillment_otra.items[5].restore != null) this.data.fulfillment_otra.items[5].restore = this.data.fulfillment_otra.items[5].all;
            }
        },
        'otraReturnedCod': function() {
            if(this.data.fulfillment_otra.items[6].all != undefined) { 
                if (this.data.fulfillment_otra.items[6].view != null) this.data.fulfillment_otra.items[6].view = this.data.fulfillment_otra.items[6].all;
                if (this.data.fulfillment_otra.items[6].process != undefined && this.data.fulfillment_otra.items[6].process != null) this.data.fulfillment_otra.items[6].process = this.data.fulfillment_otra.items[6].all;
                if (this.data.fulfillment_otra.items[6].restore != undefined && this.data.fulfillment_otra.items[6].restore != null) this.data.fulfillment_otra.items[6].restore = this.data.fulfillment_otra.items[6].all;
            }
        },
        'otraReturneddRegular': function() {
            if(this.data.fulfillment_otra.items[7].all != undefined) { 
                if (this.data.fulfillment_otra.items[7].view != null) this.data.fulfillment_otra.items[7].view = this.data.fulfillment_otra.items[7].all;
                if (this.data.fulfillment_otra.items[7].process != undefined && this.data.fulfillment_otra.items[7].process != null) this.data.fulfillment_otra.items[7].process = this.data.fulfillment_otra.items[7].all;
                if (this.data.fulfillment_otra.items[7].restore != undefined && this.data.fulfillment_otra.items[7].restore != null) this.data.fulfillment_otra.items[7].restore = this.data.fulfillment_otra.items[7].all;
            }
        },
        'otraAdvancedFulfillment': function(){
            if(this.data.fulfillment_otra.items[8].all != undefined) { 
                if (this.data.fulfillment_otra.items[8].view != null) this.data.fulfillment_otra.items[8].view = this.data.fulfillment_otra.items[8].all;
                if (this.data.fulfillment_otra.items[8].process != undefined && this.data.fulfillment_otra.items[8].process != null) this.data.fulfillment_otra.items[8].process = this.data.fulfillment_otra.items[8].all;
                if (this.data.fulfillment_otra.items[8].edit != undefined && this.data.fulfillment_otra.items[8].edit != null) this.data.fulfillment_otra.items[8].edit = this.data.fulfillment_otra.items[8].all;
            }
        },
        'merqRegular': function() {
            if(this.data.fulfillment_merq.items[0].all != undefined) { 
                if (this.data.fulfillment_merq.items[0].view != null) this.data.fulfillment_merq.items[0].view = this.data.fulfillment_merq.items[0].all;
                if (this.data.fulfillment_merq.items[0].edit != null) this.data.fulfillment_merq.items[0].edit = this.data.fulfillment_merq.items[0].all;
                if (this.data.fulfillment_merq.items[0].process != undefined && this.data.fulfillment_merq.items[0].process != null) this.data.fulfillment_merq.items[0].process = this.data.fulfillment_merq.items[0].all;
            }
        },
        'merqCod': function() {
            if(this.data.fulfillment_merq.items[1].all != undefined) { 
                if (this.data.fulfillment_merq.items[1].view != null) this.data.fulfillment_merq.items[1].view = this.data.fulfillment_merq.items[1].all;
                if (this.data.fulfillment_merq.items[1].edit != null) this.data.fulfillment_merq.items[1].edit = this.data.fulfillment_merq.items[1].all;
                if (this.data.fulfillment_merq.items[1].process != undefined && this.data.fulfillment_merq.items[1].process != null) this.data.fulfillment_merq.items[1].process = this.data.fulfillment_merq.items[1].all;
            }
        },
        'merqPendingCod': function() {
            if(this.data.fulfillment_merq.items[2].all != undefined) { 
                if (this.data.fulfillment_merq.items[2].view != null) this.data.fulfillment_merq.items[2].view = this.data.fulfillment_merq.items[2].all;
                if (this.data.fulfillment_merq.items[2].restore != undefined && this.data.fulfillment_merq.items[2].restore != null) this.data.fulfillment_merq.items[2].restore = this.data.fulfillment_merq.items[2].all;
            }
        },
        'merqPendingRegular': function() {
            if(this.data.fulfillment_merq.items[3].all != undefined) { 
                if (this.data.fulfillment_merq.items[3].view != null) this.data.fulfillment_merq.items[3].view = this.data.fulfillment_merq.items[3].all;
                if (this.data.fulfillment_merq.items[3].restore != undefined && this.data.fulfillment_merq.items[3].restore != null) this.data.fulfillment_merq.items[3].restore = this.data.fulfillment_merq.items[3].all;
            }
        },
        'merqProcessedCod': function() {
            if(this.data.fulfillment_merq.items[4].all != undefined) { 
                if (this.data.fulfillment_merq.items[4].view != null) this.data.fulfillment_merq.items[4].view = this.data.fulfillment_merq.items[4].all;
                if (this.data.fulfillment_merq.items[4].restore != undefined && this.data.fulfillment_merq.items[4].restore != null) this.data.fulfillment_merq.items[4].restore = this.data.fulfillment_merq.items[4].all;
            }
        },
        'merqProcessedRegular': function() {
            if(this.data.fulfillment_merq.items[5].all != undefined) { 
                if (this.data.fulfillment_merq.items[5].view != null) this.data.fulfillment_merq.items[5].view = this.data.fulfillment_merq.items[5].all;
                if (this.data.fulfillment_merq.items[5].restore != undefined && this.data.fulfillment_merq.items[5].restore != null) this.data.fulfillment_merq.items[5].restore = this.data.fulfillment_merq.items[5].all;
            }
        },
        'merqReturnedCod': function() {
            if(this.data.fulfillment_merq.items[6].all != undefined) { 
                if (this.data.fulfillment_merq.items[6].view != null) this.data.fulfillment_merq.items[6].view = this.data.fulfillment_merq.items[6].all;
                if (this.data.fulfillment_merq.items[6].process != undefined && this.data.fulfillment_merq.items[6].process != null) this.data.fulfillment_merq.items[6].process = this.data.fulfillment_merq.items[6].all;
                if (this.data.fulfillment_merq.items[6].restore != undefined && this.data.fulfillment_merq.items[6].restore != null) this.data.fulfillment_merq.items[6].restore = this.data.fulfillment_merq.items[6].all;
            }
        },
        'merqReturneddRegular': function() {
            if(this.data.fulfillment_merq.items[7].all != undefined) { 
                if (this.data.fulfillment_merq.items[7].view != null) this.data.fulfillment_merq.items[7].view = this.data.fulfillment_merq.items[7].all;
                if (this.data.fulfillment_merq.items[7].process != undefined && this.data.fulfillment_merq.items[7].process != null) this.data.fulfillment_merq.items[7].process = this.data.fulfillment_merq.items[7].all;
                if (this.data.fulfillment_merq.items[7].restore != undefined && this.data.fulfillment_merq.items[7].restore != null) this.data.fulfillment_merq.items[7].restore = this.data.fulfillment_merq.items[7].all;
            }
        },
        'merqAdvancedFulfillment': function(){
            if(this.data.fulfillment_merq.items[8].all != undefined) { 
                if (this.data.fulfillment_merq.items[8].view != null) this.data.fulfillment_merq.items[8].view = this.data.fulfillment_merq.items[8].all;
                if (this.data.fulfillment_merq.items[8].process != undefined && this.data.fulfillment_merq.items[8].process != null) this.data.fulfillment_merq.items[8].process = this.data.fulfillment_merq.items[8].all;
                if (this.data.fulfillment_merq.items[8].edit != undefined && this.data.fulfillment_merq.items[8].edit != null) this.data.fulfillment_merq.items[8].edit = this.data.fulfillment_merq.items[8].all;
            }
        },
        'jtexpressRegular': function() {
            if(this.data.fulfillment_jtexpress.items[0].all != undefined) { 
                if (this.data.fulfillment_jtexpress.items[0].view != null) this.data.fulfillment_jtexpress.items[0].view = this.data.fulfillment_jtexpress.items[0].all;
                if (this.data.fulfillment_jtexpress.items[0].edit != null) this.data.fulfillment_jtexpress.items[0].edit = this.data.fulfillment_jtexpress.items[0].all;
                if (this.data.fulfillment_jtexpress.items[0].process != undefined && this.data.fulfillment_jtexpress.items[0].process != null) this.data.fulfillment_jtexpress.items[0].process = this.data.fulfillment_jtexpress.items[0].all;
            }
        },
        'jtexpressCod': function() {
            if(this.data.fulfillment_jtexpress.items[1].all != undefined) { 
                if (this.data.fulfillment_jtexpress.items[1].view != null) this.data.fulfillment_jtexpress.items[1].view = this.data.fulfillment_jtexpress.items[1].all;
                if (this.data.fulfillment_jtexpress.items[1].edit != null) this.data.fulfillment_jtexpress.items[1].edit = this.data.fulfillment_jtexpress.items[1].all;
                if (this.data.fulfillment_jtexpress.items[1].process != undefined && this.data.fulfillment_jtexpress.items[1].process != null) this.data.fulfillment_jtexpress.items[1].process = this.data.fulfillment_jtexpress.items[1].all;
            }
        },
        'jtexpressPendingCod': function() {
            if(this.data.fulfillment_jtexpress.items[2].all != undefined) { 
                if (this.data.fulfillment_jtexpress.items[2].view != null) this.data.fulfillment_jtexpress.items[2].view = this.data.fulfillment_jtexpress.items[2].all;
                if (this.data.fulfillment_jtexpress.items[2].restore != undefined && this.data.fulfillment_jtexpress.items[2].restore != null) this.data.fulfillment_jtexpress.items[2].restore = this.data.fulfillment_jtexpress.items[2].all;
            }
        },
        'jtexpressPendingRegular': function() {
            if(this.data.fulfillment_jtexpress.items[3].all != undefined) { 
                if (this.data.fulfillment_jtexpress.items[3].view != null) this.data.fulfillment_jtexpress.items[3].view = this.data.fulfillment_jtexpress.items[3].all;
                if (this.data.fulfillment_jtexpress.items[3].restore != undefined && this.data.fulfillment_jtexpress.items[3].restore != null) this.data.fulfillment_jtexpress.items[3].restore = this.data.fulfillment_jtexpress.items[3].all;
            }
        },
        'jtexpressProcessedCod': function() {
            if(this.data.fulfillment_jtexpress.items[4].all != undefined) { 
                if (this.data.fulfillment_jtexpress.items[4].view != null) this.data.fulfillment_jtexpress.items[4].view = this.data.fulfillment_jtexpress.items[4].all;
                if (this.data.fulfillment_jtexpress.items[4].restore != undefined && this.data.fulfillment_jtexpress.items[4].restore != null) this.data.fulfillment_jtexpress.items[4].restore = this.data.fulfillment_jtexpress.items[4].all;
            }
        },
        'jtexpressProcessedRegular': function() {
            if(this.data.fulfillment_jtexpress.items[5].all != undefined) { 
                if (this.data.fulfillment_jtexpress.items[5].view != null) this.data.fulfillment_jtexpress.items[5].view = this.data.fulfillment_jtexpress.items[5].all;
                if (this.data.fulfillment_jtexpress.items[5].restore != undefined && this.data.fulfillment_jtexpress.items[5].restore != null) this.data.fulfillment_jtexpress.items[5].restore = this.data.fulfillment_jtexpress.items[5].all;
            }
        },
        'jtexpressReturnedCod': function() {
            if(this.data.fulfillment_jtexpress.items[6].all != undefined) { 
                if (this.data.fulfillment_jtexpress.items[6].view != null) this.data.fulfillment_jtexpress.items[6].view = this.data.fulfillment_jtexpress.items[6].all;
                if (this.data.fulfillment_jtexpress.items[6].process != undefined && this.data.fulfillment_jtexpress.items[6].process != null) this.data.fulfillment_jtexpress.items[6].process = this.data.fulfillment_jtexpress.items[6].all;
                if (this.data.fulfillment_jtexpress.items[6].restore != undefined && this.data.fulfillment_jtexpress.items[6].restore != null) this.data.fulfillment_jtexpress.items[6].restore = this.data.fulfillment_jtexpress.items[6].all;
            }
        },
        'jtexpressReturneddRegular': function() {
            if(this.data.fulfillment_jtexpress.items[7].all != undefined) { 
                if (this.data.fulfillment_jtexpress.items[7].view != null) this.data.fulfillment_jtexpress.items[7].view = this.data.fulfillment_jtexpress.items[7].all;
                if (this.data.fulfillment_jtexpress.items[7].process != undefined && this.data.fulfillment_jtexpress.items[7].process != null) this.data.fulfillment_jtexpress.items[7].process = this.data.fulfillment_jtexpress.items[7].all;
                if (this.data.fulfillment_jtexpress.items[7].restore != undefined && this.data.fulfillment_jtexpress.items[7].restore != null) this.data.fulfillment_jtexpress.items[7].restore = this.data.fulfillment_jtexpress.items[7].all;
            }
        },
        'jtexpressAdvancedFulfillment': function(){
            if(this.data.fulfillment_jtexpress.items[8].all != undefined) { 
                if (this.data.fulfillment_jtexpress.items[8].view != null) this.data.fulfillment_jtexpress.items[8].view = this.data.fulfillment_jtexpress.items[8].all;
                if (this.data.fulfillment_jtexpress.items[8].process != undefined && this.data.fulfillment_jtexpress.items[8].process != null) this.data.fulfillment_jtexpress.items[8].process = this.data.fulfillment_jtexpress.items[8].all;
                if (this.data.fulfillment_jtexpress.items[8].edit != undefined && this.data.fulfillment_jtexpress.items[8].edit != null) this.data.fulfillment_jtexpress.items[8].edit = this.data.fulfillment_jtexpress.items[8].all;
            }
        },
        'afimexRegular': function() {
            if(this.data.fulfillment_afimex.items[0].all != undefined) { 
                if (this.data.fulfillment_afimex.items[0].view != null) this.data.fulfillment_afimex.items[0].view = this.data.fulfillment_afimex.items[0].all;
                if (this.data.fulfillment_afimex.items[0].edit != null) this.data.fulfillment_afimex.items[0].edit = this.data.fulfillment_afimex.items[0].all;
                if (this.data.fulfillment_afimex.items[0].process != undefined && this.data.fulfillment_afimex.items[0].process != null) this.data.fulfillment_afimex.items[0].process = this.data.fulfillment_afimex.items[0].all;
            }
        },
        'afimexCod': function() {
            if(this.data.fulfillment_afimex.items[1].all != undefined) { 
                if (this.data.fulfillment_afimex.items[1].view != null) this.data.fulfillment_afimex.items[1].view = this.data.fulfillment_afimex.items[1].all;
                if (this.data.fulfillment_afimex.items[1].edit != null) this.data.fulfillment_afimex.items[1].edit = this.data.fulfillment_afimex.items[1].all;
                if (this.data.fulfillment_afimex.items[1].process != undefined && this.data.fulfillment_afimex.items[1].process != null) this.data.fulfillment_afimex.items[1].process = this.data.fulfillment_afimex.items[1].all;
            }
        },
        'afimexPendingCod': function() {
            if(this.data.fulfillment_afimex.items[2].all != undefined) { 
                if (this.data.fulfillment_afimex.items[2].view != null) this.data.fulfillment_afimex.items[2].view = this.data.fulfillment_afimex.items[2].all;
                if (this.data.fulfillment_afimex.items[2].restore != undefined && this.data.fulfillment_afimex.items[2].restore != null) this.data.fulfillment_afimex.items[2].restore = this.data.fulfillment_afimex.items[2].all;
            }
        },
        'afimexPendingRegular': function() {
            if(this.data.fulfillment_afimex.items[3].all != undefined) { 
                if (this.data.fulfillment_afimex.items[3].view != null) this.data.fulfillment_afimex.items[3].view = this.data.fulfillment_afimex.items[3].all;
                if (this.data.fulfillment_afimex.items[3].restore != undefined && this.data.fulfillment_afimex.items[3].restore != null) this.data.fulfillment_afimex.items[3].restore = this.data.fulfillment_afimex.items[3].all;
            }
        },
        'afimexProcessedCod': function() {
            if(this.data.fulfillment_afimex.items[4].all != undefined) { 
                if (this.data.fulfillment_afimex.items[4].view != null) this.data.fulfillment_afimex.items[4].view = this.data.fulfillment_afimex.items[4].all;
                if (this.data.fulfillment_afimex.items[4].restore != undefined && this.data.fulfillment_afimex.items[4].restore != null) this.data.fulfillment_afimex.items[4].restore = this.data.fulfillment_afimex.items[4].all;
            }
        },
        'afimexProcessedRegular': function() {
            if(this.data.fulfillment_afimex.items[5].all != undefined) { 
                if (this.data.fulfillment_afimex.items[5].view != null) this.data.fulfillment_afimex.items[5].view = this.data.fulfillment_afimex.items[5].all;
                if (this.data.fulfillment_afimex.items[5].restore != undefined && this.data.fulfillment_afimex.items[5].restore != null) this.data.fulfillment_afimex.items[5].restore = this.data.fulfillment_afimex.items[5].all;
            }
        },
        'afimexReturnedCod': function() {
            if(this.data.fulfillment_afimex.items[6].all != undefined) { 
                if (this.data.fulfillment_afimex.items[6].view != null) this.data.fulfillment_afimex.items[6].view = this.data.fulfillment_afimex.items[6].all;
                if (this.data.fulfillment_afimex.items[6].process != undefined && this.data.fulfillment_afimex.items[6].process != null) this.data.fulfillment_afimex.items[6].process = this.data.fulfillment_afimex.items[6].all;
                if (this.data.fulfillment_afimex.items[6].restore != undefined && this.data.fulfillment_afimex.items[6].restore != null) this.data.fulfillment_afimex.items[6].restore = this.data.fulfillment_afimex.items[6].all;
            }
        },
        'afimexReturneddRegular': function() {
            if(this.data.fulfillment_afimex.items[7].all != undefined) { 
                if (this.data.fulfillment_afimex.items[7].view != null) this.data.fulfillment_afimex.items[7].view = this.data.fulfillment_afimex.items[7].all;
                if (this.data.fulfillment_afimex.items[7].process != undefined && this.data.fulfillment_afimex.items[7].process != null) this.data.fulfillment_afimex.items[7].process = this.data.fulfillment_afimex.items[7].all;
                if (this.data.fulfillment_afimex.items[7].restore != undefined && this.data.fulfillment_afimex.items[7].restore != null) this.data.fulfillment_afimex.items[7].restore = this.data.fulfillment_afimex.items[7].all;
            }
        },
        'afimexAdvancedFulfillment': function(){
            if(this.data.fulfillment_afimex.items[8].all != undefined) { 
                if (this.data.fulfillment_afimex.items[8].view != null) this.data.fulfillment_afimex.items[8].view = this.data.fulfillment_afimex.items[8].all;
                if (this.data.fulfillment_afimex.items[8].process != undefined && this.data.fulfillment_afimex.items[8].process != null) this.data.fulfillment_afimex.items[8].process = this.data.fulfillment_afimex.items[8].all;
                if (this.data.fulfillment_afimex.items[8].edit != undefined && this.data.fulfillment_afimex.items[8].edit != null) this.data.fulfillment_afimex.items[8].edit = this.data.fulfillment_afimex.items[8].all;
            }
        },
        'cexpressRegular': function() {
            if(this.data.fulfillment_cexpress.items[0].all != undefined) { 
                if (this.data.fulfillment_cexpress.items[0].view != null) this.data.fulfillment_cexpress.items[0].view = this.data.fulfillment_cexpress.items[0].all;
                if (this.data.fulfillment_cexpress.items[0].edit != null) this.data.fulfillment_cexpress.items[0].edit = this.data.fulfillment_cexpress.items[0].all;
                if (this.data.fulfillment_cexpress.items[0].process != undefined && this.data.fulfillment_cexpress.items[0].process != null) this.data.fulfillment_cexpress.items[0].process = this.data.fulfillment_cexpress.items[0].all;
            }
        },
        'cexpressCod': function() {
            if(this.data.fulfillment_cexpress.items[1].all != undefined) { 
                if (this.data.fulfillment_cexpress.items[1].view != null) this.data.fulfillment_cexpress.items[1].view = this.data.fulfillment_cexpress.items[1].all;
                if (this.data.fulfillment_cexpress.items[1].edit != null) this.data.fulfillment_cexpress.items[1].edit = this.data.fulfillment_cexpress.items[1].all;
                if (this.data.fulfillment_cexpress.items[1].process != undefined && this.data.fulfillment_cexpress.items[1].process != null) this.data.fulfillment_cexpress.items[1].process = this.data.fulfillment_cexpress.items[1].all;
            }
        },
        'cexpressPendingCod': function() {
            if(this.data.fulfillment_cexpress.items[2].all != undefined) { 
                if (this.data.fulfillment_cexpress.items[2].view != null) this.data.fulfillment_cexpress.items[2].view = this.data.fulfillment_cexpress.items[2].all;
                if (this.data.fulfillment_cexpress.items[2].restore != undefined && this.data.fulfillment_cexpress.items[2].restore != null) this.data.fulfillment_cexpress.items[2].restore = this.data.fulfillment_cexpress.items[2].all;
            }
        },
        'cexpressPendingRegular': function() {
            if(this.data.fulfillment_cexpress.items[3].all != undefined) { 
                if (this.data.fulfillment_cexpress.items[3].view != null) this.data.fulfillment_cexpress.items[3].view = this.data.fulfillment_cexpress.items[3].all;
                if (this.data.fulfillment_cexpress.items[3].restore != undefined && this.data.fulfillment_cexpress.items[3].restore != null) this.data.fulfillment_cexpress.items[3].restore = this.data.fulfillment_cexpress.items[3].all;
            }
        },
        'cexpressProcessedCod': function() {
            if(this.data.fulfillment_cexpress.items[4].all != undefined) { 
                if (this.data.fulfillment_cexpress.items[4].view != null) this.data.fulfillment_cexpress.items[4].view = this.data.fulfillment_cexpress.items[4].all;
                if (this.data.fulfillment_cexpress.items[4].restore != undefined && this.data.fulfillment_cexpress.items[4].restore != null) this.data.fulfillment_cexpress.items[4].restore = this.data.fulfillment_cexpress.items[4].all;
            }
        },
        'cexpressProcessedRegular': function() {
            if(this.data.fulfillment_cexpress.items[5].all != undefined) { 
                if (this.data.fulfillment_cexpress.items[5].view != null) this.data.fulfillment_cexpress.items[5].view = this.data.fulfillment_cexpress.items[5].all;
                if (this.data.fulfillment_cexpress.items[5].restore != undefined && this.data.fulfillment_cexpress.items[5].restore != null) this.data.fulfillment_cexpress.items[5].restore = this.data.fulfillment_cexpress.items[5].all;
            }
        },
        'cexpressReturnedCod': function() {
            if(this.data.fulfillment_cexpress.items[6].all != undefined) { 
                if (this.data.fulfillment_cexpress.items[6].view != null) this.data.fulfillment_cexpress.items[6].view = this.data.fulfillment_cexpress.items[6].all;
                if (this.data.fulfillment_cexpress.items[6].process != undefined && this.data.fulfillment_cexpress.items[6].process != null) this.data.fulfillment_cexpress.items[6].process = this.data.fulfillment_cexpress.items[6].all;
                if (this.data.fulfillment_cexpress.items[6].restore != undefined && this.data.fulfillment_cexpress.items[6].restore != null) this.data.fulfillment_cexpress.items[6].restore = this.data.fulfillment_cexpress.items[6].all;
            }
        },
        'cexpressReturneddRegular': function() {
            if(this.data.fulfillment_cexpress.items[7].all != undefined) { 
                if (this.data.fulfillment_cexpress.items[7].view != null) this.data.fulfillment_cexpress.items[7].view = this.data.fulfillment_cexpress.items[7].all;
                if (this.data.fulfillment_cexpress.items[7].process != undefined && this.data.fulfillment_cexpress.items[7].process != null) this.data.fulfillment_cexpress.items[7].process = this.data.fulfillment_cexpress.items[7].all;
                if (this.data.fulfillment_cexpress.items[7].restore != undefined && this.data.fulfillment_cexpress.items[7].restore != null) this.data.fulfillment_cexpress.items[7].restore = this.data.fulfillment_cexpress.items[7].all;
            }
        },
        'cexpressAdvancedFulfillment': function(){
            if(this.data.fulfillment_cexpress.items[8].all != undefined) { 
                if (this.data.fulfillment_cexpress.items[8].view != null) this.data.fulfillment_cexpress.items[8].view = this.data.fulfillment_cexpress.items[8].all;
                if (this.data.fulfillment_cexpress.items[8].process != undefined && this.data.fulfillment_cexpress.items[8].process != null) this.data.fulfillment_cexpress.items[8].process = this.data.fulfillment_cexpress.items[8].all;
                if (this.data.fulfillment_cexpress.items[8].edit != undefined && this.data.fulfillment_cexpress.items[8].edit != null) this.data.fulfillment_cexpress.items[8].edit = this.data.fulfillment_cexpress.items[8].all;
            }
        },
        'fulfillmentEonOrder': function() {
            if(this.data.fulfillment_eon.items[0].all != undefined) { 
                if (this.data.fulfillment_eon.items[0].view != null) this.data.fulfillment_eon.items[0].view = this.data.fulfillment_eon.items[0].all;
                if (this.data.fulfillment_eon.items[0].edit != null) this.data.fulfillment_eon.items[0].edit = this.data.fulfillment_eon.items[0].all;
                if (this.data.fulfillment_eon.items[0].process != undefined && this.data.fulfillment_eon.items[0].process != null) this.data.fulfillment_eon.items[0].process = this.data.fulfillment_eon.items[0].all;
            }
        },
        'fulfillmentEonPending': function() {
            if(this.data.fulfillment_eon.items[1].all != undefined) { 
                if (this.data.fulfillment_eon.items[1].view != null) this.data.fulfillment_eon.items[1].view = this.data.fulfillment_eon.items[1].all;
                if (this.data.fulfillment_eon.items[1].restore != undefined && this.data.fulfillment_eon.items[1].restore != null) this.data.fulfillment_eon.items[1].restore = this.data.fulfillment_eon.items[1].all;
            }
        },
        'fulfillmentEonProcessed': function() {
            if(this.data.fulfillment_eon.items[2].all != undefined) { 
                if (this.data.fulfillment_eon.items[2].view != null) this.data.fulfillment_eon.items[2].view = this.data.fulfillment_eon.items[2].all;
                if (this.data.fulfillment_eon.items[2].restore != undefined && this.data.fulfillment_eon.items[2].restore != null) this.data.fulfillment_eon.items[2].restore = this.data.fulfillment_eon.items[2].all;
            }
        },
        'fulfillmentEonReturned': function() {
            if(this.data.fulfillment_eon.items[3].all != undefined) { 
                if (this.data.fulfillment_eon.items[3].view != null) this.data.fulfillment_eon.items[3].view = this.data.fulfillment_eon.items[3].all;
                if (this.data.fulfillment_eon.items[3].process != undefined && this.data.fulfillment_eon.items[3].process != null) this.data.fulfillment_eon.items[3].process = this.data.fulfillment_eon.items[3].all;
                if (this.data.fulfillment_eon.items[3].restore != undefined && this.data.fulfillment_eon.items[3].restore != null) this.data.fulfillment_eon.items[3].restore = this.data.fulfillment_eon.items[3].all;
            }
        },
        'fulfillmentEonAdvancedFulfillment': function(){
            if(this.data.fulfillment_eon.items[4].all != undefined) { 
                if (this.data.fulfillment_eon.items[4].view != null) this.data.fulfillment_eon.items[4].view = this.data.fulfillment_eon.items[4].all;
                if (this.data.fulfillment_eon.items[4].process != undefined && this.data.fulfillment_eon.items[4].process != null) this.data.fulfillment_eon.items[4].process = this.data.fulfillment_eon.items[4].all;
                if (this.data.fulfillment_eon.items[4].edit != undefined && this.data.fulfillment_eon.items[4].edit != null) this.data.fulfillment_eon.items[4].edit = this.data.fulfillment_eon.items[4].all;
            }
        },
        'fulfillmentLeiremOrder': function() {
            if(this.data.fulfillment_leirem.items[0].all != undefined) { 
                if (this.data.fulfillment_leirem.items[0].view != null) this.data.fulfillment_leirem.items[0].view = this.data.fulfillment_leirem.items[0].all;
                if (this.data.fulfillment_leirem.items[0].edit != null) this.data.fulfillment_leirem.items[0].edit = this.data.fulfillment_leirem.items[0].all;
                if (this.data.fulfillment_leirem.items[0].process != undefined && this.data.fulfillment_leirem.items[0].process != null) this.data.fulfillment_leirem.items[0].process = this.data.fulfillment_leirem.items[0].all;
            }
        },
        'fulfillmentLeiremPending': function() {
            if(this.data.fulfillment_leirem.items[1].all != undefined) { 
                if (this.data.fulfillment_leirem.items[1].view != null) this.data.fulfillment_leirem.items[1].view = this.data.fulfillment_leirem.items[1].all;
                if (this.data.fulfillment_leirem.items[1].restore != undefined && this.data.fulfillment_leirem.items[1].restore != null) this.data.fulfillment_leirem.items[1].restore = this.data.fulfillment_leirem.items[1].all;
            }
        },
        'fulfillmentLeiremProcessed': function() {
            if(this.data.fulfillment_leirem.items[2].all != undefined) { 
                if (this.data.fulfillment_leirem.items[2].view != null) this.data.fulfillment_leirem.items[2].view = this.data.fulfillment_leirem.items[2].all;
                if (this.data.fulfillment_leirem.items[2].restore != undefined && this.data.fulfillment_leirem.items[2].restore != null) this.data.fulfillment_leirem.items[2].restore = this.data.fulfillment_leirem.items[2].all;
            }
        },
        'fulfillmentLeiremReturned': function() {
            if(this.data.fulfillment_leirem.items[3].all != undefined) { 
                if (this.data.fulfillment_leirem.items[3].view != null) this.data.fulfillment_leirem.items[3].view = this.data.fulfillment_leirem.items[3].all;
                if (this.data.fulfillment_leirem.items[3].process != undefined && this.data.fulfillment_leirem.items[3].process != null) this.data.fulfillment_leirem.items[3].process = this.data.fulfillment_leirem.items[3].all;
                if (this.data.fulfillment_leirem.items[3].restore != undefined && this.data.fulfillment_leirem.items[3].restore != null) this.data.fulfillment_leirem.items[3].restore = this.data.fulfillment_leirem.items[3].all;
            }
        },
        'fulfillmentLeiremAdvancedFulfillment': function(){
            if(this.data.fulfillment_leirem.items[4].all != undefined) { 
                if (this.data.fulfillment_leirem.items[4].view != null) this.data.fulfillment_leirem.items[4].view = this.data.fulfillment_leirem.items[4].all;
                if (this.data.fulfillment_leirem.items[4].process != undefined && this.data.fulfillment_leirem.items[4].process != null) this.data.fulfillment_leirem.items[4].process = this.data.fulfillment_leirem.items[4].all;
                if (this.data.fulfillment_leirem.items[4].edit != undefined && this.data.fulfillment_leirem.items[4].edit != null) this.data.fulfillment_leirem.items[4].edit = this.data.fulfillment_leirem.items[4].all;
            }
        },
        'catalogsWarehouse': function() {
            if(this.data.catalogs.items[0].all != undefined) { 
                if (this.data.catalogs.items[0].create != null) this.data.catalogs.items[0].create = this.data.catalogs.items[0].all;
                if (this.data.catalogs.items[0].view != null) this.data.catalogs.items[0].view = this.data.catalogs.items[0].all;
                if (this.data.catalogs.items[0].edit != null) this.data.catalogs.items[0].edit = this.data.catalogs.items[0].all;
                if (this.data.catalogs.items[0].delete != null) this.data.catalogs.items[0].delete = this.data.catalogs.items[0].all;
            }
        },
        'catalogsLocation': function() {
            if(this.data.catalogs.items[1].all != undefined) { 
                if (this.data.catalogs.items[1].create != null) this.data.catalogs.items[1].create = this.data.catalogs.items[1].all;
                if (this.data.catalogs.items[1].view != null) this.data.catalogs.items[1].view = this.data.catalogs.items[1].all;
                if (this.data.catalogs.items[1].edit != null) this.data.catalogs.items[1].edit = this.data.catalogs.items[1].all;
                if (this.data.catalogs.items[1].delete != null) this.data.catalogs.items[1].delete = this.data.catalogs.items[1].all;
            }
        },
        'catalogsGate': function() {
            if(this.data.catalogs.items[2].all != undefined) { 
                if (this.data.catalogs.items[2].create != null) this.data.catalogs.items[2].create = this.data.catalogs.items[2].all;
                if (this.data.catalogs.items[2].view != null) this.data.catalogs.items[2].view = this.data.catalogs.items[2].all;
                if (this.data.catalogs.items[2].edit != null) this.data.catalogs.items[2].edit = this.data.catalogs.items[2].all;
                if (this.data.catalogs.items[2].delete != null) this.data.catalogs.items[2].delete = this.data.catalogs.items[2].all;
            }
        },
        'catalogsBox': function() {
            if(this.data.catalogs.items[3].all != undefined) { 
                if (this.data.catalogs.items[3].create != null) this.data.catalogs.items[3].create = this.data.catalogs.items[3].all;
                if (this.data.catalogs.items[3].view != null) this.data.catalogs.items[3].view = this.data.catalogs.items[3].all;
                if (this.data.catalogs.items[3].edit != null) this.data.catalogs.items[3].edit = this.data.catalogs.items[3].all;
                if (this.data.catalogs.items[3].delete != null) this.data.catalogs.items[3].delete = this.data.catalogs.items[3].all;
            }
        },
        'catalogsCustomsBroker': function() {
            if(this.data.catalogs.items[4].all != undefined) { 
                if (this.data.catalogs.items[4].create != null) this.data.catalogs.items[4].create = this.data.catalogs.items[4].all;
                if (this.data.catalogs.items[4].view != null) this.data.catalogs.items[4].view = this.data.catalogs.items[4].all;
                if (this.data.catalogs.items[4].edit != null) this.data.catalogs.items[4].edit = this.data.catalogs.items[4].all;
                if (this.data.catalogs.items[4].delete != null) this.data.catalogs.items[4].delete = this.data.catalogs.items[4].all;
            }
        },
        'catalogsOriginSupplier': function() {
            if(this.data.catalogs.items[5].all != undefined) { 
                if (this.data.catalogs.items[5].create != null) this.data.catalogs.items[5].create = this.data.catalogs.items[5].all;
                if (this.data.catalogs.items[5].view != null) this.data.catalogs.items[5].view = this.data.catalogs.items[5].all;
                if (this.data.catalogs.items[5].edit != null) this.data.catalogs.items[5].edit = this.data.catalogs.items[5].all;
                if (this.data.catalogs.items[5].delete != null) this.data.catalogs.items[5].delete = this.data.catalogs.items[5].all;
            }
        },
        'catalogsServices': function() {
            if(this.data.catalogs.items[6].all != undefined) { 
                if (this.data.catalogs.items[6].create != null) this.data.catalogs.items[6].create = this.data.catalogs.items[6].all;
                if (this.data.catalogs.items[6].view != null) this.data.catalogs.items[6].view = this.data.catalogs.items[6].all;
                if (this.data.catalogs.items[6].edit != null) this.data.catalogs.items[6].edit = this.data.catalogs.items[6].all;
                if (this.data.catalogs.items[6].delete != null) this.data.catalogs.items[6].delete = this.data.catalogs.items[6].all;
            }
        },
        'catalogsConsumables': function(){
            if(this.data.catalogs.items[7].all != undefined) { 
                if (this.data.catalogs.items[7].create != null) this.data.catalogs.items[7].create = this.data.catalogs.items[7].all;
                if (this.data.catalogs.items[7].view != null) this.data.catalogs.items[7].view = this.data.catalogs.items[7].all;
                if (this.data.catalogs.items[7].edit != null) this.data.catalogs.items[7].edit = this.data.catalogs.items[7].all;
                if (this.data.catalogs.items[7].delete != null) this.data.catalogs.items[7].delete = this.data.catalogs.items[7].all;
            }
        },
        'accessRole': function() {
            if(this.data.access.items[0].all != undefined) { 
                if (this.data.access.items[0].create != null) this.data.access.items[0].create = this.data.access.items[0].all;
                if (this.data.access.items[0].view != null) this.data.access.items[0].view = this.data.access.items[0].all;
                if (this.data.access.items[0].edit != null) this.data.access.items[0].edit = this.data.access.items[0].all;
                if (this.data.access.items[0].delete != null) this.data.access.items[0].delete = this.data.access.items[0].all;
            }
        },
        'orders_pendings': function() {
            if(this.data.orders_pendings.items[0].all != undefined) { 
                if (this.data.orders_pendings.items[0].view != null) this.data.orders_pendings.items[0].view = this.data.orders_pendings.items[0].all;
                if (this.data.orders_pendings.items[0].edit != null) this.data.orders_pendings.items[0].edit = this.data.orders_pendings.items[0].all;
                if (this.data.orders_pendings.items[0].delete != null) this.data.orders_pendings.items[0].delete = this.data.orders_pendings.items[0].all;
            }
        },
        'orders_posible_fraud': function() {
            if(this.data.orders_pendings.items[1].all != undefined) { 
                if (this.data.orders_pendings.items[1].view != null) this.data.orders_pendings.items[1].view = this.data.orders_pendings.items[1].all;
                if (this.data.orders_pendings.items[1].edit != null) this.data.orders_pendings.items[1].edit = this.data.orders_pendings.items[1].all;
                if (this.data.orders_pendings.items[1].delete != null) this.data.orders_pendings.items[1].delete = this.data.orders_pendings.items[1].all;
            }
        },
        'external_stuck_orders': function() {
            if(this.data.orders_pendings.items[2].all != undefined) { 
                if (this.data.orders_pendings.items[2].view != null) this.data.orders_pendings.items[2].view = this.data.orders_pendings.items[2].all;
                if (this.data.orders_pendings.items[2].delete != null) this.data.orders_pendings.items[2].delete = this.data.orders_pendings.items[2].all;
            }
        },
        'accessUser': function() {
            if(this.data.access.items[1].all != undefined) { 
                if (this.data.access.items[1].create != null) this.data.access.items[1].create = this.data.access.items[1].all;
                if (this.data.access.items[1].view != null) this.data.access.items[1].view = this.data.access.items[1].all;
                if (this.data.access.items[1].edit != null) this.data.access.items[1].edit = this.data.access.items[1].all;
                if (this.data.access.items[1].delete != null) this.data.access.items[1].delete = this.data.access.items[1].all;
            }
        },
        'customer': function() {
            if(this.data.customers.items[0].view == true) {
                this.data.customers.items[1].view = false;
                this.data.customers.items[2].view = false;
                this.data.customers.items[4].view = false;
                this.data.customers.items[3].view = false;
            } 
        },
        'customer_fulfillment': function() {
            if(this.data.customers.items[1].view == true) {
                this.data.customers.items[0].view = false;
                this.data.customers.items[2].view = false;
                this.data.customers.items[4].view = false;
                this.data.customers.items[3].view = false;
            } 
        },
        'advanced_customer_fulfillment': function() {
            if(this.data.customers.items[2].view == true) {
                this.data.customers.items[0].view = false;
                this.data.customers.items[1].view = false;
                this.data.customers.items[4].view = false;
                this.data.customers.items[3].view = false;
            } 
        },
        'customer_fulfillment_eon': function() {
            if(this.data.customers.items[3].view == true) {
                this.data.customers.items[0].view = false;
                this.data.customers.items[1].view = false;
                this.data.customers.items[2].view = false;
                this.data.customers.items[4].view = false;
            } 
        },
        'customer_all_access': function() {
            if(this.data.customers.items[4].view == true) {
                this.data.customers.items[0].view = false;
                this.data.customers.items[1].view = false;
                this.data.customers.items[2].view = false;
                this.data.customers.items[3].view = false;
            } 
        },
        'customs_patch': function(){
            if(this.data.customs_patch.items[0].all != undefined) { 
                if (this.data.customs_patch.items[0].create != null) this.data.customs_patch.items[0].create = this.data.customs_patch.items[0].all;
                if (this.data.customs_patch.items[0].view != null) this.data.customs_patch.items[0].view = this.data.customs_patch.items[0].all;
                if (this.data.customs_patch.items[0].delete != null) this.data.customs_patch.items[0].delete = this.data.customs_patch.items[0].all;
            }
        },
        'visitors': function(){
            if(this.data.entrance.items[0].all != undefined) { 
                if (this.data.entrance.items[0].create != null) this.data.entrance.items[0].create = this.data.entrance.items[0].all;
                if (this.data.entrance.items[0].view != null) this.data.entrance.items[0].view = this.data.entrance.items[0].all;
                if (this.data.entrance.items[0].edit != null) this.data.entrance.items[0].edit = this.data.entrance.items[0].all;
                if (this.data.entrance.items[0].delete != null) this.data.entrance.items[0].delete = this.data.entrance.items[0].all;
                if (this.data.entrance.items[0].qr != null) this.data.entrance.items[0].qr = this.data.entrance.items[0].all;
                if (this.data.entrance.items[0].gafete != null) this.data.entrance.items[0].gafete = this.data.entrance.items[0].all;
            }
        },
        'carriers': function(){
            if(this.data.entrance.items[1].all != undefined) { 
                if (this.data.entrance.items[1].create != null) this.data.entrance.items[1].create = this.data.entrance.items[1].all;
                if (this.data.entrance.items[1].view != null) this.data.entrance.items[1].view = this.data.entrance.items[1].all;
                if (this.data.entrance.items[1].edit != null) this.data.entrance.items[1].edit = this.data.entrance.items[1].all;
                if (this.data.entrance.items[1].delete != null) this.data.entrance.items[1].delete = this.data.entrance.items[1].all;
            }
        },
        'attendances': function(){
            if(this.data.entrance.items[2].all != undefined) { 
                if (this.data.entrance.items[2].create != null) this.data.entrance.items[2].create = this.data.entrance.items[2].all;
                if (this.data.entrance.items[2].view != null) this.data.entrance.items[2].view = this.data.entrance.items[2].all;
                if (this.data.entrance.items[2].delete != null) this.data.entrance.items[2].delete = this.data.entrance.items[2].all;
            }
        }
    }
}
</script>

<style lang="css">
</style>