<template>
	<v-row justify="center" align="center" class="mx-8">
		<v-container>
			<v-list lines="two" subheader>
				<v-row>
					<span class="mt-0 py-0 mx-4">{{ $t('edit_appointment.rfid.label_tags') }}:</span>
				</v-row>
				<v-row class="my-0 py-0">
					<v-col cols="2">
						<v-checkbox v-model="selected.opcions.pallets" color="amarillo" :label="$t('edit_appointment.rfid.pallets')"></v-checkbox>
					</v-col>
					<v-col cols="2" v-if="tag_per_product">
						<v-checkbox v-model="selected.opcions.tags" color="amarillo" :label="$t('edit_appointment.rfid.units')"></v-checkbox>
					</v-col>

					<v-col cols="5" justify="left" align="left">
						<v-row class="px-0 pb-2 my-2" justify="center" align="center">
							<span class="mt-2 mr-4 py-0" style="color: #555">{{ $t('edit_appointment.rfid.label_type') }}:</span>
							<v-btn :class="style_left" style="min-width: 90px;" v-bind:elevation="selected.opcions.rfid_icon == false ? 3 : 0" v-bind:color="selected.opcions.rfid_icon == false ? 'amarillo' : '#e4e4e4'" @click="(selected.opcions.rfid_icon = false)">{{ $t('edit_appointment.rfid.regular') }}</v-btn>
							<v-btn :class="style_right" style="min-width: 90px;" v-bind:elevation="selected.opcions.rfid_icon == false ? 0 : 3" v-bind:color="selected.opcions.rfid_icon == true ? 'amarillo' : '#e4e4e4'" @click="(selected.opcions.rfid_icon = true)">{{ $t('edit_appointment.rfid.rfid') }}</v-btn>
						</v-row>
					</v-col>
				</v-row>
				<v-row class="my-0 py-0">
					<v-col cols="4">
						<v-select :label="$t('edit_appointment.rfid.printer')" :items="printers" v-model="printer_selected"></v-select>
					</v-col>
					<v-col cols="2">
						<v-btn class="white--text" color="amarillo" @click="print()"> {{ $t('edit_appointment.rfid.print') }} </v-btn>
					</v-col>
					<v-col cols="1" v-permission="'generate_epcs.create'">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn 
									v-on="on" 
									class="right white--text mr-2" 
									outlined 
									color="amarillo" 
									@click="generateTags()" 
									v-if="(appointment_id != '' && appointment_id != undefined) || (product_id != '' && product_id != undefined) || (location_id != '' && location_id != undefined)" 
									style="max-width: 40px !important; min-width: 40px !important"> 
									<v-icon class="material-icons-outlined" small>refresh</v-icon> 
								</v-btn>
							</template>
							<span>{{$t('rfid.refresh')}}</span>
						</v-tooltip>
					</v-col>
				</v-row>
			</v-list>
			<v-divider></v-divider>
			<v-list lines="two" subheader>
				<v-tabs v-model="tab" background-color="transparent" color="basil" grow>
					<v-tab v-for="t in tabs" :key="t">
						{{ t }}
					</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item :key="$t('edit_appointment.rfid.pallet')">
						<v-card>
							<v-data-table :headers="headers_pallets" :items="items.pallets" item-key="id" show-select
								v-model="selected.pallets" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
								class="elevation-1"></v-data-table>
						</v-card>
					</v-tab-item>
					<v-tab-item :key="$t('edit_appointment.rfid.units')">
						<v-card>
							<v-data-table :headers="headers_tags" :items="items.tags" item-key="id" show-select
								v-model="selected.tags" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
								class="elevation-1"></v-data-table>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-list>
		</v-container>
	</v-row>
</template>

<script>
export default {
	props: [
		'items',
		'tag_per_product',
		'appointment_id', // Id to generate tags by appointment
		'product_id', // Id to generate tags by product
		'location_id' // Id to generate tags by location
	],
	data() {
		return {
			rfid_dialog: true,
			tab: null,
			tabs: [this.$t('edit_appointment.rfid.pallets'), this.$t('edit_appointment.rfid.units')],
			selected: {
				pallets: [],
				boxes: [],
				tags: [],
				opcions: {
					pallets: false,
					boxes: false,
					tags: false,
					only_new: false,
					rfid_icon: false
				}
			},
			style_left: "radio-left mt-1 white--text",
			style_right: "radio-right mt-1 switch_grey--text",
			printers: [],
			printer_selected: "",
		}
	},
	computed: {
		headers_pallets() {
			return [
				{ text: 'ID', align: "center", value: 'id', },
				{ text: 'EPC', align: "center", value: 'epc', },
				{ text: this.$t('edit_appointment.rfid.pallet'), align: "center", value: 'pallet', },
				{ text: this.$t('edit_appointment.rfid.location'), align: "center", value: 'ubicacion', },
				// { text: '# Cajas', align: "center", value: 'cajas', },
				{ text: "#" + this.$t('edit_appointment.rfid.units'), align: "center", value: 'piezas', },
			]
		},
		headers_tags() {
			return [
				{ text: 'ID', align: "center", value: 'id', },
				{ text: 'EPC', align: "center", value: 'epc', },
				{ text: this.$t('edit_appointment.rfid.product'), align: "center", value: 'product', },
				{ text: this.$t('edit_appointment.rfid.batch'), align: "center", value: 'batch', },
				{ text: this.$t('edit_appointment.rfid.expiration'), align: "center", value: 'reference', },
				{ text: this.$t('edit_appointment.rfid.location'), align: "center", value: 'location', },
				{ text: this.$t('edit_appointment.rfid.pallet'), align: "center", value: 'pallet', },
				// { text: 'Caja', align: "center", value: 'box', },
			]
		},
	},
	mounted() {
		this.getPrinters();
	},
	methods: {
		getPrinters() {
			this.$http.get(this.$store.state.printerServiceApi + 'printers/')
				.then((response) => {
					this.printers = response.data.map(p => { return p.name })
				}).catch(error => console.log(error))
		},
		show() {
			this.rfid_dialog = true
		},
		hide() {
			this.rfid_dialog = false
		},
		print() {
			var t = this;
			this.$store.state.loaderProps.visible = true;
			this.$http.post(this.$store.state.chronosApi + 'rfid/get_tags', this.selected)
				.then((response) => {
					// console.log("aqui es donde se imprime")
					var print_info = {
						printer: t.printer_selected,
						data: {
							pallets: response.data.items.pallets,
							details: response.data.items.boxes,
							products: response.data.items.products,
						},
						print_type: {
							tag: t.selected.opcions.tags,
							box: t.selected.opcions.boxes,
							pallet: t.selected.opcions.pallets,
							rfid_icon: t.selected.opcions.rfid_icon
						}
					}
					// console.log(print_info)
					this.$http.post(this.$store.state.printerServiceApi + 'print/', print_info)
						.then((response) => {
							response
							// console.log("aqui es donde se imprime")
							this.$store.state.loaderProps.visible = false;
							// console.log(response)
						}).catch(error => console.log(error))
				}).catch(error => {
					this.$store.state.loaderProps.visible = false;
					console.log(error)
				})
		},
		async generateTags(){
			this.$store.state.loaderProps.visible = true;
            var t = this
            const lsToken = localStorage.getItem("access_token");
			var url = "";
			if(t.appointment_id != undefined && t.appointment_id != null) url = this.$store.state.chronosApi + 'rfid/appointment/' + t.appointment_id
			else if(t.product_id != undefined && t.product_id != null) {
				await t.updateProductTags();
				url = this.$store.state.chronosApi + 'rfid/product/' + t.product_id;
			}
			else if(t.location_id != undefined && t.location_id != null) {
				await t.updateLocationTags();
				url = this.$store.state.chronosApi + 'rfid/location/' + t.location_id;
			}

            this.$http.get(url, {headers: {Authorization: lsToken}})
            .then(response => {
                t.items = response.data.items;
				this.$store.state.loaderProps.visible = false;
            })
            .catch(err => {console.log(err)})
            .finally(()=>{this.$store.state.loaderProps.visible = false;})
		},
		async updateProductTags(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const result = await this.$http.get(this.$store.state.chronosApi + 'rfid/generate_tags_by_product?product_id=' + this.product_id, {headers: {Authorization: lsToken}})
            result;
        },
        async updateLocationTags(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const result = await this.$http.get(this.$store.state.chronosApi + 'rfid/generate_tags_by_location?location_id=' + this.location_id, {headers: {Authorization: lsToken}})
            result;
        }
	},
	watch: {
		"selected.opcions.rfid_icon": function(){
			if(this.selected.opcions.rfid_icon == false){
				this.style_left = "radio-left mt-1 white--text";
				this.style_right = "radio-right mt-1 switch_grey--text";
			}
			else {
				this.style_left = "radio-left mt-1 switch_grey--text";
				this.style_right = "radio-right mt-1 white--text";
			}
		}
	}
}
</script>

<style></style>