<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-0 size">
                <v-card-title class="justify-center">
                    <h2 class="text-uppercase azul--text">{{ $t('new_order.new_order') }}</h2>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.unique_order_number" :label="$t('new_order.uon')" :rules="rules_uon" counter="20"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-autocomplete v-model="form.shipping_service" item-text="text" item-value="value" :label="$t('new_order.shipping_service')" :rules="rules_required" :items="services"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-autocomplete v-model="cod" item-text="text" item-value="value" :label="$t('new_order.order_type')" :rules="rules_required" :items="cod_types"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.name" :label="$t('new_order.full_name')" :rules="consignee_name" counter="35"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.phone" :label="$t('new_order.phone')" counter="10" :rules="consignee_phone"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.email" :label="$t('new_order.email')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.invoice" :label="$t('new_order.invoice')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.zipcode" counter="5" :label="$t('new_order.zip_code')" :rules="consignee_zipcode"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="is_local">
                            <v-col cols="6" justify="center" align="start">
                                <v-label>{{ $t('new_order.received') }}: {{receivedNeighborhood}}</v-label>
                                <v-autocomplete v-model="form.consignee.neighborhood" :label="$t('new_order.neigborhood')" :rules="rules_required" :items="neighborhoods"></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row align="center" justify="center" v-else>
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.neighborhood" :label="$t('new_order.neigborhood')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="form.consignee.country != 'United States'">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.street" :label="$t('new_order.street')" counter="24" :rules="consignee_street_mexico"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-else>
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.street" :label="$t('new_order.address')" counter="34" :rules="consignee_street_usa"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="form.consignee.country != 'United States'">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.external_number" :label="$t('new_order.external_number')" counter="5" :rules="is_local ? consignee_external_number : []"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="form.consignee.country != 'United States'">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.internal_number" :label="$t('new_order.internal_number')" :rules="consignee_internal_number" counter="5"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.municipality" :label="$t('new_order.municipality')" :rules="rules_required" disabled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.state" :label="$t('new_order.state')" :rules="rules_required" disabled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.comments" :label="$t('new_order.address_reference')"  counter="255"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-text class="pt-0">
                    <v-form ref="productForm" lazy-validation @submit.prevent="save">
                        <v-row align="center" justify="center">
                            <v-col class="py-0" cols="6" justify="center" align="center">
                                <v-text-field v-model="form.total_price" :label="$t('new_order.total_cost')" :disabled="cod == 'regular'"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row align="center" justify="center">
                        <v-col cols="4" justify="center" align="center">
                            <v-autocomplete :items="items" v-model="product" item-value="product_id" item-text="sku" :label="$t('new_order.product')"></v-autocomplete>
                        </v-col>
                        <v-col cols="2" justify="end" align="end">
                            <v-btn color="amarillo" class="white--text" @click="addProduct">{{ $t('new_order.add') }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center" v-for="(order, index) in form.order_details" :key="index">
                        <v-col cols="3" justify="start" align="start">
                            {{ order.sku }}
                        </v-col>
                        <v-col cols="2" justify="center" align="center">
                            <v-text-field hide-details :label="$t('new_order.quantity')" v-model="order.quantity" type="number"></v-text-field>
                        </v-col>
                        <v-col cols="1" justify="end" align="end">
                            <v-btn color="red" icon small @click="removeProduct(index)"><v-icon>remove_circle_outline</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text>
                    <v-row align="center" justify="center">
                        <v-col cols="6">
                            <v-row justify="end" align="center">
                                <v-btn class="mr-2" text @click="cancel">{{ $t('new_order.cancel') }}</v-btn>
                                <v-btn color="amarillo" class="white--text" @click="save">{{ $t('new_order.create_order') }}</v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    computed:{
        rules_uon(){
            return [
                v => !!v || this.$t('new_order.validations.required_field'),
                v => v.toString().length >= 6 && v.toString().length <= 20 || this.$t('new_order.validations.uon'),
            ];
        },       
        rules_tn() {
            return [
                v => !!v || this.$t('new_order.validations.required_field'),
            ]
        },       
        consignee_name() {
            return [
                v => !!v || this.$t('new_order.validations.required_field'),
                v => v.toString().length >= 3 && v.toString().length <= 35 || this.$t('new_order.validations.name'),
            ];
        },
        consignee_phone() {
            return [
                v => v.toString().length === 10 || this.$t('new_order.validations.phone'),
                v => /^(\d*\.)?\d+$/.test(v) || this.$t('new_order.validations.numbers'),
            ]
        },
        consignee_street_mexico(){
            return [
                v => !!v || this.$t('new_order.validations.required_field'),
                v => v.toString().length <= 24 || this.$t('new_order.validations.street_mx'),
            ];
        }, 
        consignee_street_usa(){
            return [
                v => !!v || this.$t('new_order.validations.required_field'),
                v => v.toString().length <= 34 || this.$t('new_order.validations.street_usa'),
            ];
        }, 
        consignee_external_number(){
            return [
                v => !!v || this.$t('new_order.validations.required_field'),
                v => v.length <= 5 || this.$t('new_order.validations.external_number'),
            ];
        },
        consignee_internal_number(){
            return [
                v => v.toString().length <= 5 || this.$t('new_order.validations.internal_number'),
            ];
        },
        consignee_zipcode(){
            return [
                v => v.toString().length === 5 || this.$t('new_order.validations.zip_code'),
                v => /^(\d*\.)?\d+$/.test(v) || this.$t('new_order.validations.numbers'),
            ];
        },
        consignee_comments(){ 
            return [
                v => (v.toString().length == 0 || (v.toString().length >= 3 && v.toString().length <= 255)) || this.$t('new_order.validations.comments'),
            ]
        }, 
        rules_required(){return [v => !!v || this.$t('new_order.validations.required_field')]}, 
        rules_email(){
            return [v => (v.length == 0 || /.+@.+\..+/.test(v)) || this.$t('new_order.validations.email')];
        },
        r_total_price(){
            return [
                v => !!v || this.$t('new_order.validations.total'),
                v => /^(\d*\.)?\d+$/.test(v) || this.$t('new_order.validations.numbers'),
                v => parseFloat(v) <= 8000 || this.$t('new_order.validations.total_limit'),
            ];
        } 
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            loadingData: false,
            keepPage: false,
            cod: 'cod',
            token: '',
            neighborhoods:[],
            neighborhoodsData: [],
            receivedNeighborhood: '',
            form: {
                date: "",
                cod: true,
                unique_order_number: "",
                shipping_service: "FEDEX",
                invoice: "---",
                warehouse_id: this.$store.state.warehouseId,
                channel: "",
                consignee: {
                    name: "",
                    country: "Mexico",
                    state: "",
                    municipality:"",
                    neighborhood: "",
                    street: "",
                    external_number: "",
                    internal_number: "",
                    zipcode: "",
                    email: '',
                    phone: "",
                    comments: "",
                },
                delivery_date: "",
                order_details: [],
                total_price: ""
            },
            product: {
                quantity: '',
                sku: '',
                price: ''
            },
            cod_types: [{value: "cod", text: this.$t("new_order.cod")}, {value: "prepaid", text: this.$t("new_order.prepaid")}],
            items: [],
            products: [],
            clientId: '',
            client: "",
            bearer: "",
            is_local: true,
            services: [{value: "FEDEX", text: "FeDex"}, {value: "MIN", text: "99Minutos"}, {value: "YEGO", text: "Yego"}, {value: "QUIKEN", text: "Quiken"}, {value: "UPS", text: "UPS"}]
        }
    },
    mounted(){
        this.index();
        this.calcDates();
        this.$store.state.module = "headers.create_order";
    },
    methods: {
        index(){
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'orders_pendings/' + this.id, { headers: {Authorization: lsToken} })
            .then((response) => {
                response.data.data = JSON.parse(response.data.data);
                if(response.data.data.consignee.country != "Mexico" && response.data.data.consignee.country != "MX") this.is_local = false;

                this.getClientToken(response.data.client);
                this.form.date = response.data.data.date;
                if(response.data.data.invoice != null) this.form.invoice = response.data.data.invoice;
                this.form.cod = response.data.data.cod;
                this.form.channel = response.data.channel;
                this.form.unique_order_number = response.data.data.unique_order_number;
                this.form.shipping_service = response.data.data.shipping_service;
                this.form.total_price = response.data.data.total_price;
                this.form.consignee = response.data.data.consignee;
                this.form.order_details = response.data.data.order_details;
                this.receivedNeighborhood = response.data.data.consignee.neighborhood;
                if(response.data.data.consignee.email == undefined || response.data.data.consignee.email == null) this.form.consignee.email = "";
            })
            .catch((error) => {
                error
            })
        },
        getProducts(token){
            const lsToken = localStorage.getItem("access_token");
            this.items = [];
            this.$http.get(this.$store.state.chronosApi + 'products/customer/' + token, { headers: {Authorization: lsToken} })
            .then((response) => {
                response.data.products.data.forEach(x => {
                    x = x.attributes;
                    this.items.push({
                        sku: x.sku,
                        id: x.id
                    });
                })

                response.data.kits.forEach(x => {
                    this.items.push({
                        sku
                    })
                })
            })
            .catch((error) => {
                error
            })
        },
        addProduct(){
            if(this.product !== ''){
                const find = this.form.order_details.findIndex(x => x.sku === this.product);
                const product = this.items.find( x => x.sku == this.product);

                if(find < 0 && product != undefined && product != null){
                    this.form.order_details.push({
                        quantity: 1,
                        sku: product.sku,
                        product_id: product.id,
                        unit_price: 1
                    })
                }
            }
        },
        cancel(){
            this.$router.go(-1);
        },
        getClientToken(token){            
            this.getProducts(token);

            this.$http.get(this.$store.state.chronosApi + 'getCurrenAvailableToken/' + token)
            .then(response2 => {
                if(response2.data.token != "---"){
                    this.bearer = "Bearer " + response2.data.token;
                }
                else if(response2.data.token == "---"){
                    if(this.client != null && this.client.token != ""){
                        this.$http.post(this.$store.state.chronosApi + 'token/generate', {email: "alejandro@intech.mx", password: "afuerzaniloscalcetines:" + token}, {headers:{}})
                        .then( response3 => {
                            this.bearer = "Bearer " + response3.data.token;
                        })
                    }
                }
            })
        },
        removeProduct(index){
            this.form.order_details.splice(index, 1)
        },
        calcDates(){
            const date = new Date()
            const delivery_date = new Date()
            delivery_date.setDate(delivery_date.getDate() + 5)
            
            this.form.date = date.getFullYear()+'-'+(date.getMonth() + 1).toString().padStart(2, 0)+'-'+date.getDate().toString().padStart(2, 0)
            this.form.delivery_date = delivery_date.getFullYear()+'-'+(delivery_date.getMonth() + 1).toString().padStart(2, 0)+'-'+delivery_date.getDate().toString().padStart(2, 0)
        },
        save(){
            if(this.bearer != ""){
                const formVal = this.$refs.form.validate()
                const prodFormVal = this.$refs.productForm.validate();
                if(formVal && prodFormVal){
                    this.$store.state.loaderProps.visible = true;
                    if(this.form.total_price == 0 || this.form.total_price.toString().trim() == "") this.form.total_price = null;
                    if(this.form.consignee.internal_number == null) this.form.consignee.internal_number = "";
                    if(this.form.consignee.comments == null) this.form.consignee.comments = "";
                    if(this.form.order_details.length > 0){
                        let count = 0;
                        this.form.order_details.forEach(x =>{
                            count += parseInt(x.quantity);
                        })
                        this.form.order_details.forEach(x =>{
                            x.unit_price = this.form.total_price / count;
                        })
                        this.form.consignee.neighborhood = this.form.consignee.neighborhood.substring(0,34);
                        this.form.cod = (this.cod == "prepaid" ? false : true);
                        console.log(this.form);
                        this.$http.post(this.$store.state.chronosApi+'shipment/create', this.form, { headers: { Authorization: this.bearer } })
                        .then((response) => {
                            if(response.data.code === 200){
                                this.snackbar = {
                                    visible: true,
                                    color: 'success2',
                                    text: 'Orden creada con éxito'
                                }
                                this.$http.put(this.$store.state.chronosApi+'orders_pendings_update', { "uon": this.form.unique_order_number }, { headers: { Authorization: this.bearer } })
                                .then((response2) => {
                                    if(response2.status === 200){
                                        console.log("Updated: " + this.form.unique_order_number);
                                    }
                                    this.$store.state.loaderProps.visible = false;
                                })

                                this.$store.state.loaderProps.visible = false;
                                this.$router.go(-1);
                            }
                        })
                        .catch((error) => {                                
                            this.$store.state.loaderProps.visible = false;
                            this.snackbar = {
                                visible: true,
                                color: 'red',
                                text: error.response.data.message
                            }
                        })
                    }else{
                        this.$store.state.loaderProps.visible = false
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: 'Add at least 1 product'
                        }
                    }
                }else{
                    this.$store.state.loaderProps.visible = false
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: 'Form incomplete'
                    }
                }
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: 'Error gettin client data'
                }
            }
        }
    },
    watch: {
        '$store.state.loggedUserClientCode': function() {
            this.index();
        },
        'form.consignee.zipcode': function(){
            if(this.is_local) {
                this.neighborhoods = [];
                this.form.consignee.state = '';
                this.form.consignee.municipality = '';
                if(this.form.consignee.zipcode.length == 5){
                    this.$http.get('https://locations.intech.mx/api/neighborhoods/zip_code/' + this.form.consignee.zipcode).then(response =>{
                        response.data.forEach(x => {
                            this.neighborhoods.push(x.name);
                            this.neighborhoodsData.push(x);
                        })

                    })
                }
            }
        },
        'form.consignee.neighborhood': function(){
            if(this.is_local) {
                this.neighborhoodsData.forEach(x =>{
                    if(x.name == this.form.consignee.neighborhood){
                        this.$http.get("https://locations.intech.mx/api/getCityDetails/" + x.city_id).then(response =>{
                            this.form.consignee.municipality = response.data.city.name;
                            this.form.consignee.state = response.data.state.alt_names[0];
                        });
                    }
                })
            }
        },
        city_model(value){
            this.municipality_model = ''
            const selected = this.cities.find(x => x.value === value)
            this.form.consignee.state = selected.text

            this.getMunicipalities()
        },
        municipality_model(value){
            const selected = this.municipalities.find(x => x.value === value)
            this.form.consignee.municipality = typeof selected !== 'undefined' ? selected.text : ''
        }
    }
}
</script>

<style>

</style>
