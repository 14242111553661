<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('fulfillments_cod_pendings.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="modalBulk = true;">
                        {{ $t('fulfillments_cod_pendings.bulk_supply') }}
                        <v-icon class="px-1" small>add</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <v-data-table class="px-0 my-2"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                :footer-props="{
                                    'items-per-page-text': this.$t('datatable.shown'),
                                    showFirstLastPage: true,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2} ' + this.$t('datatable.results'),
                                    'show-current-page': true,
                                    'items-per-page-options': typeof props.rowsPP === 'undefined' ? [10, 25, 50, 100] : props.rowsPP}"
                                :page="1"
                                :mobile-breakpoint="0"
                                item-key="id"
                                show-select
                                v-model="selected"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                            <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ action.name }}</span>
                                    </v-tooltip>
                                </template>
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalSupply" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillments_cod_pendings.order_supply') }}: {{ this.orderToShow.uon }}
                            </h4>
                        </v-row>
                    </v-card-title>

                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_cod_pendings.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.order_number") }}</label> <label>{{ this.orderToShow.uniqueOrderNumber }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.date") }}</label> <label>{{ this.orderToShow.createdAt }}</label></div>
                    <div class="detail_content1" v-if="shipping_service != 'otra'"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.shipping_service") }}</label> <label>{{ this.orderToShow.shippingService }}</label></div>
                    <div class="detail_content1" v-if="shipping_service == 'otra'"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.shipping_service") }}</label> <label>{{ this.orderToShow.externalShippingService }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.invoice") }}</label> <label>{{ this.orderToShow.invoice }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.client != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.client") }}</label> <label>{{ this.orderToShow.client.name }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.cod") }}</label> <label>{{ this.orderToShow.total }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.buyer") }}</label> <label>{{ this.orderToShow.consignee.name }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.state") }}</label> <label>{{ this.orderToShow.consignee.state }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.municipality") }}</label> <label>{{ this.orderToShow.consignee.municipality }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.neighborhood") }}</label> <label>{{ this.orderToShow.consignee.neighborhood }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.street") }}</label> <label>{{ this.orderToShow.consignee.street }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.zip_code") }}</label> <label>{{ this.orderToShow.consignee.zipcode }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_cod_pendings.products') }}</label></div>
                    <span v-if="orderToShow.shipmentProductDetail != null">
                        <div v-for="(product, i) of orderToShow.shipmentProductDetail.data" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.attributes.product.sku }}</label> <label>{{ product.attributes.quantity }}</label></div>
                    </span>
                    
                    <div class="marker1-1 mt-3" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_cod_pendings.details') }}</label></div>
                    <v-row class="px-7 py-0 mt-3" justify="center" align="center">
                        <v-col cols="12" class="py-0">
                            <v-autocomplete class="mt-0 py-0" v-model="selected_box" :items="boxes" item-value="id" item-text="name" :label="$t('fulfillments_cod_pendings.box')" :rules="rules_required"></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalSupply = false">{{ $t('fulfillments_cod_pendings.go_back') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="orderSupply(orderToShow)">
                                {{ $t('fulfillments_cod_pendings.supply') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('fulfillments_cod_pendings.modal.delete') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('fulfillments_cod_pendings.modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('fulfillments_cod_pendings.no') }}</v-btn>
                            <v-btn color="error" @click="deleteOrder">
                                {{ $t('fulfillments_cod_pendings.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalBulk" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillments_cod_pendings.fill_orders') }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_cod_pendings.orders') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ this.selected.length }} {{ $t("fulfillments_cod_pendings.orders_to_fill") }}</label></div>
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_cod_pendings.select_box') }}</label></div>

                    <v-row class="px-7 py-0 mt-4" justify="center" align="center">
                        <v-col cols="12" class="py-0">
                            <v-autocomplete class="mt-0 py-0" v-model="selected_box" :items="boxes" item-value="id" item-text="name" :label="$t('fulfillments_cod_pendings.box')" :rules="rules_required"></v-autocomplete>
                        </v-col>
                    </v-row>
                    
                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalBulk = false">{{ $t('fulfillments_cod_pendings.go_back') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="bulkSupply">
                                {{ $t('fulfillments_cod_pendings.supply') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalBulkResponses" persistent max-width="90%">
                <v-card>
                    <v-card-text>
                        <v-card flat class="mb-5">
                            <v-card-text class="py-0 my-0">
                                <v-card-title class="justify-center">
                                    <v-row align="center" justify="center">
                                        <h4 class="text-uppercase azul--text py-2">
                                            {{ $t('fulfillments_cod_pendings.summary_supply') }}
                                        </h4>
                                    </v-row>
                                </v-card-title>
                                
                                <p>{{bulkProps.items.length}} {{ $t('fulfillments_cod_pendings.results') }}</p>
                            </v-card-text>
                            <v-simple-table v-if="bulkProps.items.length > 0" fixed-header height="300px">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th v-for="(th, key) in [$t('fulfillments_cod_pendings.order'), $t('fulfillments_cod_pendings.message')]" :key="key" class="text-left">{{ th }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, i) in bulkProps.items" :key="i">
                                            <td>{{ item.order }}</td>
                                            <!-- <td>{{ item.status }}</td> -->
                                            <td>{{ item.message }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>

                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn color="amarillo" class="white--text" @click="modalBulkResponses = false; index();">
                                {{ $t('fulfillments_cod_pendings.accept') }}
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillments_cod_pendings.order_details.header') }} {{ this.orderToShow.uniqueOrderNumber }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_cod_pendings.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.order_number") }}</label> <label>{{ this.orderToShow.uniqueOrderNumber }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.date") }}</label> <label>{{ this.orderToShow.createdAt }}</label></div>
                    <div class="detail_content1" v-if="shipping_service != 'otra'"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.shipping_service") }}</label> <label>{{ this.orderToShow.shippingService }}</label></div>
                    <div class="detail_content1" v-if="shipping_service == 'otra'"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.shipping_service") }}</label> <label>{{ this.orderToShow.externalShippingService }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.invoice") }}</label> <label>{{ this.orderToShow.invoice }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.client != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.client") }}</label> <label>{{ this.orderToShow.client.name }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.cod") }}</label> <label>{{ this.orderToShow.total }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.buyer") }}</label> <label>{{ this.orderToShow.consignee.name }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.state") }}</label> <label>{{ this.orderToShow.consignee.state }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.municipality") }}</label> <label>{{ this.orderToShow.consignee.municipality }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.neighborhood") }}</label> <label>{{ this.orderToShow.consignee.neighborhood }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.street") }}</label> <label>{{ this.orderToShow.consignee.street }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.zip_code") }}</label> <label>{{ this.orderToShow.consignee.zipcode }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_cod_pendings.products') }}</label></div>
                    <span v-if="orderToShow.shipmentProductDetail != null">
                        <div v-for="(product, i) of orderToShow.shipmentProductDetail.data" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.attributes.product.sku }}</label> <label>{{ product.attributes.quantity }}</label></div>
                    </span>

                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDetail = false">{{ $t('fulfillments_cod_pendings.go_back') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="orderSupply(orderToShow)">
                                {{ $t('fulfillments_cod_pendings.supply') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalAlreadyOpen" persistent max-width="90%">
                <v-card >
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h3>{{ $t('fulfillments_cod_pendings.page_already_open_1') }} {{ shipping_service }} {{ $t('fulfillments_cod_pendings.page_already_open_2') }}</h3>
                            </v-col>
                        </v-row>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalParcel" persistent max-width="90%">
                <v-card >
                    <v-card-text>
                        <v-row align="center" justify="center" class="pt-3 mt-3 text-center">
                            <v-col cols="12">
                                <h3>{{ $t('fulfillments_cod_pendings.change_parcel') }}</h3>
                            </v-col>
                        </v-row>
                        <v-row justify="center" align="center">
                            <v-col cols="10">
                                <div><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.order") }}</label> <label>{{ this.shipmentParcel.uniqueOrderNumber }}</label></div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" align="center">
                            <v-col cols="10">
                                <v-autocomplete class="mt-0 py-0" v-model="newParcel" :items="parcels" :label="$t('fulfillments_cod_pendings.select_parcel')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="cancelUpdateParcel()">{{ $t('fulfillments_prepaid_returns.cancel') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="updateParcel()">
                                {{ $t('fulfillments_prepaid_returns.save') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        </v-container>   
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
	computed: {
        headers(){
            if(this.shipping_service != "otra"){
                return [
                    { text: this.$t('fulfillments_cod_pendings.order'), value: 'uniqueOrderNumber' },
                    { text: this.$t('fulfillments_cod_pendings.client'), value: 'client.name' },
                    { text: this.$t('fulfillments_cod_pendings.date'), value: 'createdAt' },
                    { text: this.$t('fulfillments_cod_pendings.time'), value: 'time' },
                    { text: this.$t('fulfillments_cod_pendings.cod'), value: 'total' },
                    { text: this.$t('fulfillments_cod_pendings.actions'), value: 'actions' },
                ];
            }
            else{
                return [
                    { text: this.$t('fulfillments_cod_pendings.order'), value: 'uniqueOrderNumber' },
                    { text: this.$t('fulfillments_cod_pendings.client'), value: 'client.name' },
                    { text: this.$t('fulfillments_cod_pendings.date'), value: 'createdAt' },
                    { text: this.$t('fulfillments_cod_pendings.time'), value: 'time' },
                    { text: this.$t('fulfillments_cod_pendings.cod'), value: 'total' },
                    { text: this.$t('fulfillments_cod_pendings.shipping_service'), value: 'externalShippingService' },
                    { text: this.$t('fulfillments_cod_pendings.actions'), value: 'actions' },
                ];
            }
        },
        headersBulk(){
            return [
                { text: this.$t('fulfillments_cod_pendings.order'), value: 'order' },
                { text: this.$t('fulfillments_cod_pendings.status'), value: 'status' },
                { text: this.$t('fulfillments_cod_pendings.message'), value: 'message' }
            ];
        },
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            shipping_service: this.$route.params.shipping_service,
            sending_data: false,
            props: {
                search: '',
                items: [
                ],
            },
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            selected: [],
            modalDelete: false,
            modalDetail: false,
            modalBulk: false,
            modalAlreadyOpen: false,
            modalBulkResponses: false,
            selected_box: "",
            boxes: [],
            modalSupply: false,
            orderToDelete: '',
            orderToShow: '',
            actions:[
                {
                    name: this.$t('fulfillments_cod_pendings.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                },
                {
                    name: this.$t('fulfillments_cod_pendings.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'mode_edit'
                    },
                    link: '/pending-shipments/cod/edit/10000',
                },
                {
                    name: this.$t('fulfillments_cod_pendings.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                },
                {
                    name: this.$t('fulfillments_cod_pendings.supply'),
                    icon: {
                        color: 'accent',
                        icon: 'send'
                    },
                    method: 'supplyOrderModal',
                }
            ],
            otraActions: 
            [
                {
                    name: this.$t('fulfillments_cod_pendings.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                },
                {
                    name: this.$t('fulfillments_cod_pendings.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'mode_edit'
                    },
                    link: '/pending-shipments/cod/edit/10000',
                },
                {
                    name: this.$t('fulfillments_cod_pendings.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                },
                {
                    name: this.$t('fulfillments_cod_pendings.supply'),
                    icon: {
                        color: 'accent',
                        icon: 'send'
                    },
                    method: 'supplyOrderModal',
                },
                {
                    name: this.$t('fulfillments_cod_pendings.change_parcel'),
                    icon: {
                        color: 'accent',
                        icon: 'swap_horizontal_circle'
                    },
                    method: 'changeParcelModal',
                }
            ],
            shipmentParcel: "",
            newParcel: "OTRA",
            modalParcel: false,
            bulkProps: {
                items: [],
                search: ""
            },
            parcels: [
                "FEDEX", "99MINUTOS", "PAQUETEXPRESS", "QUIKEN", "YEGO", "UPS", "MERQ"
            ]
        }
    },
    async mounted(){
        this.$store.state.module = "headers." + this.shipping_service + "_cod_pendings";
        await this.checkIfCanOpen(this.shipping_service);
        
        if(this.modalAlreadyOpen == false) this.index();
    },
    methods: {
        methodHandler(object, params){
            this[object](params)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.props.items = [];
            this.boxes = [];
            this.selected = [];
            this.selected_box = "";
            this.modalBulk = false;
            this.modalBulkResponses = false;
            this.modalSupply = false;

            this.$http.get(this.$store.state.chronosApi + 'boxes', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x => {
                    x = x.attributes;
                    this.boxes.push(x)
                })
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })

            this.$http.get(this.$store.state.chronosApi + 'shipment/getPendingOrders?warehouse=' + this.$store.state.warehouseId + '&shipping_service=' + this.shipping_service.toUpperCase() + '&cod=true', {headers: {Authorization: lsToken}})
            .then((response) => {
                // Get Shippment_services
                this.$store.state.loaderProps.visible = true;
                response.data.data.forEach( x => { 
                    const type = x.type;
                    const id = x.id;
                    x = x.attributes;
                    x.type = type;
                    x.id = id;
                    x.time = x.createdAt.substring(11,16);
                    x.createdAt = x.createdAt.substring(0,10);
                    if(this.shipping_service != 'otra'){
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x['actions'][1].link = "/pending-shipments/cod/edit/" + x.id;
                    } else {
                        x['actions'] = JSON.parse(JSON.stringify(this.otraActions));
                        x['actions'][1].link = "/pending-shipments/cod/edit/" + x.id;
                    }
                    
                    x.parameters = x;
                    this.props.items.push(x);
                })
            }).catch((err) => {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: err.response.data.fault.message
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        showDetails(order){
            this.orderToShow = order;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/getOrderInformation/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data.attributes;
                this.$store.state.loaderProps.visible = false;
                this.orderToShow = response.data;
                this.modalDetail = true;
            }).catch((err) => {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: err.response.data.fault.message
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        supplyOrderModal(order){
            this.orderToShow = order;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/getOrderInformation/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data.attributes;
                this.$store.state.loaderProps.visible = false;
                this.orderToShow = response.data;
                this.modalSupply = true;
            }).catch((err) => {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: err.response.data.fault.message
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        orderSupply: async function(order){
            if(this.sending_data) return;
            this.sending_data = true;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            
            const shipment = {
                "shipments" :[
                    {
                        "id": order.id,
                        "unique_order_number": order.uniqueOrderNumber,
                        "client_id": order.client.id,
                        "type": order.type,
                        "boxes": [{
                            "box_id": this.selected_box,
                            "quantity": 1,
                            "weight": 1.0
                        }]
                    }
                ]
            }

            var new_shipments = await this.verifyOrders(shipment, lsToken);

            if(new_shipments.length > 0) {
                if(new_shipments.includes(order.uniqueOrderNumber)){
                    this.$http.post(this.$store.state.chronosApi + 'shipment/generateLabels', shipment, {headers: {Authorization: lsToken}})
                    .then((response) => {
                        if(response.status == 200){
                            if(response.data[0].status == 200){
                                this.snackbar = {
                                    visible: true,
                                    color: 'success2',
                                    text: response.data[0].message
                                }
                                this.modalDetail = false;
                                this.modalSupply = false;
                                this.index();
                            }
                            else {
                                this.snackbar = {
                                    visible: true,
                                    color: 'error',
                                    text: response.data[0].message
                                }
                                this.modalDetail = false;
                                this.modalSupply = false;
                                this.index();
                            } 
                        }
                        this.$store.state.loaderProps.visible = false;
                    }).catch((err) => {
                        console.log(err);
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: err.response.data[0].message
                        }
                        this.index();
                    }).finally(() => {
                        this.sending_data = false;
                        this.$store.state.loaderProps.visible = false;
                    })
                }
                else {
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('fulfillments_cod_pendings.already_processed')
                    }
                    this.index();
                }
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('fulfillments_cod_pendings.already_processed')
                }
                this.index();
            }
        },
        askDelete(order){
            this.modalDelete = true;
            this.orderToDelete = order;
        },
        deleteOrder(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'shipment/deleteOrder/' + this.orderToDelete.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.data.success == true){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('fulfillments_cod_pendings.deleted')
                    }
                    this.modalDelete = false;
                    this.index();
                }
                else{
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('fulfillments_cod_pendings.deleted_failed')
                    }
                    this.modalDelete = false;
                    this.index();
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        bulkSupply: async function(){
            this.bulkProps.items = [];
            if(this.sending_data) return;
            this.sending_data = true;

            var orders = [];
            var shipments = [];

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.selected.forEach( x => {
                if(!orders.includes(x.uniqueOrderNumber)){
                    shipments.push({
                        "id": x.id,
                        "unique_order_number": x.uniqueOrderNumber,
                        "client_id": x.client.id,
                        "type": x.type,
                        "boxes": [{
                            "box_id": this.selected_box,
                            "quantity": 1,
                            "weight": 1.0
                        }]
                    });
                    
                    orders.push(x.uniqueOrderNumber);
                } 
                else {
                    this.bulkProps.items.push({
                        order: x.uniqueOrderNumber,
                        status: 422,
                        message: this.$t('fulfillments_cod_pendings.already_processed')
                    })
                }
            });

            var new_shipments = await this.verifyOrders({ "shipments": shipments }, lsToken);
            if(new_shipments.length > 0) {
                var dif_shipments = [];
                this.selected.forEach( x => {
                    if(new_shipments.includes(x.uniqueOrderNumber)){
                        dif_shipments.push({
                            "id": x.id,
                            "unique_order_number": x.uniqueOrderNumber,
                            "client_id": x.client.id,
                            "type": x.type,
                            "boxes": {
                                "box_id": this.selected_box
                            }
                        });
                    }
                    else {
                        this.bulkProps.items.push({
                            order: x.uniqueOrderNumber,
                            status: 422,
                            message: this.$t('fulfillments_cod_pendings.already_processed')
                        });
                    }
                });
                
                this.sendOrders({ "shipments": dif_shipments }, lsToken);
            }
            else {
                shipments.forEach(x => {
                    this.bulkProps.items.push({
                        order: x.unique_order_number,
                        status: 422,
                        message: this.$t('fulfillments_cod_pendings.already_processed')
                    })
                })
                this.modalBulkResponses = true;
                this.modalBulk = false;
                this.$store.state.loaderProps.visible = false;
            }
        },
        verifyOrders: async function(shipment, lsToken){
            const {data} = await this.$http.post(this.$store.state.chronosApi + 'shipment/validateUonsBeforeProcess', shipment, {headers: {Authorization: lsToken}})
            return data.data;
        },
        sendOrders(shipment, lsToken){
            this.$http.post(this.$store.state.chronosApi + 'shipment/generateLabels', shipment, {headers: {Authorization: lsToken}})
            .then((response) => {
                console.log(response)
                response.data.forEach(x => {  
                    if(x.code != 200){
                        this.bulkProps.items.push({
                            order: x.fault.arguments.order,
                            status: x.code,
                            message: x.fault.message
                        });
                    }  
                    else {
                        this.bulkProps.items.push({
                            order: x.success.arguments.order,
                            status: x.code,
                            message: x.success.message
                        });
                    }   
                })

                this.modalBulkResponses = true;
                this.modalBulk = false;
                this.$store.state.loaderProps.visible = false;
            }).catch((err) => {
                console.log(err);
                err;
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: err.response.data.fault.message
                }
                this.index();
            }).finally(() => {
                this.sending_data = false;
                this.$store.state.loaderProps.visible = false;
            })
        },
        checkIfCanOpen(parcel){
            localStorage.open_pages = Date.now();
            window.addEventListener('storage', (e) => {
                if(e.key == "open_pages") localStorage.setItem("page_available_cod_" + parcel, Date.now())
                if(e.key == "page_available_cod_" + parcel) this.modalAlreadyOpen = true;              
            }, false);
        },
        changeParcelModal(shipment){
            this.modalParcel = true;
            this.shipmentParcel = shipment;
        },
        updateParcel(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.patch(this.$store.state.chronosApi + 'shipment/update/' + this.shipmentParcel.id, { shipping_service: this.newParcel, warehouse_id: this.$store.state.warehouseId, cod: 1 }, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('edit_shipment.success')
                    }
                    this.newParcel = "";
                    this.shipmentParcel = "";
                    this.modalParcel = false;
                    this.index();
                }
            }).catch((err) => {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: err.response.data.error
                }
                // TESTORDERWITHFILES02
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancelUpdateParcel(){
            this.newParcel = "";
            this.shipmentParcel = "";
            this.modalParcel = false;
        }
    },
    watch:{
        '$route.params.shipping_service': function() {
            location.reload();
        }
    }
}
</script>

<style lang="scss">
</style>