<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center" v-if="appointment.client">    
                        <h3 class="azul--text text-uppercase">{{ $t('store_product.store') }} {{ this.id }} {{ this.appointment.client }} </h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-row class="px-0 py-0 my-0" justify="center" align="center" v-if="!has_unknown_products">
                        <v-col cols="6" align="start" class="py-0 my-0" justify="start" v-if="appointment.client">    
                            <h3 class="azul--text text-uppercase">{{ $t('store_product.products_to_store') }} </h3>
                        </v-col>
                    </v-row>
                    <v-row justify="center" align="center" class="my-0 py-0" v-if="!has_unknown_products">
                        <v-col cols="6" justify="center" align="center" class="my-0 py-0">
                            <v-data-table class="px-0 my-0"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="props_products_to_store.items"
                                calculate-widths
                                :hide-default-footer="true"
                                :mobile-breakpoint="0"
                                disable-pagination
                                item-key="id"
                            >   
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                                
                                <template v-slot:item.quantity="{item, header}">
                                    <v-edit-dialog
                                        :return-value.sync="editQuantity"
                                        @save="saveDialog(item)"
                                        @cancel="cancelDialog"
                                        @open="openDialog(item)"
                                        @close="closeDialog"
                                        large
                                        :cancel-text="$t('datatable.cancel')"
                                        :save-text="$t('datatable.save')"
                                    > {{item[header.value]}}
                                        <template v-slot:input>
                                        <v-text-field
                                            :rules="numeric_required"
                                            v-model="editQuantity"
                                            label="Edit"
                                            single-line
                                        ></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                    
                                </template>

                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-icon class="material-icons-outlined" :disabled="!action.is_full" small :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                        </template>
                                        <span>{{ action.name }}</span>
                                    </v-tooltip>
                                </template>

                            </v-data-table>
                        </v-col>
                    </v-row>

                    <v-form ref="formsku" lazy-validation @submit.prevent="save">
                        <v-row class="px-0 py-0 mb-3 mt-0" justify="center" align="center">
                            <v-col cols="6" align="start" class="py-0 mb-1" justify="start" v-if="appointment.client">    
                                <h3 class="azul--text text-uppercase">{{ $t('store_product.products_distribution') }} </h3>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0 mb-3 mt-0" justify="center" align="center" v-if="has_unknown_products">
                            <v-col cols="6" class="py-0">
                                <label>{{$t('store_product.operation_without_products')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0 my-0" justify="center" align="center" v-if="!has_unknown_products">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product.id" :items="products" item-value="id" item-text="sku" :label="$t('store_product.select_product')" :rules="rules_required"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0 my-0" justify="center" align="center" v-else>
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product.id" :items="client_products" item-value="id" item-text="sku" :label="$t('store_product.select_product')" :rules="rules_required"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product.location" :items="racks" item-value="id" item-text="name" :label="$t('store_product.location')" :rules="rules_required"></v-autocomplete> 
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="product.quantity" :label="$t('store_product.quantity')" :rules="numeric_required"></v-text-field>
                            </v-col>
                        </v-row>
                        
                        <v-row class="px-0 py-0 my-0" justify="center" align="center" v-if="this.appointment.client_type == undefined || this.appointment.client_type != 'HD'">
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="product.batch" @keyup="uppercase" @change="uppercase" :label="$t('store_product.batch')"></v-text-field>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="product.reference" @keyup="uppercase" @change="uppercase" :label="$t('store_product.reference')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0 my-0" justify="center" align="center" v-if="this.appointment.client_type != undefined && this.appointment.client_type == 'HD'">
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="product.batch" @keyup="uppercase" @change="uppercase" :label="$t('store_product.part_number')"></v-text-field>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="product.reference" @keyup="uppercase" @change="uppercase" :label="$t('store_product.weight')"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="px-0 py-0 my-0" justify="center" align="center" v-if="this.appointment.client_type == undefined || this.appointment.client_type != 'HD'">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.pallet" :label="$t('store_product.pallet')" :rules="numeric_required"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-0 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0" justify="end" align="end">
                            <v-btn class="right white--text mr-3" color="success2" outlined @click="modal_import = true">
                                {{ $t('store_product.import_distribution') }}
                            </v-btn>
                            <v-btn class="right white--text" color="success2" outlined @click="addProduct(false)">
                                {{ $t('store_product.add') }}
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row justify="center" aling="center">
                        <v-col cols="6" class="py-0" justify="center" aling="center">
                            <datatable :props="{ headers: headers_distribution, items: skus }" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 mb-0 mt-4" justify="center" align="center">
                        <v-col cols="6" class="py-0" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('store_product.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save">
                                {{ $t('store_product.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>

        <v-dialog v-model="modal_import" persistent max-width="90%" >
            <v-card style="min-width: 500px">
            <v-card-title class="justify-center">
                {{ $t('store_product.import_distribution') }}
            </v-card-title>
                <v-stepper v-model="stepper">
                    <v-stepper-items>
                        <v-stepper-content step="1" class="mx-0 px-0">
                            <v-card flat class="mb-5">
                                <v-form ref="form2" lazy-validation>
                                    <v-row class="mx-0 px-0" justify="center" align="center">
                                        <v-col cols="10">
                                            <v-file-input v-model="file_products" color="amarillo" accept=".csv" :clearable="true" :label="$t('edit_appointment.multiple_skus')" :rules="rules_required"></v-file-input>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn class="mx-0" small color="amarillo" v-bind="attrs" v-on="on" @click="stepper = 4">
                                                        <v-icon class="material-icons-outlined" aria-hidden="false" color="white" small>
                                                            help
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('fulfillments_cod_returns.help') }}</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card>
                            <v-row class="mx-2 px-5 mx-5 py-0" justify="end" align="end">
                                <v-col cols="2" justify="end" align="end" class="mr-12">
                                    <v-btn text  @click="modal_import = false;" >
                                        {{ $t('fulfillments_cod_returns.cancel') }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="2" justify="end" align="end" class="mr-4">
                                    <v-btn class="white--text" color="amarillo" @click="addMultipleSkus" >
                                        {{ $t('fulfillments_cod_returns.load') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>

                        <v-stepper-content step="4">
                            <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                            <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                            <p class="py-0 my-0">3.- El archivo de productos debe ser formato CSV.</p> 
                            <p class="py-0 mt-0 mb-2">4.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 

                            <v-simple-table fixed-header>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left" style="padding: 0 5px !important;" v-if="appointment.client_type != 'HD'">tarima</th>
                                            <th class="text-left" style="padding: 0 5px !important;">sku</th>
                                            <th class="text-left" style="padding: 0 5px !important;">ubicacion</th>
                                            <th class="text-left" style="padding: 0 5px !important;">cantidad</th>
                                            <th class="text-left" style="padding: 0 5px !important;" v-if="appointment.client_type != 'HD'">lote</th>
                                            <th class="text-left" style="padding: 0 5px !important;" v-if="appointment.client_type == 'HD'">Número de parte</th>
                                            <th class="text-left" style="padding: 0 5px !important;" v-if="appointment.client_type != 'HD'">referencia</th>
                                            <th class="text-left" style="padding: 0 5px !important;" v-if="appointment.client_type == 'HD'">Peso</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td v-if="appointment.client_type != 'HD'">1</td>
                                            <td>THISISSKU01</td>
                                            <td>TEMPO2</td>
                                            <td>200</td>
                                            <td v-if="appointment.client_type != 'HD'">LOTE</td>
                                            <td v-if="appointment.client_type == 'HD'">Número de parte</td>
                                            <td v-if="appointment.client_type != 'HD'">REFERENCIA</td>
                                            <td v-if="appointment.client_type == 'HD'">Peso</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-row align="center" justify="center" class="my-3">
                                <v-btn color="amarillo" class="white--text" @click="stepper = 1">
                                    {{ $t('products.go_back') }}
                                </v-btn>
                            </v-row>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('store_product.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
        headers: function(){
            return [
                {text: this.$t('store_product.sku'), value: 'sku', class: 'text-uppercase', width:"70%"},
                {text: this.$t('store_product.quantity'), value: 'quantity', class: 'text-uppercase', width:"20%", align:"end"},
                {text: "", value: 'actions', class: 'text-uppercase', width:"10%", align:"start"},
            ]
        },
        headers_distribution: function(){
            
            var headers = [
                {text: this.$t('store_product.sku'), value: 'sku', class: 'text-uppercase'},
                {text: this.$t('store_product.location'), value: 'location_name', class: 'text-uppercase'},
                {text: this.$t('store_product.pallet'), value: 'pallet', class: 'text-uppercase'},
                {text: this.$t('store_product.batch'), value: 'batch', class: 'text-uppercase'},
                {text: this.$t('store_product.reference'), value: 'reference', class: 'text-uppercase'},
                {text: this.$t('store_product.quantity'), value: 'quantity', class: 'text-uppercase'},
                {text: "", value: 'actions', class: 'text-uppercase'},
            ]

            if(this.appointment.client_type != undefined && this.appointment.client_type == 'HD'){
                headers.splice(2,1);
                headers[2].text = this.$t('store_product.part_number');
                headers[3].text = this.$t('store_product.weight');
            }

            return headers;
        }
    },
    data(){
        return {
            from: this.$route.params.from,
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props_products_to_store: {
                items:[],
            },
            stepper: 1,
            sTHeaders: [],
            readRows: [],
            modal_import: false,
            appointment: {},
            product: {
                id: "",
                quantity: "",
                location: "",
                batch: null,
                reference: null,
                pallet: ""
            },
            to_sum: "",
            product_to_sum: "",
            modal_sum: false,
            products: [], //catalogue of products
            all_products: [], // catalogue of products without modifications
            skus: [], // appointments products
            actions: [
                {
                    name: this.$t('store_product.sum'),
                    icon: {
                        color: 'success',
                        icon: 'check_circle'
                    },
                    is_full: false
                }
            ],
            actions_remove: [
                {
                    name: this.$t('store_product.remove'),
                    icon: {
                        color: 'error',
                        icon: 'highlight_off'
                    },
                    method: 'removeProduct'
                }
            ],
            racks: [],
            products_count: 0,
            file_products: [],
            inputFile: [],
            readFile: '',
            has_unknown_products: false,
            client_products: [], // All products of client
            editQuantity: ""
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.store";
    },
    methods: {
        async index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.props_products_to_store.items = []
            const {data} = await this.$http.get(this.$store.state.chronosApi + 'appointments/standby_products/' + this.id, { headers: { Authorization: lsToken } })
            this.appointment = {
                client: data.client.name,
                id: this.id,
                client_id: data.client.id,
                client_type: data.client.client_type
            }

            this.has_unknown_products = data.unknown_products;

            if(!this.has_unknown_products) {
                data.products.data.forEach(x => {
                    x = x.attributes;

                    this.props_products_to_store.items.push({ // items on list
                        id: x.product.id,
                        quantity: x.real_quantity,
                        sku: x.product.sku,
                        actions: JSON.parse(JSON.stringify(this.actions)),
                        parameters: x.product.id
                    });

                    this.products.push({id: x.product.id, sku: x.product.sku}); // items on drop down
                    this.all_products.push({id: x.product.id, sku: x.product.sku}); // all items on drop down without modifications
                })
            }
            else {
                this.$http.get(this.$store.state.chronosApi + 'getProducts/' + this.appointment.client_id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    response.data = response.data.data;
                    response.data.forEach(x =>{
                        x = x.attributes;
                        this.client_products.push({
                            product_id: x.id,
                            sku_p: x.sku,
                            sku: x.sku + ' | ' + x.description, 
                            id: x.id
                        });
                    });
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }

            this.$http.get(this.$store.state.chronosApi + 'getLocations/5/' + data.client.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data.forEach(x =>{
                        this.racks.push({
                            id: x.id,
                            name: x.name
                        });
                    });
                }
            });

            this.$store.state.loaderProps.visible = false;
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        save(){
            if(this.skus.length == 0) {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('store_product.no_products')
                }

                return;
            }

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const payload = {
                products: []
            };
            var dynamic_pallet = 0;
            
            this.skus.forEach(x => {
                payload.products.push({ 
                    sku: x.sku,
                    quantity: parseInt(x.quantity),
                    location: x.location_name,
                    batch: x.batch,
                    reference: x.reference,
                    expiration: "",
                    pallet: (this.appointment.client_type == "HD" ? dynamic_pallet++ : parseInt(x.pallet))
                })
            })

            this.$http.post(this.$store.state.chronosApi + 'appointments/store/' + this.id, payload, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('store_product.responses.ok')
                    }

                    this.$router.go(-1);

                    // if(this.from == "national") this.$router.push('/national-processed');  
                    // else this.$router.push('/international-processed');
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: response.response.data.fault.message
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        cancel(){
            this.$router.go(-1);
        },
        async addProduct(multiple, i){
            const result = await this.checkProductQuantity();
            if (result == false) {
                if(multiple){
                    this.skus = [];
                    this.$refs.formsku.reset();
                }

                return;
            }

            const validate = this.$refs.formsku.validate();
            if(!validate) {
                this.showSnackbar(this.$t('store_product.wrong_information'), "error");
                return;
            }

            var exists = this.skus.find(x => (x.product_id == this.product.id && x.batch == this.product.batch && x.reference == this.product.reference && x.pallet == this.product.pallet && x.location == this.product.location))

            if(exists != undefined) {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('store_product.sku_already_in_list_1') + (i+1).toString() + this.$t('store_product.sku_already_in_list_2')
                }
                
                this.skus = [];

                return false;
            }

            this.skus.push(JSON.parse(JSON.stringify({
                id: this.product.id + '_' + this.products_count.toString(),
                product_id: this.product.id,
                quantity: this.product.quantity,
                batch: this.product.batch,
                reference: this.product.reference,
                pallet: this.product.pallet,
                location: this.product.location,

                location_name: this.racks.find(x => x.id == this.product.location).name,
                sku: this.has_unknown_products ? this.client_products.find(x => x.id == this.product.id).sku_p : this.all_products.find(x => x.id == this.product.id).sku,
                actions: JSON.parse(JSON.stringify(this.actions_remove)),
                parameters: this.product
            })));

            this.products_count++;

            this.$refs.formsku.reset();
        },
        async checkProductQuantity(){
            var c_sku = ""
            if(this.has_unknown_products) return true;
            if((this.product.id == "" || this.product.id == null 
            || this.product.quantity == "" || this.product.quantity == null 
            || this.product.pallet == "" || this.product.pallet == null
            || this.product.location == "" || this.product.location == null) && this.appointment.client_type != "HD") {
                c_sku = this.props_products_to_store.items.find(x => x.id == this.product.id).sku;
                this.showSnackbar(this.$t('store_product.missing_sku_data') + ' ' + c_sku + '-' + this.product.batch, "error");
                return false;
            }
            else if((this.product.id == "" || this.product.id == null 
            || this.product.quantity == "" || this.product.quantity == null 
            || this.product.location == "" || this.product.location == null) && this.appointment.client_type == "HD"){
                this.product.pallet = "1";
                c_sku = this.props_products_to_store.items.find(x => x.id == this.product.id).sku;
                this.showSnackbar(this.$t('store_product.missing_sku_data') + ' ' + c_sku + '-' + this.product.batch, "error");
                return false;
            }

            if(isNaN(this.product.quantity) || parseInt(this.product.quantity) < 0) {
                this.showSnackbar(this.$t('store_product.wrong_information'), "error");
                return false;
            }
            
            // product total limit
            var l_product = this.props_products_to_store.items.find(x => x.id == this.product.id)
                        
            // product current quantity
            var c_product = this.skus.map(x => {
                if (x.product_id == this.product.id) return parseInt(x.quantity);
                else return 0;
            })

            var already_exist = this.skus.find(x => (x.product_id == this.product.id && x.batch == this.product.batch && x.reference == this.product.reference && x.pallet == this.product.pallet && x.location == this.product.location))
            if(already_exist != undefined){
                var index= this.skus.findIndex(x => (x.product_id == this.product.id && x.batch == this.product.batch && x.reference == this.product.reference && x.pallet == this.product.pallet && x.location == this.product.location))
                this.showSnackbar(this.$t('store_product.sku_already_in_list_1') + " " + (index+1).toString() + " " + this.$t('store_product.sku_already_in_list_2'), "error");
                return false;
            }

            if(l_product == null || l_product == undefined){
                c_sku = this.props_products_to_store.items.find(x => x.id == this.product.id).sku;
                this.showSnackbar(this.$t('store_product.sku_not_in_product_list') + ' ' + c_sku + '-' + this.product.batch, "error");
                return false;
            }

            // Product does not exist in current products distribution table
            if(c_product.length == 0 && parseInt(this.product.quantity) > parseInt(l_product.quantity)) {
                c_sku = this.props_products_to_store.items.find(x => x.id == this.product.id).sku;
                this.showSnackbar(this.$t('store_product.sku_higher_quantity') + ' ' + c_sku + '-' + this.product.batch, "error");
                return false;
            }

            // The quantity is less or equal to limit quantity
            if(parseInt(this.product.quantity) + parseInt(c_product.reduce((a, b) => a + b, 0)) > l_product.quantity){
                c_sku = this.props_products_to_store.items.find(x => x.id == this.product.id).sku;
                this.showSnackbar(this.$t('store_product.sku_higher_quantity') + ' ' + c_sku + '-' + this.product.batch, "error");
                return false;
            } 

            if(c_product.length == 0 && parseInt(this.product.quantity) <= parseInt(l_product.quantity)) {
                if(parseInt(this.product.quantity) == l_product.quantity){
                    l_product.actions[0].is_full = true;
                    this.removeFromDropDown(l_product);                  
                } 

                return true;
            }

            // The quantity is less or equal to limit quantity
            if(parseInt(this.product.quantity) + parseInt(c_product.reduce((a, b) => a + b, 0)) <= l_product.quantity){
                if(parseInt(this.product.quantity) + parseInt(c_product.reduce((a, b) => a + b, 0)) == l_product.quantity) {
                    l_product.actions[0].is_full = true;
                    this.removeFromDropDown(l_product);                  
                } 
                
                return true;
            } 

            return false;
        },
        removeProduct(product){
            const index = this.skus.findIndex(x => (x.product_id == product.id && x.batch == product.batch && x.location == product.location && x.reference == product.reference))
            if(index != null && index != undefined){
                this.skus.splice(index, 1)
            }

            // Add product to dropdown again
            const index_dd = this.products.findIndex(x => x.id == product.id)
            if(index_dd == -1){
                this.products.push(this.all_products.find(x => x.id == product.id))
            }

            var l_product = this.props_products_to_store.items.find(x => x.id == product.id)

            l_product.actions[0].is_full = false;
        },
        uppercase() {
            if(this.product.batch != null){
                this.product.batch = this.product.batch.toUpperCase();
                this.product.batch = this.product.batch.replace(/\s/g,'');
            }

            if(this.product.reference != null){
                this.product.reference = this.product.reference.toUpperCase();
                this.product.reference = this.product.reference.replace(/\s/g,'');
            }
        },
        addMultipleSkus(){
            if(this.file_products != []){
                this.createInput(this.file_products);
            }
        },
        createInput(file) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows = result.split('\r\n');
                    this.sTHeaders = this.readRows[0].split(',')
                    this.readRows.shift()
                    this.processSkus();
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        async processSkus(){
            if(this.appointment.client_type == "HD") await this.proccessHdSkus();
            else await this.proccessDefaultSkus();            
        },
        async proccessHdSkus(){
            this.modal_import = false;
            for(var i = 0; i < this.readRows.length; i++){
                var p = this.readRows[i].split(",");

                if(p.length == 1 && p[0] == ""){ continue; }

                var p_id = undefined; // product_id
                var l_id = undefined; // location_id
                var result = false;

                if(!this.has_unknown_products){
                    p_id = this.props_products_to_store.items.find(x => x.sku == p[0]);
                    l_id = this.racks.find(x => p[1] == x.name)
                    
                    result = this.validateProductLocation(p_id, l_id, i);
                    if(result == false) return;
                    

                    this.product = {
                        id: p_id.id,
                        quantity: parseInt(p[2]),
                        location: l_id.id,
                        batch: (p[3] == "" || p[3] == null) ? null : p[3].toUpperCase().replace(/\s/g,''),
                        reference: (p[4] == "" || p[4] == null) ? null : p[4].toUpperCase().replace(/\s/g,''),
                        pallet: i + 1
                    }
                }
                else {
                    p_id = this.client_products.find(x => x.sku.split("|")[0].trim() == p[0]);
                    l_id = this.racks.find(x => p[1] == x.name)

                    result = this.validateProductLocation(p_id, l_id, i);
                    if(result == false) return;

                    this.product = {
                        id: p_id.id,
                        quantity: parseInt(p[2]),
                        location: l_id.id,
                        batch: (p[3] == "" || p[3] == null) ? null : p[3].toUpperCase().replace(/\s/g,''),
                        reference: (p[4] == "" || p[4] == null) ? null : p[4].toUpperCase().replace(/\s/g,''),
                        pallet: i + 1
                    }
                }
                
                await this.addProduct(true, i);
            }
        },
        async proccessDefaultSkus(){
            this.modal_import = false;
            for(var i = 0; i < this.readRows.length; i++){
                var p = this.readRows[i].split(",");

                if(p.length == 1 && p[0] == ""){ continue; }

                var p_id = undefined; // product_id
                var l_id = undefined; // location_id
                var result = false;

                if(!this.has_unknown_products){
                    p_id = this.props_products_to_store.items.find(x => x.sku == p[1]);
                    l_id = this.racks.find(x => p[2] == x.name)
                    
                    result = this.validateProductLocation(p_id, l_id, i);
                    if(result == false) return;
                    

                    this.product = {
                        id: p_id.id,
                        quantity: parseInt(p[3]),
                        location: l_id.id,
                        batch: (p[4] == "" || p[4] == null) ? null : p[4].toUpperCase().replace(/\s/g,''),
                        reference: (p[5] == "" || p[5] == null) ? null : p[5].toUpperCase().replace(/\s/g,''),
                        pallet: p[0]
                    }
                }
                else {
                    p_id = this.client_products.find(x => x.sku.split("|")[0].trim() == p[1]);
                    l_id = this.racks.find(x => p[2] == x.name)

                    result = this.validateProductLocation(p_id, l_id, i);
                    if(result == false) return;

                    this.product = {
                        id: p_id.id,
                        quantity: parseInt(p[3]),
                        location: l_id.id,
                        batch: (p[4] == "" || p[4] == null) ? null : p[4].toUpperCase().replace(/\s/g,''),
                        reference: (p[5] == "" || p[5] == null) ? null : p[5].toUpperCase().replace(/\s/g,''),
                        pallet: p[0]
                    }
                }
                
                await this.addProduct(true, i);
            }
        },
        validateProductLocation(p_id, l_id, i){
            if(p_id == undefined){
                this.skus = [];
                
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('store_product.product_not_found') + (i+1).toString()
                }

                return false;
            }

            if(l_id == undefined){
                this.skus = [];
                
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('store_product.location_not_found') + (i+1).toString()
                }

                return false;
            }

            return true;
        },
        showSnackbar(message, color){
            this.snackbar = {
                visible: true,
                color: color,
                text: message
            }
        },
        removeFromDropDown(product){
            const index = this.products.findIndex(x => x.id == product.id);
            this.products.splice(index, 1);
        },
        async saveDialog(param) {
            if(this.isInDesiredForm(this.editQuantity)){
                param.quantity = parseInt(this.editQuantity);
                param.updated = true;
            }

            this.skus = this.skus.filter(x => x.product_id != param.id);
            await this.updateKnowProducts();
        },
        async updateKnowProducts(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            
            const payload = {
                products: []
            };

            this.props_products_to_store.items.forEach(x => {
                payload.products.push({ product_id: x.id, quantity: x.quantity })
            })

            await this.$http.post(this.$store.state.chronosApi + 'appointments/prefetch/' + this.id, payload, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('store_product.responses.updated')
                    }

                    this.index();
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('store_product.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        cancelDialog() {},
        openDialog(param) {
            this.editQuantity = param.quantity;
        },
        closeDialog() {},
        isInDesiredForm(str) {
            var n = Math.floor(Number(str));
            return n !== Infinity && String(n) === str && n >= 0;
        }
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>