<template>
    <div>
        <v-data-table class="px-0 my-2"
        
        no-results-text="No hay resultados"
            :headers="props.headers"
            :items="props.items"
            :search="props.search"
            :show-select="showSelect"
            v-model="content"
            calculate-widths
            :footer-props="{
                'items-per-page-text': this.$t('datatable.shown'),
                showFirstLastPage: false,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2} ' + this.$t('datatable.results'),
                'show-current-page': true,
                'items-per-page-options': pagination != undefined ? (pagination.constructor === Array ? pagination : [10, 25, 50, 100]) : [10, 25, 50, 100]
            }"
            :page="typeof this.page === 'undefined' ? 1 : page"
            :disable-pagination="hide_pagination"
            :mobile-breakpoint="0"
            :hide-default-footer="hide_pagination"
        >
            <template v-for="custom_column in custom_columns" v-slot:[`item.${custom_column}`]="{ item }">
                <slot :name="`item.${custom_column}`" v-bind:item="item"></slot>
            </template>

            <template v-slot:item._actions="{ item }">
                <slot name="other-actions" v-bind:item="item"></slot>
            </template>

            <!-- Editable fields "Quantity" -->
            <template v-slot:item.quantity="{ item, header, index}" v-if="ff_quantity_editable == true || consumables_quantity_editable == true">
                <v-edit-dialog
                    :return-value.sync="editQuantity"
                    @save="save(item, 'quantity', index)"
                    @cancel="cancel"
                    @open="open(item, 'quantity')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                    <v-text-field
                        :rules="numeric_required"
                        v-model="editQuantity"
                        label="Edit"
                        single-line
                    ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>

            <!-- Editable fields "unit_price" -->
            <template v-slot:item.unit_price="{ item, header, index}" v-if="unit_price_editable == true">
                <v-edit-dialog
                    :return-value.sync="editUnitPrice"
                    @save="save(item, 'unit_price', index)"
                    @cancel="cancel"
                    @open="open(item, 'unit_price')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                    <v-text-field
                        :rules="numeric_required"
                        v-model="editUnitPrice"
                        label="Edit"
                        single-line
                    ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template> 

            <!-- Editable fields "Batch" -->
            <template v-slot:item.batch="{item, header, index}" v-if="batch_editable == true">
                <v-edit-dialog
                    :return-value.sync="editBatch"
                    @save="save(item, 'batch', index)"
                    @cancel="cancel"
                    @open="open(item, 'batch')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                    <v-text-field
                        v-model="editBatch"
                        label="Edit"
                        single-line
                    ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>

            <!-- Editable fields "Reference" -->
            <template v-slot:item.reference="{item, header, index}" v-if="reference_editable == true">
                <v-edit-dialog
                    :return-value.sync="editReference"
                    @save="save(item, 'reference', index)"
                    @cancel="cancel"
                    @open="open(item, 'reference')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                    <v-text-field
                        v-model="editReference"
                        label="Edit"
                        single-line
                    ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>

            <!-- Editable fields "damaged_product" -->
            <template v-slot:item.damaged_product="{item, header, index}" v-if="damaged_editable == true">
                <v-edit-dialog
                    :return-value.sync="editDamageProduct"
                    @save="save(item, 'damaged_product', index)"
                    @cancel="cancel"
                    @open="open(item, 'damaged_product')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                    <v-text-field
                        :rules="numeric_required"
                        v-model="editDamageProduct"
                        label="Edit"
                        single-line
                    ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>

            <!-- Editable fields "stolen_product" -->
            <template v-slot:item.stolen_product="{item, header, index}" v-if="stolen_editable == true">
                <v-edit-dialog
                    :return-value.sync="editStolenProduct"
                    @save="save(item, 'stolen_product', index)"
                    @cancel="cancel"
                    @open="open(item, 'stolen_product')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                    <v-text-field
                        :rules="numeric_required"
                        v-model="editStolenProduct"
                        label="Edit"
                        single-line
                    ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>

            <!-- ACTIONS -->
            <template v-slot:item.actions="{ item }">
                <v-tooltip top v-for="(action, i) of item.actions" :key="i" v-permission:any="action.permission">                    
                    <!-- RFID -->
                    <template v-if="action.show_rfid == true" v-slot:activator="{ on }">
                        <v-icon v-if="action.has_action" style="margin: 0 2px 0 2px" :disabled="!action.is_enabled" @click="methodHandler(action.method, item.parameters)"  v-on="on" :color="action.icon.color">{{ action.icon.icon }}</v-icon>
                        <v-icon v-else-if="!action.has_action" style="margin: 0 2px 0 2px" :disabled="!action.is_enabled" v-on="on" :color="action.icon.color">{{ action.icon.icon }}</v-icon>
                    </template>

                    <!-- Links Filled -->
                    <template v-else-if="action.name == $t('pendings.files') || action.name == $t('processed.files') || action.name == $t('no_annex.files') || action.name == $t('digital_heritage.files')" v-slot:activator="{ on }">
                        <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                            <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                        </v-btn>
                        <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                            <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                        </v-btn>    
                    </template>

                    <!-- Links Outlined -->
                    <template v-slot:activator="{ on }" v-else-if="action.name != $t('end_process.already_checked') && action.name != $t('edit_appointment.product_details.checked') && action.name != $t('edit_appointment.product_details.unchecked') && action.name != $t('end_process.already_checked_with_incidence') && action.name != $t('end_process.already_exist_incidence') && action.name != $t('pendings.files') && action.name != $t('processed.files') && action.name != $t('no_annex.files')  && action.name != $t('digital_heritage.files')">
                        <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                            <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                        </v-btn>
                        <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                            <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                        </v-btn>
                    </template>

                    <!-- Buttons -->
                    <template v-else-if="action.name == $t('end_process.already_checked') || action.name == $t('edit_appointment.product_details.checked') || action.name == $t('edit_appointment.product_details.unchecked') || action.name == $t('end_process.already_checked_with_incidence') || action.name == $t('end_process.already_exist_incidence') || action.name == $t('edit_location.disable') || action.name == $t('edit_location.disabled')" v-slot:activator="{ on }">
                        <v-icon  small :disabled="!action.value" v-on="on" :color="action.icon.color">{{ action.icon.icon }}</v-icon>
                    </template>

                    <span>{{ action.name }}</span>
                </v-tooltip>
            </template>
            
            <!-- Colored observation in stuck orders and stuck external orders -->
            <template v-slot:item.observation="{ item }"> 
                <td style="text-align: center; text-transform: uppercase;">
                    <span :class="'back_orange'"> {{ item.observation }} </span> 
                </td>
            </template>

            <!-- Colored departure time for visits and attendances -->
            <template v-slot:item.departure_time="{ item }"> 
                <td style="text-align: center; text-transform: uppercase;">
                    <span v-if="item.departure_time == '23:59'" style="color: red"> {{ item.departure_time }} </span> 
                    <span v-else> {{ item.departure_time }} </span> 
                </td>
            </template>

            <template v-slot:item.exit_time="{ item }"> 
                <td style="text-align: center; text-transform: uppercase;">
                    <span v-if="item.exit_time == '23:59'" style="color: red"> {{ item.exit_time }} </span> 
                    <span v-else> {{ item.exit_time }} </span> 
                </td>
            </template>

            <!-- Id dot in appointment id. INDEX -->
            <template v-slot:item.idIndex="{ item }"> 
                <v-tooltip top v-if="item.dotColor != 'white' && item.dotColor != 'error'">
                    <template v-slot:activator="{ on }">
                        <td>
                            <v-icon small v-on="on" :color="item.dotColor"> fiber_manual_record </v-icon>
                            {{ item.idIndex }} 
                        </td>
                    </template>

                    <span>{{$t('datatable.' + item.dotColor)}}</span>
                </v-tooltip>

                <template v-else>
                    <td>
                        <v-icon small :color="item.dotColor"> fiber_manual_record </v-icon>
                        {{ item.idIndex }} 
                    </td>
                </template>
            </template>

            <!-- tooltip for actions instead button -->
            <template v-slot:item.checked="{ item }">
                <v-tooltip top v-for="(action, i) of item.checked" :key="i">
                    <template v-slot:activator="{ on }">
                        <v-icon class="material-icons-outlined" small :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                    </template>
                    <span>{{ action.name }}</span>
                </v-tooltip>
            </template>

            <!-- Colored status for orders in customer view -->
            <template v-slot:item.coloredStatus="{ item }"> 
                <td style="text-align: center; text-transform: uppercase;">
                    <span :class="{
                    'back_orange': item.color == 'orange',
                    'back_blue': item.color == 'blue',
                    'back_green': item.color == 'green',
                    'back_purple': item.color == 'purple',
                    'back_red': item.color == 'red',
                }"> {{ item.coloredStatus }} </span> 
                </td>
            </template>

            <v-data-footer :items-per-page-options="[]"></v-data-footer>
        </v-data-table>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>    
</template>
<script>
    export default {
        computed: {
            numeric_required(){
                return [
                    v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                    v => !!v || this.$t('edit_appointment.validations.required_field')
                ];
            }
        },
        props: [
            'props', 
            'page',
            'value',
            'custom_columns',
            'showSelect',
            'damaged_editable',
            'batch_editable',
            'reference_editable',
            'stolen_editable',
            'unit_price_editable',            
            'hide_pagination',
            'pagination',
            'ff_quantity_editable',
            'consumables_quantity_editable'
        ],
        data() {
            return {
                content: this.value,
                pageStart: 1,
                pageStop: 10, 
                editQuantity: "",
                editUnitPrice: "",
                editPallet: "",
                editBox: "",
                editLocation: "",
                editBatch: "",
                editReference: "",
                editStolenProduct: "",
                editDamageProduct: "",
                editDescription: "",
                locations: [],
                locations_load: false,
                snackbar: {
                    visible: false,
                    color: 'primary',
                    text: ''
                },
            }
        },
        methods: {
            methodHandler(methodName, parameters){
                this.$emit('methodHandler', {
                    methodName: methodName, 
                    parameters: parameters
                });
            },
            save(param, column) {
                if(this.isInDesiredForm(this.editQuantity) && column == "quantity"){
                    param.quantity = parseInt(this.editQuantity);
                    param.updated = true;
                }
                else if(this.isInDesiredForm(this.editUnitPrice) && column == "unit_price"){
                    param.unit_price = parseFloat(this.editUnitPrice);
                    param.updated = true;
                }
                else if(column == "batch") {
                    this.editBatch = this.editBatch.toUpperCase().replace(/\s/g,'');
                    param.batch = this.editBatch;
                    param.updated = true;
                }
                else if(column == "reference") {
                    this.editReference = this.editReference.toUpperCase().replace(/\s/g,'');
                    param.reference = this.editReference;
                    param.updated = true;
                }
                else if(this.isInDesiredForm(this.editDamageProduct) && column == "damaged_product") {
                    var damaged = parseInt(this.editDamageProduct);

                    if(damaged + param.stolen_product <= param.totalquantity){
                        param.quantity = param.totalquantity - (param.stolen_product + damaged); 
                        param.damaged_product = damaged;
                        param.updated = true;
                    }
                    else this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t("datatable.quantity_error")
                    }
                }
                else if(this.isInDesiredForm(this.editStolenProduct) && column == "stolen_product") {
                    var stolen = parseInt(this.editStolenProduct);

                    if(stolen + param.damaged_product <= param.totalquantity){
                        param.quantity = param.totalquantity - (param.damaged_product + stolen); 
                        param.stolen_product = stolen;
                        param.updated = true;
                    }
                    else this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t("datatable.quantity_error")
                    }
                }
                else console.log("Not Valid")
            },
            cancel() {},
            open() {},
            close() {},
            isInDesiredForm(str) {
                var n = Math.floor(Number(str));
                return n !== Infinity && String(n) === str && n >= 0;
            }
        },
        watch: {
            content: {
                handler(val){
                    this.$emit('input',val);
                },
                deep: true
            },
        }
    }
</script>
<style lang="scss">
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
    .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
        font-size: 11px !important;
        color: #00587d !important;
        border-bottom: 2px solid #00587d !important;
        border-collapse: collapse !important;
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
    .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
    .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
        font-size: 11px !important;
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr > .test,
    .v-data-table > .v-data-table__wrapper > table > thead > tr > .test,
    .v-data-table > .v-data-table__wrapper > table > tfoot > tr > .test {
        font-size: 11px !important;
        display:inline-flex !important; 
        align-items: center !important;
    }

    .hidden {
       background-color: transparent !important;
       color: transparent !important;
    }
    
    td, th{
        padding:0 8px !important;
    }
</style>