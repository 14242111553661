import Vue from 'vue'

// Layouts
Vue.component('dashboard', require('./../views/Layouts/Dashboard.vue').default);
Vue.component('customerDashboard', require('./../views/Layouts/CustomerDashboard.vue').default);
Vue.component('login', require('./../views/Layouts/Login.vue').default);
Vue.component('loading', require('./../views/Layouts/Loading.vue').default);

// Components
Vue.component('sidebar-menu', require('./../components/SidebarMenu.vue').default);
Vue.component('bottom-bar', require('./../components/Bottombar.vue').default);
Vue.component('nav-bar', require('./../components/Navbar.vue').default);
Vue.component('loader', require('./../components/Loader.vue').default);
Vue.component('datatable', require('./../components/Datatable.vue').default);
Vue.component('table-checkbox', require('./../components/TableOfCheckbox.vue').default);
Vue.component('client-selector', require('./../components/ClientSelector.vue').default);
Vue.component('alternativeDateRangePicker', require("./AlternativeDateRangePicker.vue").default);
Vue.component('datatable-action', require("./DataTableAction.vue").default);
Vue.component('rfid-settings', require('./../components/RFIDSettings.vue').default);
Vue.component('search-dialog', require('./../components/SearchDialog.vue').default);
Vue.component('d-operations', require('./../components/Dashboard/Operations.vue').default);
Vue.component('d-audits', require('./../components/Dashboard/Audits.vue').default);
Vue.component('d-sales', require('./../components/Dashboard/Sales.vue').default);
Vue.component('d-fulfillment', require('./../components/Dashboard/Fulfillment.vue').default);
Vue.component('d-supervision', require('./../components/Dashboard/Supervision.vue').default);
Vue.component('upload-files-international', require('./../components/UploadFilesInternational.vue').default);
Vue.component('upload-files-national', require('./../components/UploadFilesNational.vue').default);
Vue.component('datepicker', require('./../components/DatePicker.vue').default);
Vue.component('create-fulfillment', require('./../components/CreateFulfillment.vue').default);

// Dashboard Components
Vue.component('d-operations', require('./../components/Dashboard/Operations.vue').default);
Vue.component('d-audits', require('./../components/Dashboard/Audits.vue').default);
Vue.component('d-sales', require('./../components/Dashboard/Sales.vue').default);
Vue.component('d-fulfillment', require('./../components/Dashboard/Fulfillment.vue').default);
Vue.component('d-supervision', require('./../components/Dashboard/Supervision.vue').default);